import Api from "@/services/api";
import {
  ADD_ALERT,
  LOAD_ALL_MEMBERS,
  LOAD_MEMBER_BY_ID,
  SEND_MEMBER_INVITATION,
  UPDATE_MEMBER,
  MEMBER_SETUP
} from "@/store/_actiontypes";
import {
  SET_ALL_MEMBERS,
  SET_MEMBER_BY_ID
} from "@/store/_mutationtypes";

import { updateLoaderTo } from "./account";

const state = {
  allMembers: {},
  selectedMember: {}
};

const actions = {
  [LOAD_ALL_MEMBERS]({ commit }) {
    updateLoaderTo(true);
    commit(SET_ALL_MEMBERS, {});
    return new Promise((resolve, reject) => {
      Api.get(`members`).then(
        (response) => {
          let allMembers = response.data;
          commit(SET_ALL_MEMBERS, allMembers);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },

  [LOAD_MEMBER_BY_ID]({ commit }, { member_id }){
    updateLoaderTo(true)
    commit(SET_MEMBER_BY_ID, {})
    return new Promise((resolve, reject) => {
      Api.get(`members/${member_id}`).then(
        response => {
          let selected_member = response.data;
          commit(SET_MEMBER_BY_ID, selected_member)
          updateLoaderTo(false)
          resolve(response)
        },
        error => {
          updateLoaderTo(false)
          reject(error)
        }
      )
    })
  },

  [SEND_MEMBER_INVITATION]({commit, dispatch}, {email,role,first_name,last_name}){
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(`members`, {
        email: email,
        role: role,
        first_name: first_name,
        last_name: last_name
      }).then(
        (response) => {
          dispatch(
            `alert/${ADD_ALERT}`,
            { message: response.data.message, color: "success" },
            { root: true }
          );
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },

  [UPDATE_MEMBER]({commit, dispatch}, {member_id, action, value}){
    return new Promise((resolve, reject) => {
      let payload = {};
      payload[action] = value;
      
      updateLoaderTo(true);
      Api.put(`/members/${member_id}`, payload).then(
        (response) => {
          dispatch(
            `alert/${ADD_ALERT}`,
            { message: response.data.message, color: "success" },
            { root: true }
          );
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },

  [MEMBER_SETUP]({ commit, dispatch, state }, {userData}) {
    return new Promise((resolve, reject) => {
      Api.put("members/setup", {
        first_name: userData.first_name,
        middle_name: userData.middle_name,
        last_name: userData.last_name,
        birth_date: userData.birth_date,
        gender: userData.gender,
        phone: userData.phone,
        photo: userData.photo,
        ip_address: userData.ip_address,
        country_code: userData.country_code,
        state_or_province: userData.state_or_province,
        city: userData.city,
        postal_code: userData.postal_code,
        address: userData.address
      }).then((response) => {
        dispatch(
          `alert/${ADD_ALERT}`,
          {
            message: this.$vuetify.lang.t("$vuetify.account.acc_update_msg_txt"),
            color: "success",
          },
          { root: true }
        );
        updateLoaderTo(false);
        resolve(response)
      }).catch((error) => {
        updateLoaderTo(false);
        reject(error)
      })
    })
  },
};

const mutations = {
  [SET_ALL_MEMBERS](state, allMembers){
    state.allMembers = allMembers;
  },
  [SET_MEMBER_BY_ID](state, selectedMember){
    state.selectedMember = selectedMember;
  }
};

export const member = {
  namespaced: true,
  state,
  actions,
  mutations,
};
