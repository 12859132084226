<template>
  <v-card class="elevation-0">
    <v-card-title class="font-weight-bold">
      {{ $vuetify.lang.t("$vuetify.account.basic_info_header_txt") }}
    </v-card-title>
    <v-divider></v-divider>
    <v-list>
      <v-list-item>
        <v-list-item-content class="font-weight-bold">
          {{ `${$vuetify.lang.t("$vuetify.profile.first_name_label_txt")}:` }}
        </v-list-item-content>
        <v-list-item-content class="align-end">
          {{ user.first_name ? user.first_name : "N/A" }}
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="isVisible">
        <v-list-item-content class="font-weight-bold">
          {{ `${$vuetify.lang.t("$vuetify.profile.middle_name_label_txt")}:` }}
        </v-list-item-content>
        <v-list-item-content class="align-end">
          {{ user.additional_name ? user.additional_name : "N/A" }}
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content class="font-weight-bold">
          {{ `${$vuetify.lang.t("$vuetify.profile.last_name_label_txt")}:` }}
        </v-list-item-content>
        <v-list-item-content class="align-end">
          {{ user.last_name ? user.last_name : "N/A" }}
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="isVisible"> 
        <v-list-item-content class="font-weight-bold">
          {{ `${$vuetify.lang.t("$vuetify.profile.gender_label_txt")}:` }}
        </v-list-item-content>
        <v-list-item-content class="align-end">
          {{ user.gender ? capitalizeFirstLetter(user.gender) : "N/A" }}
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="isVisible">
        <v-list-item-content class="font-weight-bold">
          {{ `${$vuetify.lang.t("$vuetify.profile.dob_label_txt")}:` }}
        </v-list-item-content>
        <v-list-item-content class="align-end">
          {{ user.date_of_birth ? user.date_of_birth : "N/A" }}
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="isVisible">
        <v-list-item-content class="font-weight-bold">
          {{ `${$vuetify.lang.t("$vuetify.profile.phone_no_label_txt")}:` }}
        </v-list-item-content>
        <v-list-item-content class="align-end">
          {{ user.phoneInt ? user.phoneInt : "N/A" }}
        </v-list-item-content>
      </v-list-item>

      <!-- <v-list-item>
          <v-list-item-content class="font-weight-bold">
            Profile Photo:
          </v-list-item-content>
          <v-list-item-content class="align-end">
            <v-img
              v-if="profileImg"
              :src="profileImg"
              width="150"
              height="100"
            ></v-img>
            <div v-else>No Photo</div>
          </v-list-item-content>
        </v-list-item> -->

      <v-list-item v-if="isVisible">
        <v-list-item-content class="font-weight-bold">
          {{ `${$vuetify.lang.t("$vuetify.profile.country_label_txt")}:` }}
        </v-list-item-content>
        <v-list-item-content class="align-end">
          {{ user.country ? user.country.countryName : "N/A" }}
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="isVisible">
        <v-list-item-content class="font-weight-bold">
          {{ `${$vuetify.lang.t("$vuetify.profile.state_label_txt")}:` }}
        </v-list-item-content>
        <v-list-item-content class="align-end">
          {{ user.region ? user.region.name : "N/A" }}
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="isVisible">
        <v-list-item-content class="font-weight-bold">
          {{ `${$vuetify.lang.t("$vuetify.profile.city_label_txt")}:` }}
        </v-list-item-content>
        <v-list-item-content class="align-end">
          {{ user.city ? user.city : "N/A" }}
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="isVisible">
        <v-list-item-content class="font-weight-bold">
          {{ `${$vuetify.lang.t("$vuetify.profile.post_code_label_txt")}:` }}
        </v-list-item-content>
        <v-list-item-content class="align-end">
          {{ user.postal_code ? user.postal_code : "N/A" }}
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="isVisible">
        <v-list-item-content class="font-weight-bold">
          {{ `${$vuetify.lang.t("$vuetify.profile.address_label_txt")}:` }}
        </v-list-item-content>
        <v-list-item-content class="align-end">
          {{ user.address ? user.address : "N/A" }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { capitalizeFirstLetter } from "@/helpers/helper";
export default {
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    profileImg: {
      type: String,
    },
  },
  mounted() {},
  data() {
    return {
      capitalizeFirstLetter,
      isVisible: false
    };
  },
  methods: {},
};
</script>
