<template>
  <v-container fill-height fluid>
    <!-- Date Filtering  -->
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      top
      :timeout="timeout"
    >
      {{ snackbarMessage }}
    </v-snackbar>
    <v-row>
      <v-col cols="12" xs="12" md="4">
        <v-menu
          ref="menuDates"
          v-model="menuDates"
          :close-on-content-click="false"
          :return-value.sync="dates"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              :placeholder="
                $vuetify.lang.t('$vuetify.common.daterange_placeholder_txt')
              "
              prepend-icon="mdi-calendar"
              readonly
              outlined
              dense
              hide-details="auto"
              v-bind="attrs"
              v-on="on"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="dates"
            color="primary"
            no-title
            scrollable
            range
            :max="maxDate"
          >
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="menuDates = false">
              {{ $vuetify.lang.t("$vuetify.common.cancel_btn") }}
            </v-btn>
            <v-btn text color="primary" @click="setDates">
              {{ $vuetify.lang.t("$vuetify.common.ok_btn") }}
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col v-if="last_insights_updated_at !== ''" cols="12" xs="12" md="8">
        <div class="float-end">
          <p class="text-end text-caption ma-0 font-weight-bold">
            {{ this.$vuetify.lang.t("$vuetify.insights.last_updated_at") }}
          </p>
          <p class="text-end text-caption ma-0">
            {{ last_insights_updated_at }}
          </p>
        </div>
      </v-col>
    </v-row>
    <template v-if="selectedProject.segment.name === 'Personalizer'">
      <Loader v-if="isLoading" />
      <v-row v-else class="d-flex justify-space-between">
        <v-col cols="12" md="3">
          <v-card outlined flat>
            <v-card-subtitle class="font-weight-bold">
              {{ this.$vuetify.lang.t("$vuetify.insights.search") }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-on="on" v-bind="attrs">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  {{ this.$vuetify.lang.t("$vuetify.insights.search_txt") }}
                </span>
              </v-tooltip>
            </v-card-subtitle>
            <v-card-text class="text-center text-h4">
              {{ totalNumberOfSearches.toLocaleString() }}
            </v-card-text>
            <v-card-text class="d-flex align-center justify-center">
              <span class="mr-2">
                {{ formattedPercentages.percentageSearch }}
              </span>
              <v-icon
                v-if="percentageIcon('percentageSearch')"
                :color="percentageColor('percentageSearch')"
              >
                {{ percentageIcon("percentageSearch") }}
              </v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card outlined flat>
            <v-card-subtitle class="font-weight-bold">
              {{ this.$vuetify.lang.t("$vuetify.insights.recommend") }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-on="on" v-bind="attrs">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  {{ this.$vuetify.lang.t("$vuetify.insights.recommend_txt") }}
                </span>
              </v-tooltip>
            </v-card-subtitle>
            <v-card-text class="text-center text-h4">
              {{ totalNumberOfRecommendations.toLocaleString() }}
            </v-card-text>
            <v-card-text class="d-flex align-center justify-center">
              <span class="mr-2">
                {{ formattedPercentages.percentageRecommend }}
              </span>
              <v-icon
                v-if="percentageIcon('percentageRecommend')"
                :color="percentageColor('percentageRecommend')"
              >
                {{ percentageIcon("percentageRecommend") }}
              </v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card outlined flat>
            <v-card-subtitle class="font-weight-bold">
              {{ this.$vuetify.lang.t("$vuetify.insights.orders") }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-on="on" v-bind="attrs">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  {{ this.$vuetify.lang.t("$vuetify.insights.orders_txt") }}
                </span>
              </v-tooltip>
            </v-card-subtitle>
            <v-card-text class="text-center text-h4">
              {{
                kpiSummary &&
                kpiSummary.purchase &&
                kpiSummary.purchase.number_of_orders
                  ? kpiSummary.purchase.number_of_orders.toLocaleString()
                  : "0"
              }}
            </v-card-text>
            <v-card-text class="d-flex align-center justify-center">
              <span class="mr-2">
                {{ formattedPercentages.percentageOrders }}
              </span>
              <v-icon
                v-if="percentageIcon('percentageOrders')"
                :color="percentageColor('percentageOrders')"
              >
                {{ percentageIcon("percentageOrders") }}
              </v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card outlined flat>
            <v-card-subtitle class="font-weight-bold">
              {{ this.$vuetify.lang.t("$vuetify.insights.revenue") }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-on="on" v-bind="attrs">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  {{ this.$vuetify.lang.t("$vuetify.insights.revenue_txt") }}
                </span>
              </v-tooltip>
            </v-card-subtitle>
            <v-card-text class="text-center text-h4">
              {{
                kpiSummary &&
                kpiSummary.purchase &&
                kpiSummary.purchase.total_purchased_amounts
                  ? kpiSummary.purchase.total_purchased_amounts.toLocaleString()
                  : "0"
              }}
            </v-card-text>
            <v-card-text class="d-flex align-center justify-center">
              <span class="mr-2">
                {{ formattedPercentages.percentageRevenue }}
              </span>
              <v-icon
                v-if="percentageIcon('percentageRevenue')"
                :color="percentageColor('percentageRevenue')"
              >
                {{ percentageIcon("percentageRevenue") }}
              </v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card outlined flat>
            <v-card-subtitle class="font-weight-bold">
              {{ this.$vuetify.lang.t("$vuetify.insights.average_cart_size") }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-on="on" v-bind="attrs">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  {{
                    this.$vuetify.lang.t(
                      "$vuetify.insights.average_cart_size_txt",
                    )
                  }}
                </span>
              </v-tooltip>
            </v-card-subtitle>
            <v-card-text class="text-center text-h4">
              {{ averageCartSize.toLocaleString() }}
            </v-card-text>
            <v-card-text class="d-flex align-center justify-center">
              <span class="mr-2">
                {{ formattedPercentages.percentageAvgCartSize }}
              </span>
              <v-icon
                v-if="percentageIcon('percentageAvgCartSize')"
                :color="percentageColor('percentageAvgCartSize')"
              >
                {{ percentageIcon("percentageAvgCartSize") }}
              </v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card outlined flat>
            <v-card-subtitle class="font-weight-bold">
              {{
                this.$vuetify.lang.t("$vuetify.insights.average_order_value")
              }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-on="on" v-bind="attrs">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  {{
                    this.$vuetify.lang.t(
                      "$vuetify.insights.average_sales_orders_txt",
                    )
                  }}
                </span>
              </v-tooltip>
            </v-card-subtitle>
            <v-card-text class="text-center text-h4">
              {{
                kpiSummary &&
                kpiSummary.purchase &&
                kpiSummary.purchase.avg_purchase_value_by_orders
                  ? kpiSummary.purchase.avg_purchase_value_by_orders.toLocaleString(
                      undefined,
                      { maximumFractionDigits: 2 },
                    )
                  : "0"
              }}
            </v-card-text>
            <v-card-text class="d-flex align-center justify-center">
              <span class="mr-2">
                {{ formattedPercentages.percentageAvgSaleOrder }}
              </span>
              <v-icon
                v-if="percentageIcon('percentageAvgSaleOrder')"
                :color="percentageColor('percentageAvgSaleOrder')"
              >
                {{ percentageIcon("percentageAvgSaleOrder") }}
              </v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card outlined flat>
            <v-card-subtitle class="font-weight-bold">
              {{
                this.$vuetify.lang.t("$vuetify.insights.average_sales_users")
              }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-on="on" v-bind="attrs">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  {{
                    this.$vuetify.lang.t(
                      "$vuetify.insights.average_sales_users_txt",
                    )
                  }}
                </span>
              </v-tooltip>
            </v-card-subtitle>
            <v-card-text class="text-center text-h4">
              {{
                kpiSummary &&
                kpiSummary.purchase &&
                kpiSummary.purchase.avg_purchase_value_by_users
                  ? kpiSummary.purchase.avg_purchase_value_by_users.toLocaleString()
                  : "0"
              }}
            </v-card-text>
            <v-card-text class="d-flex align-center justify-center">
              <span class="mr-2">
                {{ formattedPercentages.percentageAvgSaleUser }}
              </span>
              <v-icon
                v-if="percentageIcon('percentageAvgSaleUser')"
                :color="percentageColor('percentageAvgSaleUser')"
              >
                {{ percentageIcon("percentageAvgSaleUser") }}
              </v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card outlined flat>
            <v-card-subtitle class="font-weight-bold">
              {{
                this.$vuetify.lang.t("$vuetify.insights.average_sales_items")
              }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-on="on" v-bind="attrs">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  {{
                    this.$vuetify.lang.t(
                      "$vuetify.insights.average_sales_items_txt",
                    )
                  }}
                </span>
              </v-tooltip>
            </v-card-subtitle>
            <v-card-text class="text-center text-h4">
              {{
                kpiSummary &&
                kpiSummary.purchase &&
                typeof kpiSummary.purchase.avg_purchase_value_by_items
                  ? kpiSummary.purchase.avg_purchase_value_by_items
                      .toFixed(2)
                      .toLocaleString()
                  : "0"
              }}
            </v-card-text>
            <v-card-text class="d-flex align-center justify-center">
              <span class="mr-2">
                {{ formattedPercentages.percentageAvgSaleItems }}
              </span>
              <v-icon
                v-if="percentageIcon('percentageAvgSaleItems')"
                :color="percentageColor('percentageAvgSaleItems')"
              >
                {{ percentageIcon("percentageAvgSaleItems") }}
              </v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card outlined flat>
            <v-card-subtitle class="font-weight-bold">
              {{
                this.$vuetify.lang.t("$vuetify.insights.repeated_users_count")
              }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-on="on" v-bind="attrs">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>{{
                  this.$vuetify.lang.t(
                    "$vuetify.insights.repeated_users_count_txt",
                  )
                }}</span>
              </v-tooltip>
            </v-card-subtitle>
            <v-card-text class="text-center text-h4">{{
              repeatedUsers.length.toLocaleString() || "0"
            }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <v-row
      align="center"
      justify="center"
      v-if="selectedProject.segment.name === 'Personalizer'"
    >
      <v-col cols="12">
        <h3 class="text-center">Behavioral Insights</h3>
      </v-col>

      <v-btn-toggle v-model="activeTab" active-class="white">
        <v-btn :disabled="activeTab === 'item'" value="item">
          <span class="tab-card-title">
            {{ this.$vuetify.lang.t("$vuetify.insights.item") }}
          </span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="activeTab === 'user'" value="user">
          <span class="tab-card-title">
            {{ this.$vuetify.lang.t("$vuetify.insights.user") }}
          </span>
        </v-btn>
      </v-btn-toggle>
    </v-row>
    <v-row
      v-if="
        activeTab === 'item' && selectedProject.segment.name === 'Personalizer'
      "
      class="d-flex justify-space-between"
    >
      <v-col cols="12" md="6">
        <v-card outlined class="mx-auto">
          <v-card-subtitle class="d-flex justify-space-between align-center">
            <div class="font-weight-bold d-flex align-center">
              {{ $vuetify.lang.t("$vuetify.insights.items_counts") }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-on="on" v-bind="attrs">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  {{ $vuetify.lang.t("$vuetify.insights.items_counts_txt") }}
                </span>
              </v-tooltip>
            </div>
            <div class="float-right">
              <v-switch
                class="mt-0"
                dense
                hide-details="true"
                v-model="isLineChart"
                :label="
                  isLineChart
                    ? this.$vuetify.lang.t('$vuetify.insights.line_chart')
                    : 'Bar Chart'
                "
              ></v-switch>
            </div>
          </v-card-subtitle>
          <v-divider></v-divider>
          <Loader v-if="isLoading" class="mx-auto my-6" />
          <div v-else class="d-flex flex-column justify-space-between">
            <div id="chartContainer" style="flex: 1">
              <apexchart
                v-if="isLineChart"
                ref="lineChart"
                type="line"
                :options="itemlineChart"
                :series="[{ data: itemChartData }]"
              ></apexchart>
              <apexchart
                v-else
                ref="barChart"
                width="100%"
                type="bar"
                :options="itemBarChart"
                :series="[{ data: itemChartData }]"
              ></apexchart>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <TopPurchasedItems
          :itemList="
            (kpiSummary &&
              kpiSummary.purchase &&
              kpiSummary.purchase.top_items) ||
            []
          "
          :isLoading="isLoading"
          :start-date="dates[0] || ''"
          :end-date="dates[1] || ''"
        />
      </v-col>
      <v-col cols="12" md="6">
        <TopBrowsedItems
          :itemList="
            (kpiSummary && kpiSummary.browse && kpiSummary.browse.top_items) ||
            []
          "
          :isLoading="isLoading"
          :start-date="dates[0] || ''"
          :end-date="dates[1] || ''"
        />
      </v-col>
      <v-col cols="12" md="6">
        <TopRatedItems
          :itemList="
            (kpiSummary &&
              kpiSummary.ratings &&
              kpiSummary.ratings.top_positive_rated_items) ||
            []
          "
          :isLoading="isLoading"
          :start-date="dates[0] || ''"
          :end-date="dates[1] || ''"
        />
      </v-col>
    </v-row>
    <v-row v-if="activeTab === 'user'">
      <v-col cols="12" md="6">
        <v-card outlined class="mx-auto">
          <v-card-subtitle class="d-flex justify-space-between align-center">
            <div class="font-weight-bold d-flex align-center">
              {{ $vuetify.lang.t("$vuetify.insights.users_counts") }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-on="on" v-bind="attrs">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  {{ $vuetify.lang.t("$vuetify.insights.users_counts_txt") }}
                </span>
              </v-tooltip>
            </div>
            <div class="float-right">
              <v-switch
                class="mt-0"
                dense
                hide-details="true"
                v-model="isLineChart"
                :label="
                  isLineChart
                    ? this.$vuetify.lang.t('$vuetify.insights.line_chart')
                    : 'Bar Chart'
                "
              ></v-switch>
            </div>
          </v-card-subtitle>
          <v-divider></v-divider>
          <Loader v-if="isLoading" class="mx-auto my-6" />
          <div v-else class="d-flex flex-column justify-space-between">
            <div id="chartContainer" style="flex: 1">
              <apexchart
                v-if="isLineChart"
                ref="lineChart"
                type="line"
                :options="userlineChart"
                :series="[{ data: userChartData }]"
              ></apexchart>
              <apexchart
                :key="isLineChart"
                v-else
                ref="barChart"
                width="100%"
                type="bar"
                :options="userBarChart"
                :series="[{ data: userChartData }]"
              ></apexchart>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card rounded outlined class="mx-auto" height="100%">
          <v-card-subtitle
            class="font-weight-bold d-flex justify-space-between align-center"
          >
            <div class="d-flex align-center">
              {{ this.$vuetify.lang.t("$vuetify.insights.top_users") }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-on="on" v-bind="attrs" class="ml-1">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                {{ this.$vuetify.lang.t("$vuetify.insights.top_users_txt") }}
              </v-tooltip>
            </div>
            <v-btn
              small
              color="primary"
              @click="downloadAsCSV('top_users', userHeaders)"
              :loading="downloading"
              :disabled="downloading || topUsers.length === 0"
            >
              <v-icon left small>mdi-download</v-icon> CSV
            </v-btn>
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-data-table
            :headers="userHeaders"
            :items="topUsers"
            :items-per-page="itemsPerPage"
            :sort-by="['total_purchased_amount']"
            :sort-desc="[true]"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.user_id }}</td>
                <td>{{ item.member_id || "N/A" }}</td>
                <td>{{ item.total_purchased_amount.toLocaleString() }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <RepeatedUsers
          :repeatedUsers="repeatedUsers"
          :isLoading="isLoading"
          :start-date="dates[0] || ''"
          :end-date="dates[1] || ''"
        />
      </v-col>
    </v-row>

    <!--  Conversation history  -->
    <v-row>
      <v-col
        class="pa-0 px-3"
        xs="12"
        md="12"
        v-if="
          selectedProject.segment.name === PROJECT_SEGMENTS.Maira ||
          selectedProject.segment.name === PROJECT_SEGMENTS.PERSONALIZER
        "
      >
        <v-card outlined class="mx-auto">
          <template>
            <!-- integrate after get insights data  -->
            <v-card-subtitle
              class="font-weight-bold d-flex justify-space-between align-center"
            >
              <div class="d-flex align-center">
                {{ $vuetify.lang.t("$vuetify.gpt_history.gpt_conversation_history_title") }}
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-on="on" v-bind="attrs">
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>
                    {{
                      this.$vuetify.lang.t(
                        "$vuetify.gpt_history.gpt_conversation_history_title",
                      )
                    }}
                  </span>
                </v-tooltip>
              </div>
              <div class="float-right">
                <v-btn
                  class="mr-1"
                  small
                  color="primary"
                  @click="gptConversationFileGenerationModal = true"
                >
                  <v-icon left>mdi-download</v-icon>
                  <span class="hidden-sm-and-down"> CSV </span>
                </v-btn>
                <v-btn
                  class="ml-1"
                  small
                  color="primary"
                  @click="gptConversationHistoryModal = true"
                >
                  <v-icon left>mdi-history</v-icon>
                  <span class="hidden-sm-and-down">
                    {{ $vuetify.lang.t("$vuetify.common.history") }}
                  </span>
                </v-btn>
              </div>
            </v-card-subtitle>
            <v-divider></v-divider>

            <v-row class="d-flex flex-column flex-md-row py-4 mb-n6 px-6">
              <v-col width class="pa-0 pr-0 pr-md-3">
                <v-text-field
                  v-model="conversationSearch"
                  :label="$vuetify.lang.t('$vuetify.gpt_history.search_txt')"
                  outlined
                  dense
                  clearable
                ></v-text-field>
              </v-col>
              <!-- <v-col width class="pa-0 pr-0 pr-md-3">
                <v-text-field
                  v-model="userFilter"
                  :label="
                    $vuetify.lang.t('$vuetify.gpt_history.gpt_history_search_user_id')
                  "
                  outlined
                  dense
                  clearable
                ></v-text-field>
              </v-col> -->
              <!-- <v-col xs="12" md="3">
              <v-text-field
                v-model="conversationFilter"
                label="Search Conversation ID"
                outlined
                dense
                clearable
              ></v-text-field>
            </v-col> -->
              <!-- <v-col class="pa-0 pr-0 pr-md-3">
                <v-text-field
                  v-model="promptFilter"
                  :label="$vuetify.lang.t('$vuetify.gpt_history.gpt_history_search_query')"
                  outlined
                  dense
                  clearable
                ></v-text-field>
              </v-col> -->
              <v-col class="pa-0 pr-0 pr-md-3">
                <v-select
                  v-model="feedbackFilter"
                  :items="feedbackOptions"
                  :label="
                    $vuetify.lang.t('$vuetify.gpt_history.gpt_history_filter_feedback')
                  "
                  outlined
                  dense
                  clearable
                ></v-select>
              </v-col>
              <v-col class="pa-0 pr-0">
                <v-select
                  v-model="endpointFilter"
                  :items="endpointOptions"
                  :label="
                    $vuetify.lang.t('$vuetify.gpt_history.gpt_history_filter_endpoints')
                  "
                  outlined
                  dense
                  clearable
                ></v-select>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <Loader v-if="isLoading" class="mr-2 mt-6" />
            <v-data-table
              v-else
              :headers="gptHeaders"
              :items="gptConversations"
              :options.sync="gptConversationHistoryOptions"
              :server-items-length="gptTotalConversations"
              sort-by="createdAt"
              :sort-desc="true"
            >
              <template v-slot:[`item.requestBody.userId`]="{ item }">
                <span>
                  {{
                    item.requestBody.userId
                      ? item.requestBody.userId.split("-")[0]
                      : ""
                  }}
                </span>
              </template>
              <template v-slot:[`item.requestBody.query`]="{ item }">
                <span>
                  {{
                    item.requestBody.query > 50
                      ? item.requestBody.query.slice(0, 50) + "..."
                      : item.requestBody.query
                  }}
                </span>
              </template>
              <template v-slot:[`item.feedback`]="{ item }">
                <span>
                  <template v-if="item.feedback && item.feedback.type">
                    {{ item.feedback.type }}
                  </template>
                  <template v-else> N/A </template>
                </span>
              </template>
              <template v-slot:[`item.tokens`]="{ item }">
                <span>{{ item.tokens }}</span>
              </template>
              <template v-slot:[`item.requestUrl`]="{ item }">
                <span>
                  {{ determineEndpointType(item.requestUrl) }}
                </span>
              </template>
              <template v-slot:[`item.createdAt`]="{ item }">
                <div>
                  {{ formatDateTime(item.createdAt) }}
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="mr-1"
                      @click="redirectConversationDetails(item)"
                      >mdi-eye</v-icon
                    >
                  </template>
                  <span>{{ $vuetify.lang.t("$vuetify.common.details_header_txt") }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.gpt_train`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="mr-1"
                      @click="train_modal(item)"
                      >mdi-plus-circle</v-icon
                    >
                  </template>
                  <span>
                    {{ $vuetify.lang.t("$vuetify.gpt_history.add_to_training_data") }}
                  </span>
                </v-tooltip>
              </template>
            </v-data-table>
          </template>
        </v-card>
      </v-col>
    </v-row>
    <!--  Questionnaire log  -->
    <v-row>
      <v-col
        class="pa-0 px-3"
        xs="12"
        md="12"
        v-if="selectedProject.segment.name === PROJECT_SEGMENTS.PERSONALIZER"
      >
        <QuestionnaireLog :startDate="start_date" :endDate="end_date" />
      </v-col>
    </v-row>
    <!-- API call response summary -->
    <v-row v-if="selectedProject.segment.name === 'Personalizer'">
      <v-col xs="12" md="12">
        <v-card rounded outlined class="mx-auto px-2">
          <v-col md="6">
            <div class="primary--text font-weight-bold ml-n1">
              {{ $vuetify.lang.t("$vuetify.gpt_history.api_call_response_summary") }}
            </div>
          </v-col>
          <v-divider></v-divider>
          <Loader v-if="isLoading" class="mr-2 mt-6" />
          <v-data-table
            v-else
            :headers="headers"
            :items="requestDetails"
            :items-per-page="itemsPerPage"
          >
            <template v-slot:[`item.request_path`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ item.request_path }}</span>
                </template>
                <span>{{ item.request_path }}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.number_of_request`]="{ item }">
              <div>
                {{ item.number_of_request }}
              </div>
            </template>

            <template v-slot:[`item.avg_processing_time`]="{ item }">
              <div>
                {{ item.avg_processing_time.toFixed(5) }}
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- Device, Browser & OS ratio -->
    <template v-if="selectedProject.segment.name === 'Personalizer'">
      <v-container fluid>
        <v-row justify="center">
          <v-col cols="12" sm="6" md="4">
            <v-card outlined>
              <v-card-subtitle class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.insights.device_ratio_txt") }}
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-on="on" v-bind="attrs">
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>
                    {{ $vuetify.lang.t("$vuetify.insights.show_device_ratio_txt") }}
                  </span>
                </v-tooltip>
              </v-card-subtitle>
              <v-card-text>
                <Loader v-if="isLoading" />
                <v-container
                  v-else-if="
                    deviceRatio &&
                    Object.keys(deviceRatio).length > 0 &&
                    Object.values(deviceRatio).some(value => value !== 0)
                  "
                >
                  <v-row>
                    <v-col cols="12">
                      <div id="chart">
                        <apexchart
                          type="pie"
                          width="100%"
                          :options="deviceRatioOptions"
                          :series="deviceRatioValue"
                        >
                        </apexchart>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
                <v-card v-else flat height="250">
                  <v-row class="fill-height" align="center" justify="center">
                    <span class="grey--text">
                      {{ $vuetify.lang.t("$vuetify.common.no_data_available") }}
                    </span>
                  </v-row>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-card outlined>
              <v-card-subtitle class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.insights.browser_ratio_txt") }}
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-on="on" v-bind="attrs">
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>
                    {{ $vuetify.lang.t("$vuetify.insights.show_browser_ratio_txt") }}
                  </span>
                </v-tooltip>
              </v-card-subtitle>
              <v-card-text>
                <Loader v-if="isLoading" />
                <v-container
                  v-else-if="
                    browserRatio &&
                    Object.keys(browserRatio).length > 0 &&
                    Object.values(browserRatio).some(value => value !== 0)
                  "
                >
                  <v-row>
                    <v-col cols="12">
                      <div id="chart">
                        <apexchart
                          type="pie"
                          width="100%"
                          :options="browserRatioOptions"
                          :series="browserRatioValue"
                        >
                        </apexchart>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
                <v-card v-else flat height="250">
                  <v-row class="fill-height" align="center" justify="center">
                    <span class="grey--text">
                      {{ $vuetify.lang.t("$vuetify.common.no_data_available") }}
                    </span>
                  </v-row>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-card outlined>
              <v-card-subtitle class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.insights.os_ratio_txt") }}
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-on="on" v-bind="attrs">
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>
                    {{ $vuetify.lang.t("$vuetify.insights.show_os_ratio_txt") }}
                  </span>
                </v-tooltip>
              </v-card-subtitle>
              <v-card-text>
                <Loader v-if="isLoading" />
                <v-container
                  v-else-if="
                    osRatio &&
                    Object.keys(osRatio).length > 0 &&
                    Object.values(osRatio).some(value => value !== 0)
                  "
                >
                  <v-row>
                    <v-col cols="12">
                      <div id="Chart">
                        <apexchart
                          type="pie"
                          width="102%"
                          :options="osRatioOptions"
                          :series="osRatioValue"
                        ></apexchart>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
                <v-card v-else flat height="250">
                  <v-row class="fill-height" align="center" justify="center">
                    <span class="grey--text">
                      {{ $vuetify.lang.t("$vuetify.common.no_data_available") }}
                    </span>
                  </v-row>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <!-- Add Gpt Response to Training Data Modal -->
    <template>
      <v-dialog persistent v-model="openTrainModal" max-width="60%">
        <v-card style="overflow-y: scroll; overflow-x: hidden">
          <div class="d-flex flex-column modalWrapper">
            <p class="training-header">
              {{
                $vuetify.lang.t("$vuetify.gpt_history.add_gpt_response_to_training_data")
              }}
            </p>
            <label class="text-subtitle-1 text-medium-emphasis">
              {{ $vuetify.lang.t("$vuetify.gpt_history.gpt_history_conversation_id") }}:
              <span class="conversation_id">
                {{ trainingData.conversation_id }}
              </span>
            </label>

            <v-form ref="documentForm">
              <div class="inputWrapper">
                <v-select
                  @change="handleDataset"
                  v-model="selectedDataset"
                  :items="datasets"
                  item-text="name"
                  :label="$vuetify.lang.t('$vuetify.gpt_dataset.select_dataset')"
                  outlined
                  item-value="dataset_id"
                  :rules="getRules($vuetify.lang.t('$vuetify.gpt_dataset.select_dataset'))"
                >
                </v-select>
                <div v-if="!newDataSet" class="dummy-width"></div>
              </div>
            </v-form>
            <template v-if="newDataSet">
              <div>
                <v-form ref="datasetForm">
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        type="text"
                        v-model="newDatasetName"
                        :label="$vuetify.lang.t('$vuetify.gpt_dataset.dataset_name')"
                        :rules="[
                          required($vuetify.lang.t('$vuetify.gpt_dataset.dataset_name')),
                        ]"
                        outlined
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        type="text"
                        v-model="indexColumnName"
                        :label="$vuetify.lang.t('$vuetify.gpt_dataset.index_column_name')"
                        :rules="[
                          required(
                            $vuetify.lang.t('$vuetify.gpt_dataset.index_column_name'),
                          ),
                        ]"
                        outlined
                      />
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        type="text"
                        v-model="secondaryIndex"
                        :label="
                          $vuetify.lang.t('$vuetify.gpt_dataset.secondary_index_column')
                        "
                        outlined
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        type="text"
                        v-model="imageColumn"
                        :label="$vuetify.lang.t('$vuetify.gpt_dataset.image_url_column')"
                        outlined
                      />
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        type="text"
                        v-model="description"
                        :label="
                          $vuetify.lang.t('$vuetify.gpt_dataset.description_label_txt')
                        "
                        outlined
                      />
                    </v-col>
                    <v-checkbox
                      style="margin-left: 10px"
                      v-model="fillUpEmptyIndex"
                      :label="
                        $vuetify.lang.t('$vuetify.gpt_dataset.fill_up_index_if_empty')
                      "
                    ></v-checkbox>
                    <v-col cols="12" md="12">
                      <v-file-input
                        outlined
                        accept=".json, .csv, application/json, text/csv"
                        @change="handleFileChange"
                        :label="$vuetify.lang.t('$vuetify.gpt_dataset.dataset_file')"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                </v-form>
                <div class="btn-wrapper" style="margin-top: 20px">
                  <v-btn
                    type="reset"
                    class="red--text font-weight-bold"
                    text
                    outlined
                    @click="closeTrainModal"
                    style="margin-right: 14px"
                  >
                    {{ this.$vuetify.lang.t("$vuetify.common.cancel_btn") }}
                  </v-btn>
                  <v-btn
                    @click="createDataset()"
                    color="primary"
                    style=""
                    class="add-btn"
                    >{{ $vuetify.lang.t("$vuetify.gpt_dataset.create_dataset") }}</v-btn
                  >
                </div>
              </div>
            </template>
            <template v-else>
              <div class="inputWrapper">
                <v-textarea
                  type="text"
                  auto-grow
                  v-model="trainingData.query"
                  :label="$vuetify.lang.t('$vuetify.gpt_history.gpt_history_query')"
                  outlined
                />
                <input
                  class="checkbox"
                  type="checkbox"
                  name="myCheckbox"
                  v-model="selectedResponseFields.query"
                />
              </div>
              <div class="inputWrapper">
                <v-textarea
                  type="text"
                  auto-grow
                  v-model="trainingData.response"
                  :label="$vuetify.lang.t('$vuetify.gpt_history.gpt_history_response')"
                  outlined
                />
                <input
                  class="checkbox"
                  type="checkbox"
                  name="myCheckbox"
                  v-model="selectedResponseFields.response"
                />
              </div>
              <div class="inputWrapper">
                <v-textarea
                  type="text"
                  auto-grow
                  v-model="trainingData.feedback"
                  :label="$vuetify.lang.t('$vuetify.gpt_history.gpt_history_feedback')"
                  outlined
                />
                <input
                  class="checkbox"
                  type="checkbox"
                  name="myCheckbox"
                  v-model="selectedResponseFields.feedback"
                />
              </div>
              <v-form ref="conversationMetadata">
                <div class="inputWrapper">
                  <v-textarea
                    type="text"
                    auto-grow
                    v-model="trainingData.conversation_metadata"
                    :label="
                      $vuetify.lang.t(
                        '$vuetify.gpt_history.gpt_history_conversation_metadata',
                      )
                    "
                    outlined
                    :rules="
                      selectedResponseFields.conversation_metadata
                        ? [validateConversationMetadata]
                        : []
                    "
                  />
                  <input
                    class="checkbox"
                    type="checkbox"
                    name="myCheckbox"
                    v-model="selectedResponseFields.conversation_metadata"
                  />
                </div>
              </v-form>
              <div class="btn-wrapper">
                <v-btn
                  type="reset"
                  class="red--text font-weight-bold"
                  text
                  outlined
                  @click="closeTrainModal"
                  style="margin-right: 14px"
                >
                  {{ this.$vuetify.lang.t("$vuetify.common.cancel_btn") }}
                </v-btn>
                <v-btn
                  @click="addTrainingData"
                  color="primary"
                  style="margin-right: 44px; min-width: 120px"
                  class="add-btn"
                  >{{ $vuetify.lang.t("$vuetify.common.add_btn") }}</v-btn
                >
              </div>
            </template>
          </div>
        </v-card>
      </v-dialog>
    </template>
    <!--Conversation History File Generation and History Modal -->
    <template v-if="gptConversationFileGenerationModal">
      <FileGenerationModal
        :open="gptConversationFileGenerationModal"
        :closeModal="closeGptConversationFileGenerationModal"
        :title="
          $vuetify.lang.t(
            '$vuetify.logs_file_generation.conversation_logs_file_generation_title',
          )
        "
        :history_type="HISTORY_TYPES.CONVERSATION"
      />
    </template>
    <template v-if="gptConversationHistoryModal">
      <FileGeneratedHistoryModal
        :open="gptConversationHistoryModal"
        :closeModal="closeGptConversationHistoryModal"
        :title="
          $vuetify.lang.t(
            '$vuetify.logs_file_generation.conversation_logs_file_generated_history_title',
          )
        "
        :history_type="HISTORY_TYPES.CONVERSATION"
      />
    </template>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import {
  ADD_CONVERSATION_TO_DATASET,
  CREATE_DATASET,
  GET_GPT_DATASETS,
  RECOMMENDER_INSIGHTS,
  RECOMMENDER_UPDATE_FEEDBACK,
  RETRIEVE_GPT_CONVERSATIONS,
  UPLOAD_DATASET,
} from "@/store/_actiontypes";
import { SEGMENTS, HISTORY_TYPES } from "@/global/_constants";
import { DATETIME_PICKER, TEXT } from "@/components/common/input/_inputTypes";
import {
  dateOfToday,
  encryptData,
} from "@/helpers/helper";
import Loader from "@/components/common/loader/Loader";
import VueApexCharts from "vue-apexcharts";
import TopBrowsedItems from "./Insights/TopBrowsedItems.vue";
import TopPurchasedItems from "./Insights/TopPurchasedItems.vue";
import TopRatedItems from "./Insights/TopRatedItems.vue";
import RepeatedUsers from "./Insights/RepeatedUsers.vue";
import QuestionnaireLog from "./Insights/QuestionnaireLog.vue";
import { debounce } from "lodash";
import FileGenerationModal from "@/components/common/FileGenerationModal";
import FileGeneratedHistoryModal from "@/components/common/FileGeneratedHistoryModal";

export default {
  props: {},
  components: {
    Loader,
    apexchart: VueApexCharts,
    TopBrowsedItems,
    TopPurchasedItems,
    TopRatedItems,
    RepeatedUsers,
    QuestionnaireLog,
    FileGenerationModal,
    FileGeneratedHistoryModal,
  },
  computed: {
    ...mapState({
      selectedProject: state => state.project.selectedProject,
      insights: state => state.insight.recommender_insights,
      gptConversationsGlobal: state => {
        return state.gpt.gpt_conversations.conversations;
      },
      gptTotalConversations: state => state.gpt.gpt_conversations.total,
      maxDate() {
        let currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 1);
        return this.formatDate(currentDate);
      },
      dateRangeText: function () {
        return this.dates.join(" ~ ");
      },
    }),
    ...mapGetters("loader", ["loading"]),
    overview: function () {
      return this.insights.overview;
    },
    isMobile() {
      return window.innerWidth <= 768;
    },
    itemChartData() {
      const { browse, purchase, ratings } = this.insights.kpi_summary || {};
      const data = [
        { x: "Browsed", y: browse?.total_browsed_items || 0 },
        { x: "Positive Rated", y: ratings?.total_positive_rated_items || 0 },
        { x: "Negative Rated", y: ratings?.total_negative_rated_items || 0 },
        { x: "Purchased", y: purchase?.total_purchased_items || 0 },
      ];
      return data.every(({ y }) => y === 0) ? [] : data;
    },
    userChartData() {
      const { browse, purchase, ratings } = this.insights.kpi_summary || {};
      const data = [
        { x: "Browsed", y: browse?.total_unique_users_browsed || 0 },
        { x: "Rated", y: ratings?.total_unique_users_rated || 0 },
        { x: "Purchased", y: purchase?.total_unique_users_purchased || 0 },
      ];
      return data.every(({ y }) => y === 0) ? [] : data;
    },
    kpiSummary: function () {
      return this.insights.kpi_summary;
    },
    deviceRatio: function () {
      return this.insights.device_ratio ?? {};
    },
    browserRatio: function () {
      return this.insights.browser_ratio ?? {};
    },
    osRatio: function () {
      return this.insights.os_ratio ?? {};
    },
    requestDetails: function () {
      return this.insights?.request_details
        ? this.insights?.request_details
        : [];
    },
    gptDetails: function () {
      let gpt_history = this.insights.gpt_history;
      if (!gpt_history) {
        gpt_history = [];
      }
      return gpt_history;
    },
    filteredGptDetails() {
      return this.gptDetails.filter(
        item =>
          (!this.userFilter ||
            item.user_id
              ?.toLowerCase()
              .includes(this.userFilter.toLowerCase())) &&
          (!this.feedbackFilter ||
            (item.feedback && item.feedback.type === this.feedbackFilter) ||
            (item.feedback === null && this.feedbackFilter === "N/A")) &&
          (!this.promptFilter ||
            item.query
              .toLowerCase()
              .includes(this.promptFilter.toLowerCase())) &&
          (!this.endpointFilter ||
            (item.request_path &&
              this.determineEndpointType(item.request_path) ===
                this.endpointFilter) ||
            (item.request_path === null && this.endpointFilter === "N/A")),
      );
    },

    isDownloadButtonVisible() {
      return (
        this.filteredGptDetails.length > 0 || this.gptConversations.length > 0
      );
    },
    formattedPercentages() {
      return {
        percentageRecommend: this.percentageFormatted("percentageRecommend"),
        percentageSearch: this.percentageFormatted("percentageSearch"),
        percentageOrders: this.percentageFormatted("percentageOrders"),
        percentageRevenue: this.percentageFormatted("percentageRevenue"),
        percentageAvgCartSize: this.percentageFormatted(
          "percentageAvgCartSize",
        ),
        percentageAvgSaleOrder: this.percentageFormatted(
          "percentageAvgSaleOrder",
        ),
        percentageAvgSaleUser: this.percentageFormatted(
          "percentageAvgSaleUser",
        ),
        percentageAvgSaleItems: this.percentageFormatted(
          "percentageAvgSaleItems",
        ),
      };
    },
    topUsers() {
      return (
        (this.kpiSummary &&
          this.kpiSummary.purchase &&
          this.kpiSummary.purchase.top_users) ||
        []
      );
    },
    repeatedUsers() {
      return this.insights.repeat_users || [];
    },
  },
  data() {
    return {
      isLoading: false,
      PROJECT_SEGMENTS: SEGMENTS,
      HISTORY_TYPES: HISTORY_TYPES,
      gptConversationFileGenerationModal: false,
      gptConversationHistoryModal: false,
      dateOfToday,
      TEXT: TEXT,
      DATETIME_PICKER: DATETIME_PICKER,
      start_date: "",
      end_date: "",
      last_insights_updated_at: "",
      menuDates: false,
      dynamicRowCount: 1,
      gptConversationHistoryOptions: {},
      snackbarMessage: "",
      dates: [],
      on: {},
      activeTab: "item",
      tab: null,
      userHeaders: [
        { text: "User ID", value: "user_id", sortable: false },
        { text: "Member ID", value: "member_id", sortable: false },
        { text: "Purchased Amount", value: "purchased_amount", sortable: true },
      ],
      selectedDataset: "",
      dataNotSelectedError: false,
      datasets: [
        {
          name: this.$vuetify.lang.t("$vuetify.gpt_dataset.add_new_dataset"),
          dataset_id: "new_dataset",
        },
      ],
      selectedResponseFields: {
        query: true,
        response: true,
        feedback: true,
        conversation_metadata: false,
      },
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.gpt_history.request_path_header_txt"),
          align: "start",
          sortable: true,
          value: "request_path",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.gpt_history.number_of_request_header_txt"),
          align: "start",
          sortable: true,
          value: "number_of_request",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.gpt_history.avg_processing_time_header_txt"),
          align: "start",
          sortable: true,
          value: "avg_processing_time",
        },
      ],
      gptHeaders: [
        {
          text: this.$vuetify.lang.t("$vuetify.gpt_history.gpt_history_user_id"),
          align: "start",
          sortable: false,
          value: "requestBody.userId",
        },
        // {
        //   text: this.$vuetify.lang.t("$vuetify.gpt_history.gpt_history_conversation_id"),
        //   align: "start",
        //   sortable: true,
        //   value: "conversation_id",
        // },
        {
          text: this.$vuetify.lang.t("$vuetify.gpt_history.gpt_history_query"),
          align: "start",
          sortable: false,
          value: "requestBody.query",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.gpt_history.gpt_history_feedback"),
          align: "start",
          sortable: false,
          value: "feedback",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.gpt_history.gpt_history_consumed_tokens"),
          align: "start",
          sortable: true,
          value: "tokens",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.gpt_history.gpt_history_endpoint"),
          align: "start",
          sortable: false,
          value: "requestUrl",
        },
        // {
        //   text: this.$vuetify.lang.t("$vuetify.gpt_history.gpt_history_response"),
        //   align: "start",
        //   sortable: true,
        //   value: "response_msg",
        // },
        {
          text: this.$vuetify.lang.t("$vuetify.gpt_history.gpt_history_date"),
          align: "start",
          sortable: true,
          value: "createdAt",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.common.details_header_txt"),
          align: "center",
          sortable: false,
          value: "actions",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.gpt_history.add_to_training_data"),
          align: "center",
          sortable: false,
          value: "gpt_train",
        },
      ],
      optionalDataMapper: {
        secondary_idx_column: "secondaryIndex",
        image_url_column: "imageColumn",
        description: "description",
        dataset_file: "datasetFile",
      },
      required(fieldName) {
      return (value) => {
        return !!value || `${fieldName}${this.$vuetify.lang.t('$vuetify.validation.required_error_msg_txt')}`;
      };
    },
      getRules(fieldName) {
        const rules = [];
        if (!this.newDataSet) {
          rules.push(value => !!value || `${fieldName} is required`);
        }
        return rules;
      },
      snackbar: false,
      timeout: 6000,
      snackbarColor: "success",
      newDataSet: false,
      newDatasetName: "",
      indexColumnName: "",
      secondaryIndex: "",
      fillUpEmptyIndex: true,
      imageColumn: "",
      description: "",
      datasetFile: null,
      itemsPerPage: 5,
      currentPage: 1,
      openTrainModal: false,
      trainingData: {},
      userFilter: "",
      conversationSearch: "",
      conversationFilter: "",
      promptFilter: "",
      feedbackFilter: "",
      endpointFilter: "",
      feedbackOptions: ["positive", "negative", "N/A"],
      percentageChange: 0,
      percentageRevenue: 0,
      percentageOrders: 0,
      percentageAvgSaleUser: 0,
      percentageAvgSaleOrder: 0,
      percentageAvgSaleItems: 0,
      percentageAvgCartSize: 0,
      percentageRecommend: 0,
      percentageSearch: 0,
      totalNumberOfSearches: 0,
      totalNumberOfRecommendations: 0,
      averageCartSize: 0,
      oldValue: 0,
      newValue: 0,
      isLineChart: false,
      endpointOptions: ["Ask", "Vision"],
      downloading: false,
      deviceRatioValue: [],
      browserRatioValue: [],
      osRatioValue: [],
      gptConversations: [],
      deviceRatioOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (val) {
              return (val * 100).toFixed(2) + "%";
            },
          },
        },
        labels: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
              cursor: {
                show: true,
              },
            },
          },
        ],
      },
      browserRatioOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (val) {
              return (val * 100).toFixed(2) + "%";
            },
          },
        },
        labels: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      osRatioOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (val) {
              return (val * 100).toFixed(2) + "%";
            },
          },
        },
        labels: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      itemlineChart: {
        chart: {
          height: 400,
          type: "line",
          toolbar: {
            show: false,
            tools: {
              download: false,
            },
            autoSelected: "zoom",
          },
        },
        plotOptions: {
          line: {
            curve: "smooth",
            markers: {
              size: 6,
              colors: ["#008FFB"],
              strokeColors: "#fff",
              strokeWidth: 2,
              hover: {
                size: 8,
              },
            },
          },
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: [
            "Browsed",
            "Positive Rated",
            "Negative Rated",
            "Purchased",
          ],
        },
        yaxis: {
          logarithmic: true,
          min: 1,
        },
        tooltip: {
          x: {
            formatter: function () {
              return "";
            },
          },
          y: {
            formatter: function (val, { dataPointIndex, _w }) {
              const categories = [
                "Browse",
                "Positive Rated",
                "Negative Rated",
                "Purchase",
              ];
              const label = categories[dataPointIndex];
              return `${label}: ${val.toLocaleString()}`;
            },
            title: {
              formatter: function () {
                return "";
              },
            },
          },
          followCursor: true,
        },
        title: {
          text: this.$vuetify.lang.t("$vuetify.insights.items_counts"),
          align: "center",
          margin: 20,
          style: {
            fontSize: "20px",
            fontFamily: "Helvetica, Arial, sans-serif",
            color: "#333333",
          },
        },
      },
      userlineChart: {
        chart: {
          height: 400,
          type: "line",
          toolbar: {
            show: false,
            tools: {
              download: false,
            },
            autoSelected: "zoom",
          },
        },

        plotOptions: {
          line: {
            curve: "smooth",
            markers: {
              size: 6,
              colors: ["#008FFB"],
              strokeColors: "#fff",
              strokeWidth: 2,
              hover: {
                size: 8,
              },
            },
          },
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: ["Browsed", "Rated", "Purchased"],
        },
        yaxis: {
          logarithmic: true,
          min: 2,
        },
        tooltip: {
          x: {
            formatter: function () {
              return "";
            },
          },
          y: {
            formatter: function (val, { dataPointIndex, _w }) {
              const categories = ["Browse", "Rated", "Purchase"];
              const label = categories[dataPointIndex];
              return `${label}: ${val.toLocaleString()}`;
            },
            title: {
              formatter: function () {
                return "";
              },
            },
          },
          followCursor: true,
        },
        title: {
          text: this.$vuetify.lang.t("$vuetify.insights.users_counts"),
          align: "center",
          margin: 20,
          style: {
            fontSize: "20px",
            fontFamily: "Helvetica, Arial, sans-serif",
            color: "#333333",
          },
        },
      },
      itemBarChart: {
        chart: {
          type: "bar",
          height: 400,
          toolbar: {
            show: false,
            tools: {
              download: false,
            },
            autoSelected: "zoom",
          },
        },
        colors: ["#008FFB", "#FF4560", "#33B2DF", "#00E396"],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
            dataLabels: {
              position: "bottom",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "Browse",
            "Positive Rated",
            "Negative Rated",
            "Purchase",
          ],
          labels: {
            style: {
              colors: "#000000",
              fontSize: "14px",
              fontFamily: "Helvetica, Arial, sans-serif",
            },
          },
        },
        yaxis: {
          logarithmic: true,
          min: 1,
          // tickAmount: 'dataPoints',
          // forceNiceScale: false
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          x: {
            formatter: function () {
              return "";
            },
          },
          y: {
            formatter: function (val, { dataPointIndex, _w }) {
              const categories = [
                "Browse",
                "Positive Rated",
                "Negative Rated",
                "Purchase",
              ];
              const label = categories[dataPointIndex];
              return `${label}: ${val.toLocaleString()}`;
            },
            title: {
              formatter: function () {
                return "";
              },
            },
          },
          followCursor: true,
        },
        title: {
          text: this.$vuetify.lang.t("$vuetify.insights.items_counts"),
          align: "center",
          margin: 20,
          style: {
            fontSize: "20px",
            fontFamily: "Helvetica, Arial, sans-serif",
            color: "#333333",
          },
        },
        responsive: [
          {
            breakpoint: 768,
            options: {
              chart: {
                height: 300,
              },
              xaxis: {
                labels: {
                  style: {
                    fontSize: "12px",
                  },
                },
              },
              yaxis: {
                logarithmic: true,
                labels: {
                  style: {
                    fontSize: "12px",
                  },
                },
              },
              title: {
                style: {
                  fontSize: "16px",
                },
              },
            },
          },
        ],
      },
      userBarChart: {
        chart: {
          type: "bar",
          height: 400,
          toolbar: {
            show: false,
            tools: {
              download: false,
              // selection: true,
              // zoom: true,
              // zoomin: true,
              // zoomout: true,
              // pan: true,
              // reset: true | '<img src="/static/icons/reset.png" width="20">',
              // customIcons: [],
            },
            autoSelected: "zoom",
          },
        },
        colors: ["#008FFB", "#FF4560", "#33B2DF", "#00E396"],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
            dataLabels: {
              position: "bottom",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: ["Browsed", "Rated", "Purchase"],
          labels: {
            style: {
              colors: "#000000",
              fontSize: "14px",
              fontFamily: "Helvetica, Arial, sans-serif",
            },
          },
        },
        yaxis: {
          logarithmic: true,
          min: 2,
          labels: {
            style: {
              fontSize: "14px",
              fontFamily: "Helvetica, Arial, sans-serif",
            },
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          x: {
            formatter: function () {
              return "";
            },
          },
          y: {
            formatter: function (val, { dataPointIndex, _w }) {
              const categories = ["Browse", "Rated", "Purchase"];
              const label = categories[dataPointIndex];
              return `${label}: ${val.toLocaleString()}`;
            },
            title: {
              formatter: function () {
                return "";
              },
            },
          },
          followCursor: true,
        },
        title: {
          text: this.$vuetify.lang.t("$vuetify.insights.users_counts"),
          align: "center",
          margin: 20,
          style: {
            fontSize: "20px",
            fontFamily: "Helvetica, Arial, sans-serif",
            color: "#333333",
          },
        },
        responsive: [
          {
            breakpoint: 768,
            options: {
              chart: {
                height: 300,
              },
              xaxis: {
                min: 0,
                max: 100,
                labels: {
                  style: {
                    fontSize: "12px",
                  },
                },
              },
              yaxis: {
                min: 0,
                max: 100,
                labels: {
                  style: {
                    fontSize: "12px",
                  },
                },
              },
              title: {
                style: {
                  fontSize: "16px",
                },
              },
            },
          },
        ],
      },
    };
  },
  created() {
    if (this.selectedProject.segment.name !== "Maira") {
      this.getInsights();
    }

    // Creating a debounced version of the search method
    this.debouncedSearch = debounce(this.searchConversations, 500);
  },

  watch: {
    dates() {
      this.start_date = this.dates[0];
      this.end_date = this.dates[1];
    },
    conversationSearch() {
      this.debouncedSearch();
    },
    feedbackFilter() {
      this.debouncedSearch();
    },
    endpointFilter() {
      this.debouncedSearch();
    },
    gptConversationHistoryOptions: {
      handler() {
        this.handlePageChange();
      },
      deep: true,
    },
    gptConversationsGlobal(newValue, _oldVal) {
      this.gptConversations = newValue;
    },
    deviceRatio(newDeviceRatio) {
      this.updateRatioOptions(
        newDeviceRatio,
        "deviceRatioOptions",
        "deviceRatioValue",
      );
    },
    browserRatio(newBrowserRatio) {
      this.updateRatioOptions(
        newBrowserRatio,
        "browserRatioOptions",
        "browserRatioValue",
      );
    },
    osRatio(newOsRatio) {
      this.updateRatioOptions(newOsRatio, "osRatioOptions", "osRatioValue");
    },
  },
  mounted() {},
  methods: {
    ...mapActions("insight", [
      RECOMMENDER_INSIGHTS,
      RECOMMENDER_UPDATE_FEEDBACK,
    ]),
    ...mapActions("project", [GET_GPT_DATASETS]),
    ...mapActions("dataset", [
      CREATE_DATASET,
      ADD_CONVERSATION_TO_DATASET,
      UPLOAD_DATASET,
    ]),
    ...mapActions("gpt", [RETRIEVE_GPT_CONVERSATIONS]),
    capitalizeLabel(label) {
      return label.charAt(0).toUpperCase() + label.slice(1);
    },
    updateRatioOptions(ratioData, optionsKey, valueKey) {
      const labels = Object.keys(ratioData);
      const values = Object.values(ratioData);

      this[optionsKey].labels = labels.map(this.capitalizeLabel);
      this[valueKey] = values;
    },
    getAllDatasets() {
      this.GET_GPT_DATASETS({
        project_id: this.selectedProject.id,
        start: 0,
        size: 999,
      })
        .then(res => {
          this.datasets = [...this.datasets, ...res.data.datasets];
        })
        .catch(e => {
          console.error("Error on getting GPT datasets", e);
        });
    },
    sortConversations({ sortBy, sortDesc }) {
      this.gptConversations = this.gptConversations.sort((a, b) => {
        const sortA = a[sortBy[0]];
        const sortB = b[sortBy[0]];

        if (sortDesc[0]) {
          if (sortA < sortB) return 1;
          if (sortA > sortB) return -1;
          return 0;
        } else {
          if (sortA < sortB) return -1;
          if (sortA > sortB) return 1;
          return 0;
        }
      });
    },
    closeTrainModel() {
      this.openTrainModal = false;
    },
    handleDataset(value) {
      if (value === "new_dataset") {
        this.newDataSet = true;
        this.selectedDataset = "";
      } else {
        this.newDataSet = false;
      }
    },
    handleFileChange(file) {
      this.datasetFile = file;
    },
    createDataset() {
      if (!this.$refs.datasetForm.validate()) {
        this.showSnackBar(
          "error",
          this.$vuetify.lang.t("$vuetify.common.add_btn") +
            " " +
            this.$vuetify.lang.t("$vuetify.common.required_field"),
        );
      } else {
        const datasetRequestBody = {};
        datasetRequestBody.name = this.newDatasetName;
        datasetRequestBody.idx_column_name = this.indexColumnName;
        datasetRequestBody.is_idx_fillup_if_empty = this.fillUpEmptyIndex;

        for (const key in this.optionalDataMapper) {
          if (
            Object.prototype.hasOwnProperty.call(
              this.optionalDataMapper,
              key,
            ) &&
            this[this.optionalDataMapper[key]]
          ) {
            datasetRequestBody[key] = this[this.optionalDataMapper[key]];
          }
        }
        delete datasetRequestBody["dataset_file"];

        this.CREATE_DATASET({
          project_id: this.selectedProject.id,
          datasetRequestBody,
        })
          .then(res => {
            this.selectedDataset = res.data.detail.dataset_id;
            this.datasets.push({
              name: this.newDatasetName,
              dataset_id: res.data?.detail?.dataset_id,
              idx_column_name: this.indexColumnName,
            });
            if (this.datasetFile) {
              this.UPLOAD_DATASET({
                project_id: this.selectedProject.id,
                dataset_id: res.data?.detail?.dataset_id,
                dataset_file: this.datasetFile,
              })
                .then(_res => {
                  this.showSnackBar("success", "Dataset created successfully");
                })
                .catch(_err => {
                  this.showSnackBar("error", "Failed to upload dataset");
                });
            }
            this.newDataSet = false;
            this.showSnackBar(
              "success",
              this.$vuetify.lang.t("$vuetify.gpt_dataset.dataset_create_success"),
            );
          })
          .catch(err => {
            console.error("Error on creating dataset", err);
            this.showSnackBar(
              "error",
              this.$vuetify.lang.t("$vuetify.gpt_dataset.dataset_create_failed"),
            );
          });
      }
    },
    showSnackBar(type = "success", message = "Success") {
      this.snackbar = true;
      switch (type) {
        case "success":
          this.snackbarMessage = message;
          this.snackbarColor = "success";
          break;
        case "error":
          this.snackbarMessage = message;
          this.snackbarColor = "error";
          break;
      }
    },
    validateConversationMetadata(value) {
      try {
        JSON.parse(value);
        return true;
      } catch (error) {
        return this.$vuetify.lang.t(
          "$vuetify.gpt_history.gpt_history_conversation_metadata_invalid",
        );
      }
    },
    addTrainingData() {
      const isConversationMetadataValid = this.selectedResponseFields
        ?.conversation_metadata
        ? this.$refs.conversationMetadata.validate()
        : true;
      const isDocumentFormValid = this.$refs.documentForm.validate();
      if (!isConversationMetadataValid || !isDocumentFormValid) {
        this.showSnackBar(
          "error",
          this.$vuetify.lang.t("$vuetify.common.add_btn") +
            " " +
            this.$vuetify.lang.t("$vuetify.common.required_field"),
        );
        return;
      }
      const document = {};
      for (const key in this.selectedResponseFields) {
        if (
          Object.prototype.hasOwnProperty.call(
            this.selectedResponseFields,
            key,
          ) &&
          this.selectedResponseFields[key]
        ) {
          if (key === "conversation_metadata") {
            try {
              document[key] = JSON.parse(this.trainingData[key]);
            } catch (error) {
              return;
            }
          } else {
            document[key] = this.trainingData[key];
          }
        }
      }
      document["conversation_id"] = this.trainingData?.conversation_id;
      const is_background_task = false;
      const documents = [document];
      this.ADD_CONVERSATION_TO_DATASET({
        project_id: this.selectedProject?.id,
        dataset_id: this.selectedDataset,
        documents,
        is_background_task,
      })
        .then(_res => {
          this.openTrainModal = false;
          this.showSnackBar(
            "success",
            this.$vuetify.lang.t("$vuetify.gpt_history.gpt_history_add_document_success"),
          );
        })
        .catch(err => {
          this.showSnackBar(
            "error",
            this.$vuetify.lang.t("$vuetify.gpt_history.gpt_history_add_document_failed"),
          );
          console.error("Error on adding conversation to dataset", err);
        });
    },

    determineEndpointType(requestPath) {
      const path = requestPath && (requestPath.type || requestPath);
      return path.includes("/v1/gpt/ask/vision")
        ? "Vision"
        : path.includes("/v1/gpt/ask")
          ? "Ask"
          : "N/A";
    },

    formatDate(date) {
      return date.toISOString().split("T")[0];
    },

    handlePageChange() {
      let { sortBy, sortDesc, page, itemsPerPage } =
        this.gptConversationHistoryOptions;
      let sortName = "created_at";
      let sortType = "desc";
      if (sortBy.length === 1 && sortDesc.length === 1) {
        sortName = sortBy[0] === "createdAt" ? sortName : sortBy[0];
        sortType = sortDesc[0] ? sortType : "asc";
      }

      if (this.dates.length === 1) {
        this.dates[1] = dateOfToday();
        this.end_date = dateOfToday();
      }
      let start = 0;
      if (itemsPerPage === -1) {
        itemsPerPage = this.gptTotalConversations;
      } else {
        start = (page - 1) * itemsPerPage;
      }
      const start_date = this.dates[0];
      const end_date = this.dates[1];
      this.getGptConversationHistory(
        start_date,
        end_date,
        start,
        itemsPerPage,
        sortName,
        sortType,
      );
    },
    searchConversations() {
      if (this.gptConversationHistoryOptions.page === 1) {
        this.handlePageChange();
        return;
      }
      this.gptConversationHistoryOptions.page = 1;
    },
    getGptConversationHistory(
      start_date,
      end_date,
      start = 0,
      size = 10,
      sortName = "created_at",
      sortType = "desc",
    ) {
      let project_id = this.selectedProject.id;
      this.RETRIEVE_GPT_CONVERSATIONS({
        project_id: project_id,
        start_date: start_date,
        end_date: end_date,
        start: start,
        size: size,
        query: this.promptFilter,
        feedback: this.feedbackFilter,
        endpoint: this.endpointFilter,
        userId: this.userFilter,
        sortName: sortName,
        sortType: sortType,
        search_text: this.conversationSearch,
      }).then(
        res => res,
        err => {
          console.error("Error on retrieving conversations", err);
        },
      );
    },
    getInsights() {
      this.isLoading = true;
      let project_id = this.selectedProject.id;
      if (this.dates.length === 1) {
        this.dates[1] = dateOfToday();
        this.end_date = dateOfToday();
      }
      this.RECOMMENDER_INSIGHTS({
        project_id: project_id,
        start_date: this.start_date ? this.start_date : "",
        end_date: this.end_date ? this.end_date : "",
      }).then(
        _response => {
          this.dates = [this.insights.start_date, this.insights.end_date];
          this.last_insights_updated_at = this.insights
            .last_insight_processed_time
            ? new Date(
                this.insights.last_insight_processed_time,
              ).toLocaleString(`${this.$vuetify.lang.current}-CA`, {
                timeZoneName: "short",
              })
            : "N/A";
          this.percentageAvgCartSize = this.insights.percentage_avg_cart_size;
          this.percentageAvgSaleItems = this.insights.percentage_avg_sale_items;
          this.percentageAvgSaleOrder = this.insights.percentage_avg_sale_order;
          this.percentageAvgSaleUser = this.insights.percentage_avg_sale_user;
          this.percentageOrders = this.insights.percentage_orders;
          this.percentageRecommend = this.insights.percentage_recommendations;
          this.percentageRevenue = this.insights.percentage_revenue;
          this.percentageSearch = this.insights.percentage_searches;
          this.totalNumberOfSearches = this.insights.total_number_of_searches;
          this.totalNumberOfRecommendations =
            this.insights.total_number_of_recommendations;
          this.averageCartSize = this.insights.average_cart_size;
          this.isLoading = false;
        },
        _error => {
          this.isLoading = false;
        },
      );
    },
    percentageFormatted(propertyName) {
      if (this[propertyName] === "N/A" || this[propertyName] === "---") {
        return this[propertyName];
      } else {
        return this[propertyName].toFixed(2) + "%";
      }
    },
    percentageColor(propertyName) {
      const percentage = this[propertyName];
      return percentage > 0 ? "green" : percentage < 0 ? "red" : "";
    },
    percentageIcon(propertyName) {
      const percentage = this[propertyName];
      if (isNaN(percentage) || !isFinite(percentage)) {
        return "";
      }
      return percentage > 0 ? "mdi-arrow-up" : "mdi-arrow-down";
    },
    redirectConversationDetails(item) {
      const conversation_id = item.id;
      const data = {
        project_id: this.selectedProject.id,
      };
      const parts = conversation_id.split('-');
      const secretKey = `${parts[0]}#${parts[parts.length - 1]}`;
      const urlSafeEncryptedData = encryptData(data, secretKey);
      const newTabUrl = this.$router.resolve({
        path: `/conversations/${conversation_id}?token=${urlSafeEncryptedData}`,
      }).href;
      window.open(newTabUrl, "_blank");
    },
    train_modal(data) {
      this.selectedResponseFields = {
        query: true,
        response: true,
        feedback: true,
        conversation_metadata: false,
      };
      this.openTrainModal = true;
      this.trainingData = {
        conversation_id: data.id,
        query: data.requestBody.query,
        response: data.response,
        feedback: data.feedback?.message ? data.feedback?.message : "",
        conversation_metadata: data.requestBody?.conversationMetadata
          ? JSON.stringify(data.requestBody?.conversationMetadata) === "{}"
            ? ""
            : JSON.stringify(data.requestBody?.conversationMetadata)
          : "",
      };
      this.getAllDatasets();
    },
    closeTrainModal() {
      this.openTrainModal = false;
    },
    setDates() {
      this.$refs.menuDates.save(this.dates);
      this.getInsights();
      if (this.dates.length === 1) {
        this.dates[1] = dateOfToday();
        this.end_date = dateOfToday();
      }
      const start_date = this.dates[0];
      const end_date = this.dates[1];
      this.getGptConversationHistory(start_date, end_date);
    },
    formatDateTime(dateTimeString) {
      // const options = {
      //   year: "numeric",
      //   month: "2-digit",
      //   day: "2-digit",
      //   hour: "2-digit",
      //   minute: "2-digit",
      //   second: "2-digit",
      // };
      const date = new Date(dateTimeString);
      // const formattedDate = date
      //   .toLocaleString("en-US", options)
      //   .replace(",", "");
      // Manual formatting to get the desired format: YYYY-MM-DD HH-MM-SS
      const yyyy = date.getFullYear();
      const mm = String(date.getMonth() + 1).padStart(2, "0");
      const dd = String(date.getDate()).padStart(2, "0");
      const hh = String(date.getHours()).padStart(2, "0");
      const min = String(date.getMinutes()).padStart(2, "0");
      const ss = String(date.getSeconds()).padStart(2, "0");
      return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
    },
    //Download button for top users
    generateFilename(reportType) {
      const startDate = this.start_date;
      const endDate = this.end_date;
      return `${this.selectedProject.id}_${reportType}_${startDate}_${endDate}.csv`;
    },
    downloadAsCSV(reportType, headers) {
      this.downloading = true;
      const filteredData =
        this.kpiSummary && this.kpiSummary.purchase
          ? this.kpiSummary.purchase.top_users
          : [];
      const filename = this.generateFilename(reportType);
      const csv = this.convertAsCSV(headers, filteredData);
      const blob = new Blob([csv], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.downloading = false;
    },
    convertAsCSV(headers, data) {
      const headerValues = headers.map(header => header.value);
      const headerRow = headers.map(header => `"${header.text}"`).join(",");
      const dataRows = data.map(item =>
        headerValues
          .map(value => {
            let cellValue = item[value];
            if (
              value === "member_id" &&
              (cellValue === undefined || cellValue === "")
            ) {
              cellValue = "N/A";
            }
            return `"${(cellValue + "").replace(/"/g, '""')}"`;
          })
          .join(","),
      );
      const csvRows = [headerRow, ...dataRows];
      return csvRows.join("\n");
    },
    closeGptConversationFileGenerationModal() {
      this.gptConversationFileGenerationModal = false;
    },
    closeGptConversationHistoryModal() {
      this.gptConversationHistoryModal = false;
    },
  },
};
</script>
<style scoped>
.response-message {
  white-space: pre-line;
}

.response-details-text {
  color: gray;
  white-space: pre-line;
}

.response-message-header {
  font-size: 20px;
}

.add-btn {
  min-width: 140px;
}

.btn-wrapper {
  display: flex;
  justify-content: flex-end;
}

.training-header {
  display: flex;
  justify-content: center;
  font-weight: 600;
}

.modalWrapper {
  padding: 50px 25px;
  gap: 20px;
}

.input-field {
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.textarea {
  height: 200px;
  padding: 10px;
  box-sizing: border-box;
}

.flex-grow {
  flex-grow: 1;
}

.inputWrapper {
  display: flex;
  align-items: center;
  gap: 30px;
}

.dummy-width {
  width: 17px;
}

.checkbox {
  cursor: pointer;
  transform: scale(1.5);
  margin-right: 5px;
}

.relative-pos {
  position: relative;
}

.absolute-pos {
  position: absolute;
}

.arrow-icon {
  right: 10px;
  top: 10px;
}

.text-subtitle-1 {
  font-weight: 600;
}

.conversation_id {
  font-weight: 400;
}

.required {
  color: red;
}

.response-message {
  white-space: pre-line;
}

.response-details-text {
  color: gray;
  white-space: pre-line;
}

.response-message-header {
  font-size: 20px;
}

.word-break {
  word-break: break-all;
}
</style>
<style>
.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
