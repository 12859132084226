<template>
  <div class="mt-4">
    <v-stepper v-model="e1">
      <v-stepper-header>
        <template v-for="n in steps">
          <v-stepper-step :key="`${n}-step`" :complete="e1 > n" :step="n">
            <!-- Step {{ n }} -->
            <div v-if="n === 1">{{ $vuetify.lang.t("$vuetify.setup_steps.project_basic_tab") }}</div>
            <!-- <div v-if="n === 2">
              {{ $vuetify.lang.t("$vuetify.project.project_app_infra_step_txt") }}
            </div> -->
            <div v-if="n === 2">{{ $vuetify.lang.t("$vuetify.setup_steps.review_step_txt") }}</div>
          </v-stepper-step>

          <v-divider v-if="n !== steps" :key="n"></v-divider>
        </template>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content v-for="n in steps" :key="`${n}-content`" :step="n">
          <div v-if="n === 1">
            <v-form ref="form1">
              <v-row>
                <v-col cols="12" xs="12" md="12">
                  <v-text-field
                    type="text"
                    v-model="projectName"
                    :label="$vuetify.lang.t('$vuetify.project.project_name_header_txt')"
                    :placeholder="$vuetify.lang.t('$vuetify.project.project_name_placeholder_text')"
                    outlined
                    :rules="[required($vuetify.lang.t('$vuetify.project.project_name_header_txt'))]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <v-select
                    v-model="language"
                    :items="languageOptions"
                    :label="$vuetify.lang.t('$vuetify.project.language_header_txt')"
                    :disabled="isUpdate"
                    :rules="[required($vuetify.lang.t('$vuetify.project.language_header_txt'))]"
                    outlined
                  ></v-select>
                </v-col>
                <!-- <v-col cols="12" xs="12" md="6">
                  <v-select
                    v-model="subscriptionType"
                    :items="subscriptionTypes"
                    item-text="txt"
                    item-value="val"
                    :label="$vuetify.lang.t('$vuetify.project.subs_type_label_txt')"
                    :rules="[required($vuetify.lang.t('$vuetify.project.subs_type_label_txt'))]"
                    :disabled="(selectedProject.subscription_type=='paid' && isUpdate ? isUpdate : false)"
                    outlined
                  ></v-select>
                </v-col> -->
                
                <v-col cols="12" xs="12" md="6">
                  <v-autocomplete
                    v-model="appCategory"
                    :items="allCategory"
                    item-text="name"
                    item-value="id"
                    :label="$vuetify.lang.t('$vuetify.project.industry_header_txt')"
                    outlined
                    :search-input.sync="searchCategory"
                    :rules="[required($vuetify.lang.t('$vuetify.project.industry_header_txt'))]"
                    :disabled="isUpdate"
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" xs="12" md="12" v-if="PROJECT_SEGMENTS.Maira !== solutionType">
                  <v-autocomplete
                    v-model="selectedSolutionID"
                    :items="solutionOptions"
                    item-text="name"
                    item-value="id"
                    :label="$vuetify.lang.t('$vuetify.project.enable_solution_label_txt')"
                    outlined
                    small-chips
                    multiple
                    deletable-chips
                    :rules="[
                      (v) =>
                        !!v.length ||
                        $vuetify.lang.t('$vuetify.project.enable_solution_error_msg_txt'),
                    ]"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" md="12" v-else>
                  <v-textarea 
                    v-model="gpt_purpose"
                    :label="$vuetify.lang.t('$vuetify.project.purpose_of_gpt_label_txt')"
                    outlined>
                  </v-textarea>
                  <v-col></v-col>
                  <v-autocomplete
                    v-model="gpt_randomness"
                    :label="$vuetify.lang.t('$vuetify.project.randomness_label_txt')"
                    :items="gptRandomnessOptions"
                    item-text="name"
                    item-value="value"
                    outlined
                  ></v-autocomplete>
                  <v-col></v-col>
                    <v-autocomplete
                      v-model="selectedSolutionID"
                      :items="solutionOptions"
                      item-text="name"
                      item-value="id"
                      :label="$vuetify.lang.t('$vuetify.project.enable_solution_label_txt')"
                      outlined
                      small-chips
                      multiple
                      deletable-chips
                      :rules="[
                        (v) =>
                          !!v.length ||
                          $vuetify.lang.t('$vuetify.project.enable_solution_error_msg_txt'),
                      ]"
                    ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </div>
          

          <div v-if="n === 2">
            <v-card class="elevation-0">
              <v-card-title class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.setup_steps.project_basic_tab") }}
              </v-card-title>
              <v-divider></v-divider>
              <v-list>
                <v-list-item>
                  <v-list-item-content class="font-weight-bold">
                    {{ `${$vuetify.lang.t("$vuetify.project.project_name_header_txt")}:` }}
                  </v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ projectName }}
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content class="font-weight-bold align-self-start">
                    {{ `${$vuetify.lang.t("$vuetify.project.enabled_solution_label_txt")}:` }}
                  </v-list-item-content>
                  <v-list-item-content class="align-end">
                    <div
                      v-for="(item, index) in selectedSolutionNames"
                      :key="index"
                      class="py-2"
                    >
                      <v-icon
                        v-bind:color="selectedSolutionColor[index]"
                        size="21"
                        class="mr-1"
                      >
                        task_alt
                      </v-icon>
                      {{ item }}
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content class="font-weight-bold">
                    {{ `${$vuetify.lang.t("$vuetify.project.solution_header_txt")}:` }}
                  </v-list-item-content>
                  <v-list-item-content class="align-end">
                    <v-card flat>
                      <v-chip
                        v-if="selectedSolutionType"
                        class="ma-0"
                        v-bind:color="selectedSolutionColor[0]"
                        label
                        outlined
                        pill
                      >
                        {{ capitalizeFirstLetter(selectedSolutionType) }}
                      </v-chip>
                    </v-card>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content class="font-weight-bold">
                    {{ `${$vuetify.lang.t("$vuetify.project.industry_header_txt")}:` }}
                  </v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ selectedIndustryName }}
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content class="font-weight-bold">
                    {{ `${$vuetify.lang.t("$vuetify.project.language_header_txt")}:` }}
                  </v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ language ? language : "N/A" }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content class="font-weight-bold">
                    {{ `${$vuetify.lang.t("$vuetify.project.subs_type_label_txt")}:` }}
                  </v-list-item-content>
                  <v-list-item-content class="align-end">
                    <v-card flat>
                      <v-chip
                        class="ma-0 text-capitalize"
                        v-bind:color="getSubscriptionTypeColor(subscriptionType)"
                        label
                        outlined
                        pill
                      >
                        {{ subscriptionType ==="trial"  ? capitalizeFirstLetter(subscriptionType) : "Standard" }}
                      </v-chip>
                    </v-card>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>

            <v-card class="elevation-0">
              <!-- <v-card-title class="font-weight-bold">
                {{ `${$vuetify.lang.t("$vuetify.project.project_pricing")}` }}
              </v-card-title>
              <v-divider></v-divider> -->
              <v-list>
                <!-- <v-list-item>
                  <v-list-item-content class="font-weight-bold">
                    {{ `${$vuetify.lang.t("$vuetify.project.subs_type_label_txt")}:` }}
                  </v-list-item-content>
                  <v-list-item-content class="align-end">
                    <v-card flat>
                      <v-chip
                        class="ma-0 text-capitalize"
                        color="primary"
                        label
                        outlined
                        pill
                      >
                        {{ subscriptionType }}
                      </v-chip>
                    </v-card>
                  </v-list-item-content>
                </v-list-item> -->

                <!-- <template>
                  <div> -->
                    <!-- Pricing information based on type and trial period -->
                    <!-- <v-list>
                      <v-card v-if="selectedSolutionType === 'Personalizer' || selectedSolutionType === 'CV' || selectedSolutionType === 'NLU'" flat>
                        <v-list-item>
                          <v-list-item-content class="font-weight-bold">
                            Projects:
                          </v-list-item-content>
                          <v-list-item-content class="align-end">
                            Unlimited
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content class="font-weight-bold">
                            0 - 10,000 per month:
                          </v-list-item-content>
                          <v-list-item-content class="align-end">
                            $0.2 USD per API call
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content class="font-weight-bold">
                            10,000 - 20,000 per month:
                          </v-list-item-content>
                          <v-list-item-content class="align-end">
                            $0.1 USD per API call
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content class="font-weight-bold">
                            20,000 + per month:
                          </v-list-item-content>
                          <v-list-item-content class="align-end">
                            $0.05 USD per API call
                          </v-list-item-content>
                        </v-list-item>
                      </v-card> -->

                      <!-- Additional models and pricing information can be added here -->
                    <!-- </v-list>
                  </div>
                </template> -->



                <!-- <v-list-item>
                  <v-list-item-content class="font-weight-bold">
                    {{ `${$vuetify.lang.t("$vuetify.project.expected_request_label_txt")}:` }}
                  </v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ monthlyPV === '' ? formatNum(selectedSolution.max_pv) : formatNum(monthlyPV)}}
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content class="font-weight-bold">
                    {{ `${$vuetify.lang.t("$vuetify.project.request_per_sec_label_txt")}:` }}
                  </v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ expectedRPS ? formatNum(expectedRPS) : "N/A" }}
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content class="font-weight-bold">
                    {{ `${$vuetify.lang.t("$vuetify.project.unique_user_label_txt")}:` }}
                  </v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ formatNum(monthlyUU) }}
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content class="font-weight-bold">
                    {{ `${$vuetify.lang.t("$vuetify.project.item_count_label_txt")}:` }}
                  </v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ formatNum(itemCount) }}
                  </v-list-item-content>
                </v-list-item> -->

                <!-- <v-list-item>
                  <v-list-item-content class="font-weight-bold">
                    {{ `${$vuetify.lang.t("$vuetify.project.cloud_provider_label_txt")}:` }}
                  </v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ activePlan ? activePlan : "N/A" }}
                  </v-list-item-content>
                </v-list-item> -->

                <!-- <v-list-item>
                  <v-list-item-content class="font-weight-bold">
                    {{
                      `${$vuetify.lang.t(
                        "$vuetify.project.cloud_provider_label_txt"
                      )} ${$vuetify.lang.t("$vuetify.project.region_label_txt")}:`
                    }}
                  </v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ selectedRegionName ? selectedRegionName : "N/A" }}
                  </v-list-item-content>
                </v-list-item> -->

                <!-- <v-list-item>
                  <v-list-item-content class="font-weight-bold">
                    {{ `${$vuetify.lang.t("$vuetify.project.est_infra_cost_label_txt")}:` }}
                  </v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ formatNum(projectCost.infra_fee) }}
                    {{ selectedProject.currency ? selectedProject.currency : "USD" }}
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content class="font-weight-bold">
                    {{ `${$vuetify.lang.t("$vuetify.project.subs_cost_label_txt")}:` }}
                  </v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ formatNum(projectCost.subscription_fee) }}
                    {{ selectedProject.currency ? selectedProject.currency : "USD" }}
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content class="font-weight-bold">
                    {{ `${$vuetify.lang.t("$vuetify.project.est_total_cost_label_txt")}:` }}
                  </v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ formatNum(projectCost.billing_amount) }}
                    {{ selectedProject.currency ? selectedProject.currency : "USD" }}
                  </v-list-item-content>
                </v-list-item> -->

                <!-- <v-list-item>
                  <v-list-item-content class="font-weight-bold">
                    {{ `${$vuetify.lang.t("$vuetify.project.auto_scaling_label_txt")}:` }}
                  </v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ autoScaling ? "Enabled" : "Disabled" }}
                  </v-list-item-content>
                </v-list-item> -->
              </v-list>
            </v-card>
          </div>
          <br />
          <v-row align="center" justify="start">
            <v-col cols="12">
              <v-btn
                v-if="n !== 1"
                color="primary"
                class="mr-4"
                large
                outlined
                :loading="false"
                @click="previousStep(n)"
                >{{ $vuetify.lang.t("$vuetify.common.prev_btn") }}</v-btn
              >
              <v-btn
                v-if="n === 1"
                color="error"
                class="mr-2"
                large
                outlined
                :loading="false"
                @click="goBack()"
                >{{ $vuetify.lang.t("$vuetify.common.cancel_btn") }}</v-btn
              >
              <v-btn
                v-if="n === 2"
                color="primary"
                class="mr-2"
                large
                :loading="isCreatingProject"
                @click="saveProject()"
                >{{ $vuetify.lang.t("$vuetify.common.save_btn") }}</v-btn
              >
              <v-btn
                v-else
                color="primary"
                class="mr-2"
                large
                :loading="false"
                @click="nextStep(n)"
                >{{ $vuetify.lang.t("$vuetify.common.continue_btn") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-dialog v-model="createCategoryDialog" width="600">
      <v-card>
        <v-card-title class="headline primary white--text" primary-title>
          {{ $vuetify.lang.t("$vuetify.solution.create_industry_header_txt") }}
        </v-card-title>
        <div class="pa-5">
          <v-form ref="categoryForm">
            <v-text-field
              type="text"
              v-model="categoryName"
              :label="$vuetify.lang.t('$vuetify.solution.industry_name_label_txt')"
              outlined
              :rules="[required($vuetify.lang.t('$vuetify.solution.industry_name_label_txt'))]"
            />
            <v-textarea
              v-model="categoryDescription"
              :label="$vuetify.lang.t('$vuetify.solution.description_label_txt')"
              :rules="[required($vuetify.lang.t('$vuetify.solution.description_label_txt'))]"
              outlined
            ></v-textarea>
          </v-form>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" class="mr-2" @click="createCategoryDialog = false">{{
            $vuetify.lang.t("$vuetify.common.close_btn")
          }}</v-btn>
          <v-btn color="primary" :loading="isCreatingInd" @click="saveCategory()">{{
            $vuetify.lang.t("$vuetify.common.save_btn")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <DialogModal
      v-model="alertDialog"
      title="Error"
      :message="alertMessage"
      :confirmButtonText="$vuetify.lang.t('$vuetify.common.ok_btn')"
      :cancelButton="false"
      @onConfirmation="alertDialog = false"
    />
    <DialogModal
      v-model="warningDialog"
      title="Warning"
      :message="alertMessage"
      :confirmButtonText="$vuetify.lang.t('$vuetify.common.yes_btn')"
      :cancelButtonText="$vuetify.lang.t('$vuetify.common.no_btn')"
      headlineClass="headline warning white--text"
      @onCancel="cancelDialog()"
      :persistent="true"
      @onConfirmation="resetMonthlyPV()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import {
  LOAD_ALL_CATEGORY,
  LOAD_ALL_SOLUTION_BY_TYPE,
  LOAD_PROJECT_BY_ID,
  LOAD_ALL_PROJECT,
  LOAD_RESOURCEMETADATA_BY_TYPE,
  LOAD_SOLUTION_BY_ID,
  LOAD_ACCOUNT,
  SAVE_CATEGORY,
  LOAD_PROJECT_COST,
  SAVE_PROJECT,
  UPDATE_PROJECT,
  SAVE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_FEE,
  ADD_ALERT,
  LOAD_REGION_BY_CLOUD_PROVIDER,
  LOAD_REGION_BY_ID,
} from "@/store/_actiontypes";
import {
  SEGMENTS
} from '@/global/_constants';
import {
  AUTO_COMPLETE,
  MULT_AUTO_COMPLETE,
  TEXT,
  TEXT_AREA,
  NUMBER,
  SELECT,
} from "@/components/common/input/_inputTypes";

import { RAISED_BUTTON, TEXT_BUTTON } from "@/components/common/button/_buttontypes";

import validations from "@/helpers/validations";

import { formatNum, capitalizeFirstLetter, getSubscriptionTypeColor } from "@/helpers/helper";

import DialogModal from "@/components/common/DialogModal";
import { NUMBER_FORMAT_LOCALE } from "@/global/_constants";

export default {
  props: {},
  components: {
    DialogModal
  },
  computed: {
    ...mapState({
      selectedSolution: (state) => state.solution.selectedSolution,
      allSolutionByType: (state) => state.solution.allSolutionByType,
      selectedRegion: (state) => state.region.selectedRegion,
      selectedProject: (state) => state.project.selectedProject,
      projectCost: (state) => state.project.projectCost,
      user: (state) => state.account.user,
      account: (state) => state.account,
      resourceMetadataByType: (state) => state.resource_metadata.resourceMetadataByType,
      allCategory: (state) => state.category.allCategory,
      allRegion: (state) => state.region.allRegion,
    }),
    ...mapGetters("loader", ["loading"]),
  },
  created() {
    let solution_id = this.$route.params.solution_id;
    let project_id = this.$route.params.project_id;
    this.solutionType = this.$route.query.type;
    if (solution_id) {
      this.getSolution(solution_id);
      this.isUpdate = false;
    } else if (project_id) {
      this.getProjectDetails();
      this.isUpdate = true;
      this.getAllCategory();
    } else {
      this.getSolution();
      this.isUpdate = false;
      this.getAllCategory();
      // this.getSolutionName();
      // this.getIndustryName();
    }
    this.getAccount();
  },
  data() {
    return {
      activePlan: "Amazon Web Services",
      cloud_providers: {
        "Amazon Web Services": "aws",
        "Google Cloud Platform": "gcp",
        "Microsoft Azure Services": "azure",
      },
      formatNum,
      getSubscriptionTypeColor,
      capitalizeFirstLetter,
      createCategoryDialog: false,
      PROJECT_SEGMENTS: SEGMENTS,
      e1: 1,
      steps: 2,
      TEXT: TEXT,
      TEXT_AREA: TEXT_AREA,
      NUMBER: NUMBER,
      AUTO_COMPLETE: AUTO_COMPLETE,
      MULT_AUTO_COMPLETE: MULT_AUTO_COMPLETE,
      RAISED_BUTTON: RAISED_BUTTON,
      TEXT_BUTTON: TEXT_BUTTON,
      SELECT: SELECT,
      ...validations,
      projectName: "",
      solutionType: "",
      selectedRegionName: "N/A",
      selectedSolutionID: [],
      selectedSolutionNames: [],
      selectedSolutionColor: [],
      solutionOptions: [],
      languageOptions: [
        {
          text: this.$vuetify.lang.t("$vuetify.project.language.english_txt"),
          value: "English",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.project.language.japanese_txt"),
          value: "Japanese",
        }
      ],
      selectedSolutionTypeID: "",
      selectedSolutionType: "",
      allSolutions:[],
      language: "",
      appCategory: "",
      appRegion: "",
      subscriptionType: "trial",
      subscriptionTypes: [
        {"txt": "Trial", "val": "trial"}, {"txt": "Paid", "val": "paid"}
      ],
      selectedIndustryName: "N/A",
      searchCategory: "",
      searchRegion: "",
      monthlyPV: "",
      expectedRPS: "",
      monthlyUU: "",
      itemCount: "",
      autoScaling: true,
      alertDialog: false,
      warningDialog: false,
      resourceOption: "",
      resource: {},
      userDefineRamAndCpu: [],
      userDefineRam: null,
      userDefineCpu: null,
      userDefineGpu: null,
      userDefineVolume: null,
      categoryOptions: [],
      category: {},
      categoryName: "",
      categoryDisplayName: "",
      categoryDescription: "",
      allCategories: [],
      alertMessage: this.$vuetify.lang.t("$vuetify.common.unknown_err_msg_txt"),
      solution_cost: 0,
      infra_cost: 0,
      total_cost: 0,
      projectRamCpuParams: [],
      minimum_infra: 1,
      cancelResetMonthlyPV: false,
      isUpdate: false,
      numberOptions: {
        locale: NUMBER_FORMAT_LOCALE,
        prefix: "",
        suffix: "",
        length: 15,
        precision: 0,
      },
      isCreatingInd: false,
      isCreatingProject: false,
      gpt_purpose: "",
      gptRandomnessOptions: [
        {
          value: "accurate_ans",
          name: this.$vuetify.lang.t("$vuetify.project.accurate_ans_label_txt")
        },
        {
          value: "generalized_ans",
          name: this.$vuetify.lang.t("$vuetify.project.generalized_ans_label_txt")
        }
      ],
      gpt_randomness: null,
      accountType: "",
      organization: {
        account_name: "",
        name: "",
        size: "",
        phone: "",
        vat_number: "",
        registration_number: "",
        country: "",
        region: "",
        city: "",
        postal_code: "",
        director_name: "",
        website: "",
        industry: "",
        email: "",
        logo: "logo will coming soon",
        address: "",
      }
    };
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
    type() {
      this.getSolutionByType();
    },
    appCategory(value) {
      this.searchCategory = "";
      this.categoryName = value;
      this.allCategory.forEach((object) => {
        if (object.display_name === value) {
          this.category = object;
        }
      });
    },
  },
  methods: {
    ...mapActions("solution", [LOAD_SOLUTION_BY_ID, LOAD_ALL_SOLUTION_BY_TYPE]),
    ...mapActions("project", [
      LOAD_PROJECT_BY_ID,
      LOAD_ALL_PROJECT,
      SAVE_PROJECT,
      UPDATE_PROJECT,
      LOAD_PROJECT_COST,
    ]),
    ...mapActions("account", [LOAD_ACCOUNT]),
    ...mapActions("subscription", [SAVE_SUBSCRIPTION, UPDATE_SUBSCRIPTION_FEE]),
    ...mapActions("resource_metadata", [LOAD_RESOURCEMETADATA_BY_TYPE]),
    ...mapActions("category", [LOAD_ALL_CATEGORY, SAVE_CATEGORY]),
    ...mapActions("alert", [ADD_ALERT]),
    ...mapActions("region", [LOAD_REGION_BY_CLOUD_PROVIDER, LOAD_REGION_BY_ID]),
    resetMonthlyPV() {
      this.warningDialog = false;
      var index = this.projectRamCpuParams.findIndex(
        (item) => item.value === this.userDefineRamAndCpu
      );
      this.monthlyPV = Math.round(this.selectedSolution.pv * (index + 1));
    },
    cancelDialog() {
      this.warningDialog = false;
      this.userDefineRamAndCpu = [
        this.selectedSolution.cpu * this.minimum_infra,
        this.selectedSolution.memory * this.minimum_infra,
      ];
    },
    createCategoryClick() {
      this.createCategoryDialog = !this.createCategoryDialog;
      this.categoryName = this.searchCategory;
    },
    getSolutionName() {
      this.selectedSolutionNames = [];
      this.selectedSolutionColor = [];

      let isTypeSet = false;
      
      this.solutionOptions.forEach((element) => {
        if (this.selectedSolutionID.includes(element.id)) {
          this.selectedSolutionNames.push(element.name);
          this.selectedSolutionColor.push(element.color);
        }
        if (this.selectedSolutionID.includes(element.id) && !isTypeSet) {
          this.selectedSolutionTypeID = element.segmentID;
          this.selectedSolutionType = element.segmentName;
          isTypeSet = true;
        }
      });
    },
    getIndustryName() {
      this.selectedIndustryName = "N/A";
      this.allCategories.forEach((element) => {
        if (this.appCategory === element.id) {
          this.selectedIndustryName = element.name;
        }
      });
    },
    getProjectCost() {
      this.LOAD_PROJECT_COST({
        solutionIDList: this.selectedSolutionID,
        monthlyPV: this.monthlyPV === '' ? this.selectedSolution.max_pv : this.monthlyPV,
        monthlyUU: this.monthlyUU === '' ? 0 : this.monthlyUU,
        itemCount: this.itemCount === '' ? 0 : this.itemCount,
        subscriptionType: this.subscriptionType
      }).then(
        (response) => {},
        (error) => error
      );
    },
    getResourceMetadataByType(solution_type) {
      this.resource = {};
      this.LOAD_RESOURCEMETADATA_BY_TYPE({
        solutionType: solution_type,
      }).then(
        (response) => {
          this.resource = {
            cpu: response.data.cpu,
            ram: response.data.ram,
            gpu: response.data.gpu,
            volume: response.data.volume,
          };
        },
        (error) => error
      );
    },
    getResourceCustomMetadataByType(solution_type) {
      this.LOAD_RESOURCEMETADATA_BY_TYPE({
        solutionType: solution_type,
      }).then(
        (response) => {
          this.userDefineVolume = response.data.volume;
        },
        (error) => error
      );
    },
    async getAllCategory(solution_type) {
      try {
        const response = await this.LOAD_ALL_CATEGORY();
        this.allCategories = response.data.categories;
      } catch (error) {
        console.error(error);
      }
    },
    getAllRegion() {
      let cloud_provider = this.cloud_providers[this.activePlan];
      this.LOAD_REGION_BY_CLOUD_PROVIDER({
        cloudProvider: cloud_provider,
      });
    },
    getSolution(solution_id) {
    this.isLoading = true;
    // this.getSolutionFunction(solution_id);
    this.getSolutionByType();
    },
    getSolutionFunction(solution_id) {
      this.LOAD_SOLUTION_BY_ID({
        solution_id: solution_id,
      }).then(
        (response) => {
          this.setSolutionOptions();
          this.isLoading = false;
          if (!this.isUpdate) {
            this.selectedSolutionID = [this.selectedSolution.id];
          }
          this.categoryOptions = this.selectedSolution.categories;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    },
    setSolutionOptions() {
      if (
        this.selectedSolution.links !== undefined &&
        this.selectedSolution.links !== null &&
        this.selectedSolution.links.length !== 0
      ) {
        this.solutionOptions = JSON.parse(JSON.stringify(this.selectedSolution.links));
      } else {
        this.solutionOptions = [];
      }
      let obj = {
        description: this.selectedSolution.description,
        id: this.selectedSolution.id,
        name: this.selectedSolution.name,
      };
      this.solutionOptions.push(obj);
    },
    setEnableSolutionOptions(solution){
      if (solution.links !== undefined &&
        solution.links !== null &&
        solution.links.length !== 0
      ){
        this.solutionOptions = JSON.parse(JSON.stringify(solution.links));
      } else {
        this.solutionOptions = [];
      }
      let obj = {
        description: solution.description,
        id: solution.id,
        name: solution.name,
      };
      this.solutionOptions.push(obj);
    },
    getSolutionByType() {
      this.isLoading = true;
      this.LOAD_ALL_SOLUTION_BY_TYPE({
        type: this.solutionType,
      }).then(
        (response) => {
          const solutions = response.data.solutions;

          this.allSolutions = solutions;
          
          this.solutionOptions = solutions.map((solution) => ({
            id: solution.id,
            name: solution.name,
            description: solution.description,
            color: solution.segment.color,
            segmentID: solution.segment.id,
            segmentName: solution.segment.name,
          }));
          
          this.isLoading = false;
          // if (!this.isUpdate) {
          //   console.log(this.selectedSolution.id);
          //   this.selectedSolutionID = [this.selectedSolution.id];
          // }
          this.categoryOptions = solutions.categories;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    },
    getProjectDetails() {
      this.isLoading = true;
      this.isUpdate = true;
      this.LOAD_PROJECT_BY_ID({
        project_id: this.$route.params.project_id,
      }).then(
        (response) => {
          this.isLoading = false;
          this.solutionType = this.selectedProject.segment.name;
          this.getSolution(this.selectedProject.solution_template.id);
          if (this.selectedProject.solutions.length > 0) {
            this.selectedSolutionID = [];
            this.selectedProject.solutions.forEach((element) => {
              this.selectedSolutionID.push(element.id);
            });
            if (this.PROJECT_SEGMENTS.Maira === this.solutionType){
              this.selectedSolutionID = this.selectedProject.solutions.map(solution => solution.id);
              this.gpt_purpose = this.selectedProject.gpt_purpose ? this.selectedProject.gpt_purpose : "";
              this.gpt_randomness = this.selectedProject.gpt_randomness ? this.selectedProject.gpt_randomness : this.gpt_randomness;
            } else {
              // For other solution types, assign the array as is
              this.selectedSolutionID = this.selectedProject.solutions.map(solution => solution.id);
            }
          } else {
            this.selectedSolutionID = [];
          }
          this.projectName = this.selectedProject.name;
          this.language = this.selectedProject.language;
          // this.category = this.selectedProject.industries;
          this.appCategory = this.selectedProject.industry.id;
          this.monthlyPV = this.selectedProject.monthly_pv;
          this.expectedRPS =
            this.selectedProject.expected_rps === null
              ? ""
              : this.selectedProject.expected_rps;
          this.monthlyUU = this.selectedProject.monthly_uu;
          this.itemCount = this.selectedProject.item_count;
          this.autoScaling = this.selectedProject.auto_scaling;
          this.appRegion = this.selectedProject.region.id;
          this.subscriptionType = this.selectedProject.subscription_type;
        },
        (error) => {
          this.isUpdate = false;
          this.isLoading = false;
        }
      );
    },
    getAllProject() {
      this.isLoading = true;
      this.LOAD_ALL_PROJECT(this.solutionType).then(
        (response) => {
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    },
    get_cloud_provider_region(item) {
      this.selectedRegionName = `${item.timezone} (${item.cloud_provider_region})`;
      return `${item.timezone} (${item.cloud_provider_region})`;
    },
    goBack() {
      history.back();
    },
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        if (n === 1) {
          this.solution_cost = 0;
          this.infra_cost = 0;
          this.total_cost = 0;
          this.getAllCategory();
          this.getSolutionName();
          this.getIndustryName();
          this.getAllRegion();
          if (!this.$refs[`form1`][0].validate()) return;
          this.$vuetify.goTo(0);
        }
        // if (n === 2) {
        //   if (!this.$refs[`form2`][0].validate()) return;
        //   this.$vuetify.goTo(0);
        //   this.getProjectCost();
        // }
        this.e1 = n + 1;
      }
    },
    previousStep(n) {
      this.e1 = n - 1;
    },
    saveCategory() {
      if (!this.$refs.categoryForm.validate()) return;
      if (this.categoryName && this.categoryDescription) {
        this.isCreatingInd = true;
        this.SAVE_CATEGORY({
          name: this.categoryName,
          // display_name: this.categoryDisplayName,
          description: this.categoryDescription,
        }).then(
          (response) => {
            this.getAllCategory();
            this.appCategory = response.data.id;
            this.category = response.data;
            this.isCreatingInd = false;
            this.createCategoryDialog = false;
          },
          (error) => {
            this.alertMessage = error.response.data.message;
            this.alertDialog = true;
            this.isCreatingInd = false;
          }
        );
      }
    },
    saveSubscription(organization_id, project_id, subscription_fee) {
      this.SAVE_SUBSCRIPTION({
        organization_id: organization_id,
        project_id: project_id,
        subscription_fee: subscription_fee,
      }).then(
        (response) => {},
        (error) => error
      );
    },
    updateSubscription(project_id, subscription_fee) {
      this.UPDATE_SUBSCRIPTION_FEE({
        project_id: project_id,
        subscription_fee: subscription_fee,
      }).then(
        (response) => {},
        (error) => error
      );
    },
    saveProject() {
      let selectedGPTSolution = []; 
      if (this.PROJECT_SEGMENTS.Maira === this.solutionType){
        if (typeof this.selectedSolutionID == 'object') {
          selectedGPTSolution = this.selectedSolutionID
        } else {
          selectedGPTSolution.push(this.selectedSolutionID);
        }
      }

      if (
        this.projectName &&
        this.selectedSolutionID &&
        this.appCategory ||
        this.monthlyPV ||
        this.monthlyUU ||
        this.itemCount
      ) {
        if (this.$route.params.project_id) {
          this.isUpdate = true;
          this.isCreatingProject = true;
          this.UPDATE_PROJECT({
            project_id: this.$route.params.project_id,
            name: this.projectName,
            solutionIDList: this.PROJECT_SEGMENTS.Maira === this.solutionType ? selectedGPTSolution : this.selectedSolutionID,
            // industryID: this.appCategory,
            monthlyPV: this.monthlyPV === "" ? 0 : Number(this.monthlyPV),
            expectedRPS: this.expectedRPS === "" ? 0 : Number(this.expectedRPS),
            monthlyUU: this.monthlyUU === "" ? 0 : Number(this.monthlyUU),
            itemCount: this.itemCount === "" ? 0 : Number(this.itemCount),
            autoScaling: this.autoScaling,
            // regionID: this.appRegion,
            subscriptionType: this.subscriptionType,
            gpt_purpose: this.gpt_purpose,
            gpt_randomness: this.gpt_randomness
          }).then(
            (response) => {
              this.$store.dispatch(
                `alert/${ADD_ALERT}`,
                {
                  message: this.$vuetify.lang.t("$vuetify.project.update_project_success_msg_txt"),
                  color: "success",
                },
                { root: true }
              );
              this.isCreatingProject = false;
              this.getAllProject();
              // this.$router.push({ path: '/solutions', query: { type: this.project_id }});
              this.$router.push({ path: `/projects/${response.data.id}`});
              this.isUpdate = false;
            },
            (error) => {
              this.alertMessage = error.message;
              this.alertDialog = true;
              this.isUpdate = false;
              this.isCreatingProject = false;
            }
          );
        } else {
          this.isUpdate = false;
          this.isCreatingProject = true;
          this.SAVE_PROJECT({
            name: this.projectName,
            solutionIDList: this.PROJECT_SEGMENTS.Maira === this.solutionType ? selectedGPTSolution : this.selectedSolutionID,
            language: this.language ? this.language : "English", 
            segmentID: this.selectedSolutionTypeID,
            industryID: this.appCategory,
            solutionTemplateID: this.solutionType === this.PROJECT_SEGMENTS.Maira ? selectedGPTSolution[0] : this.selectedSolutionID[0],
            monthlyPV: this.monthlyPV === '' ? (this.selectedSolution.max_pv ? this.selectedSolution.max_pv : 1) : Number(this.monthlyPV),
            expectedRPS: this.expectedRPS === "" ? 0 : Number(this.expectedRPS),
            monthlyUU: this.monthlyUU === "" ? 0 : Number(this.monthlyUU),
            itemCount: this.itemCount === "" ? 0 : Number(this.itemCount),
            autoScaling: this.autoScaling,
            // regionID: this.appRegion,
            gpt_purpose: this.gpt_purpose,
            gpt_randomness: this.gpt_randomness
          }).then(
            (response) => {
              this.$store.dispatch(
                `alert/${ADD_ALERT}`,
                {
                  message: this.$vuetify.lang.t("$vuetify.project.create_project_success_msg_txt"),
                  color: "success",
                },
                { root: true }
              );
              this.isCreatingProject = false;
              this.getAllProject();
               // this.$router.push({ path: '/solutions', query: { type: this.project_id }});
              this.$router.push({ path: `/projects/${response.data.id}`});
            },
            (error) => {
              this.alertMessage = error.message;
              this.alertDialog = true;
              this.isCreatingProject = false;
            }
          );
        }
      } else {
        this.alertMessage = this.$vuetify.lang.t("$vuetify.common.req_field_error_msg_txt");
        this.alertDialog = true;
      }
    },
    getAccount() {
      this.LOAD_ACCOUNT()
        .then((res) => {
          let data = res.data;
          let addressObj = res.data.addresses.length > 0 ? res.data.addresses[0] : {};
          this.organization = {
            account_name: data.account_name,
            name: data.org_name,
            size: data.org_size,
            phone: data.org_phone,
            vat_number: data.org_vat_number,
            registration_number: data.org_registration_number,
            director_name: data.org_director_name,
            website: data.org_website_url,
            industry: data.org_industry_id,
            email: data.org_email,
            country: addressObj.country_code ? addressObj.country_code : "",
            region: addressObj.state_or_province ? addressObj.state_or_province : "",
            city: addressObj.city ? addressObj.city : "",
            postal_code: addressObj.postal_code ? addressObj.postal_code : "",
            address: addressObj.address ? addressObj.address : "",
          };
          this.accountType = data.type;
          this.subscriptionType = data.trial_allowed ===true ? "trial" : "paid";
        })
        .catch((err) => err);
    },
  },
};
</script>