<template>
  <div>
    <v-card flat class="pa-4 mt-2">
      <ValidationObserver ref="accountStep">
        <v-form>
          <v-row>
            <v-col md="6" cols="12">
              <ValidationProvider
                name="Account Name"
                rules="required|is_numeric"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="organization.account_name"
                  :label="$vuetify.lang.t('$vuetify.account.account_name_label_txt')"
                  outlined
                  :error-messages="
                    localizeErrorMsg(errors, 'account_name', organization.account_name)
                  "
                  disabled
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <template v-if="accountType !== 'organization'">
              <v-col md="6" cols="12">
                <ValidationProvider
                  name="Organization Name"
                  rules="is_numeric"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="organization.name"
                    :label="$vuetify.lang.t('$vuetify.account.org_name_label_txt')"
                    outlined
                    :error-messages="
                      localizeErrorMsg(errors, 'org_name', organization.name)
                    "
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col md="6" cols="12">
                <ValidationProvider
                  name="Organization VAT Number"
                  rules=""
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="organization.vat_number"
                    :label="$vuetify.lang.t('$vuetify.account.vat_no_label_txt')"
                    :error-messages="errors"
                    outlined
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col md="6" cols="12">
                <ValidationProvider
                  name="Organization Registration Number"
                  rules=""
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="organization.registration_number"
                    :label="$vuetify.lang.t('$vuetify.account.reg_no_label_txt')"
                    :error-messages="errors"
                    outlined
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col md="6" cols="12">
                <ValidationProvider
                  name="Registered Managing Director/CEO"
                  rules="is_numeric"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="organization.director_name"
                    :label="$vuetify.lang.t('$vuetify.account.ceo_label_txt')"
                    :error-messages="
                      localizeErrorMsg(errors, 'ceo', organization.director_name)
                    "
                    outlined
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col md="6" cols="12">
                <ValidationProvider
                  name="Organization Email"
                  rules=""
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="organization.email"
                    :label="$vuetify.lang.t('$vuetify.account.org_email_label_txt')"
                    :error-messages="errors"
                    outlined
                    readonly
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col md="6" cols="12">
                <ValidationProvider
                  name="Organization Phone"
                  rules="phone_num"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="organization.phone"
                    :label="$vuetify.lang.t('$vuetify.account.org_phone_label_txt')"
                    outlined
                    :error-messages="
                      localizeErrorMsg(errors, 'org_phone', organization.phone)
                    "
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col md="6" cols="12">
                <ValidationProvider
                  name="Organization Website"
                  rules=""
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="organization.website"
                    :label="$vuetify.lang.t('$vuetify.account.org_website_label_txt')"
                    :error-messages="
                      localizeErrorMsg(errors, 'org_website', organization.website)
                    "
                    outlined
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col md="6" cols="12">
                <ValidationProvider
                  name="Organization Size"
                  rules=""
                  v-slot="{ errors }"
                >
                  <form autocomplete="off" @submit.prevent>
                    <v-autocomplete
                      :items="oraganizationSizeParams"
                      item-text="text"
                      item-value="value"
                      v-model="organization.size"
                      :label="$vuetify.lang.t('$vuetify.account.org_size_label_txt')"
                      outlined
                      :error-messages="localizeErrorMsg(errors, 'org_size')"
                    ></v-autocomplete>
                  </form>
                </ValidationProvider>
              </v-col>

              <v-col md="6" cols="12">
                <ValidationProvider
                  name="Organization Industry"
                  rules=""
                  v-slot="{ errors }"
                >
                  <form autocomplete="off" @submit.prevent>
                    <v-autocomplete
                      :label="$vuetify.lang.t('$vuetify.account.org_industry_label_txt')"
                      :items="allIndustry"
                      v-model="organization.industry"
                      item-text="name"
                      item-value="id"
                      :error-messages="errors"
                      outlined
                    ></v-autocomplete>
                  </form>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" md="6">
                <ValidationProvider name="Country" rules="" v-slot="{ errors }">
                  <form autocomplete="off" @submit.prevent>
                    <v-autocomplete
                      :label="$vuetify.lang.t('$vuetify.profile.country_label_txt')"
                      v-model="organization.country"
                      :items="AllCountry"
                      item-text="countryName"
                      item-value="countryCode"
                      :error-messages="localizeErrorMsg(errors, 'country')"
                      outlined
                    ></v-autocomplete>
                  </form>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" md="6">
                <ValidationProvider name="State" rules="" v-slot="{ errors }">
                  <form autocomplete="off" @submit.prevent>
                    <v-autocomplete
                      :label="$vuetify.lang.t('$vuetify.profile.state_label_txt')"
                      :items="
                        AllCountry.filter(
                          (obj) => obj.countryCode === organization.country
                        )[0] &&
                        AllCountry.filter(
                          (obj) => obj.countryCode === organization.country
                        )[0].regions
                      "
                      item-text="name"
                      item-value="name"
                      v-model="organization.region"
                      :error-messages="localizeErrorMsg(errors, 'state')"
                      outlined
                    ></v-autocomplete>
                  </form>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" md="6">
                <ValidationProvider name="City" rules="is_numeric" v-slot="{ errors }">
                  <v-text-field
                    v-model="organization.city"
                    :label="$vuetify.lang.t('$vuetify.profile.city_label_txt')"
                    :error-messages="localizeErrorMsg(errors, 'city', organization.city)"
                    outlined
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" md="6">
                <ValidationProvider
                  name="Postal Code"
                  mode="eager"
                  rules="postal_code:@Country"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="organization.postal_code"
                    :error-messages="
                      localizeErrorMsg(errors, 'post_code', organization.postal_code)
                    "
                    :label="$vuetify.lang.t('$vuetify.profile.post_code_label_txt')"
                    outlined
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" md="6">
                <ValidationProvider
                  name="Address"
                  rules="is_numeric"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="organization.address"
                    :error-messages="
                      localizeErrorMsg(errors, 'address', organization.address)
                    "
                    :label="$vuetify.lang.t('$vuetify.profile.address_label_txt')"
                    outlined
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </template>
          </v-row>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              large
              class="mr-2"
              type="reset"
              @click.prevent="resetForm"
            >
              {{ $vuetify.lang.t("$vuetify.common.cancel_btn") }}
            </v-btn>
            <v-btn
              color="primary"
              large
              @click="updateAccount"
              :loading="isUpdatingAccount"
            >
              {{ $vuetify.lang.t("$vuetify.common.save_btn") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </ValidationObserver>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import "@/helpers/vee-validate";
import { AllCountry } from "vuetify-country-search";
import {
  LOAD_ALL_CATEGORY,
  ADD_ALERT,
  LOAD_ORGANIZATION,
  LOAD_ACCOUNT,
  EDIT_ACCOUNT_SETTINGS,
} from "@/store/_actiontypes";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    oraganizationSizeParams: {
      type: Array,
      default() {
        return [
          {
            text: this.$vuetify.lang.t("$vuetify.account.org_size_opt_1_txt"),
            value: "0 - 9",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.account.org_size_opt_2_txt"),
            value: "10 - 49",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.account.org_size_opt_3_txt"),
            value: "50 - 149",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.account.org_size_opt_4_txt"),
            value: "150 - 249",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.account.org_size_opt_5_txt"),
            value: "250 - 999",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.account.org_size_opt_6_txt"),
            value: "1000+",
          },
        ];
      },
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
  },
  created() {
    this.getAll();
  },
  data() {
    return {
      AllCountry,
      allIndustry: [],
      accountType: "",
      organization: {
        account_name: "",
        name: "",
        size: "",
        phone: "",
        vat_number: "",
        registration_number: "",
        country: "",
        region: "",
        city: "",
        postal_code: "",
        director_name: "",
        website: "",
        industry: "",
        email: "",
        logo: "logo will coming soon",
        address: "",
      },
      isUpdatingAccount: false,
    };
  },
  watch: {},
  methods: {
    ...mapActions("category", [LOAD_ALL_CATEGORY]),
    ...mapActions("account", [
      LOAD_ORGANIZATION,
      LOAD_ACCOUNT,
      EDIT_ACCOUNT_SETTINGS,
    ]),
    localizeErrorMsg() {
      if (arguments[0] && arguments[0].length && arguments[1] && !arguments[2]) {
        return this.$vuetify.lang.t(`$vuetify.account.${arguments[1]}_error_msg_txt`);
      }
      if (arguments[0] && arguments[0].length && arguments[1] && arguments[2]) {
        return this.$vuetify.lang.t(`$vuetify.account.${arguments[1]}_error_msg_txt_2`);
      }
    },
    getAll() {
      this.getAllIndustry();
      this.getAccount();
    },
    getAllIndustry() {
      this.LOAD_ALL_CATEGORY().then((res) => {
        this.allIndustry = res.data.categories;
      });
    },
    getAccount() {
      this.LOAD_ACCOUNT()
        .then((res) => {
          let data = res.data;
          let addressObj = res.data.addresses.length > 0 ? res.data.addresses[0] : {};
          this.organization = {
            account_name: data.account_name,
            name: data.org_name,
            size: data.org_size,
            phone: data.org_phone,
            vat_number: data.org_vat_number,
            registration_number: data.org_registration_number,
            director_name: data.org_director_name,
            website: data.org_website_url,
            industry: data.org_industry_id,
            email: data.org_email,
            country: addressObj.country_code ? addressObj.country_code : "",
            region: addressObj.state_or_province ? addressObj.state_or_province : "",
            city: addressObj.city ? addressObj.city : "",
            postal_code: addressObj.postal_code ? addressObj.postal_code : "",
            address: addressObj.address ? addressObj.address : "",
          };
          this.accountType = data.type;
        })
        .catch((err) => err);
    },
    updateAccount() {
      this.$refs.accountStep.validate().then((success) => {
        if (!success) {
          return;
        }
        let {
          account_name,
          name,
          size,
          phone,
          vat_number,
          registration_number,
          director_name,
          website,
          industry,
          email,
          country,
          region,
          city,
          postal_code,
          address,
        } = this.organization;
        let userData = {
          account_name: account_name,
          type: this.accountType,
          org_name: name,
          org_size: size,
          org_email: email,
          org_phone: phone,
          ...(industry ? { org_industry_id: industry } : {}),
          org_website_url: website,
          org_vat_number: vat_number,
          org_registration_number: registration_number,
          org_director_name: director_name,
          org_address: {
            country_code: country,
            state_or_province: region,
            city: city,
            postal_code: postal_code,
            address: address,
          },
        };
        this.isUpdatingAccount = true;
        this.EDIT_ACCOUNT_SETTINGS({ userData })
          .then((res) => {
            this.$store.dispatch(
              `alert/${ADD_ALERT}`,
              {
                message: this.$vuetify.lang.t("$vuetify.account.acc_update_msg_txt"),
                color: "success",
              },
              { root: true }
            );
            this.isUpdatingAccount = false;
            this.getAccount();
          })
          .catch((err) => {
            this.isUpdatingAccount = false;
          });
      });
    },
    resetForm() {},
  },
};
</script>
