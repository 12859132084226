<template>
  <div>
    <div>
      <Loader v-if="trainLoader && updateLoader && initialSetupDone" class="mr-2 mt-6" />
      <template v-else-if="initialSetupDone">

        <v-card class="elevation-0">
          <v-card-title class="font-weight-bold">{{ `${$vuetify.lang.t("$vuetify.project_settings.train_data")}` }}</v-card-title>
          <v-divider></v-divider>
          <v-row>
            <v-col class="mx-2">
              <template>
                <ItemsTrain @updateComplete="handleTrainHistoryTableComplete"/>
                
                <div v-if="mapper.detail">
                  <v-divider></v-divider>
                    <ImagesTrain :mapper="mapper" :taskHistoryData="taskHistoryData" @updateComplete="handleTrainHistoryTableComplete"/>
                </div>

                <v-divider></v-divider>
                <RanksTrain @updateComplete="handleTrainHistoryTableComplete"/>
              </template>
            </v-col>
          </v-row>
        </v-card>

        <v-card class="elevation-0">
          <v-card-title class="text-h6 font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.project_settings.train_history")}` }}
          </v-card-title>
          <v-divider></v-divider>
          <v-row class="mx-2 mb-3">
            <v-col cols="12" md="6">
            </v-col>
          </v-row>
          <div>
            <Loader v-if="isTrainHistoryLoading" class="mr-2 mt-6" />
            <template v-else>
              <v-col xs="12" md="4">
                <v-select
                  v-model="filterTrainHistoryType"
                  :items="filterTrainHistoryOptions"
                  :label="$vuetify.lang.t('$vuetify.project_settings.train_type')"
                  item-text="name"
                  item-value="value"
                  @change="onFilterTrainTypeChange"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-data-table
                :headers="trainHistoryHeaders"
                :items="taskHistoryData"
                :sort-by="['created_at']"
                :sort-desc="[true]"
                :server-items-length="totalNumberOfTrains"
                :footer-props="{'items-per-page-options': [5, 10, 15, 50, 100]}"
                :options.sync="paginateTrainHistoryObj"
                @update:options="paginateTrainHistory"
              >
                
                <template v-slot:item.name="{ item }">
                  {{ item.name ? capitalizeFirstLetter(item.name) : "N/A" }}
                </template>

                <template v-slot:item.started_at="{ item }">
                  {{ item.started_at ? formatDateTime(item.started_at) : "N/A" }}
                </template>

                <template v-slot:item.last_updated_at="{ item }">
                  {{ item.last_updated_at ? formatDateTime(item.last_updated_at) : "N/A" }}
                </template>

                <template v-slot:item.message="{ item }">
                  {{ item.message ? item.message : "N/A" }}
                </template>

                <template v-slot:item.scheduled_by="{ item }">
                  {{ item.scheduled_by ? item.scheduled_by : "N/A" }}
                </template>

                <template v-slot:item.status="{ item }">
                  {{ item.status ? item.status : "N/A" }}
                </template>
              </v-data-table>
            </template>
          </div>
        </v-card>

        <!-- <template>
          <v-expansion-panels :value="0">
              <v-expansion-panel>
                  <v-expansion-panel-header>
                      <div class="text-h6 font-weight-bold ml-n2">{{ `${$vuetify.lang.t("$vuetify.project_settings.train_data")}` }}</div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                      <ItemsTrain @updateComplete="handleTrainHistoryTableComplete"/>
                    <v-divider></v-divider>
                      <ImagesTrain :mapper="mapper" @updateComplete="handleTrainHistoryTableComplete"/>
                    <v-divider></v-divider>
                      <RanksTrain @updateComplete="handleTrainHistoryTableComplete"/>
                  </v-expansion-panel-content>
              </v-expansion-panel>
          </v-expansion-panels>
        </template> -->

        <template>
          <UpdateRankSettings :rankSettings="rankSettings" @updateComplete="handleSettingsUpdate"/>
          <UpdateMapper :mapper="mapper" @updateComplete="handleMapperUpdateComplete" />
        </template>

        
      </template>
      <template v-else-if="selectedProject.segment.name === PROJECT_SEGMENTS.PERSONALIZER">
        <div class="mt-8">
                <v-alert type="info" text border="left"> {{ `${$vuetify.lang.t("$vuetify.project_settings.settings_initial_setup_info")}` }} </v-alert>
        </div>
      </template>
      <v-card class="elevation-0">
        <v-card-title class="text-h6 font-weight-bold">
          {{ `${$vuetify.lang.t("$vuetify.project_credentials.credentials_label_txt")}` }}
        </v-card-title>
        <v-divider></v-divider>
        <v-row class="mx-2 mb-3">
          <v-col cols="12" md="6">
            <v-card class="pa-2" flat>
              <strong>{{
                `${$vuetify.lang.t("$vuetify.project_credentials.all_credentials_label_txt")}:`
              }}</strong>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-row class="justify-end mt-1">
              <Button
                v-if="!disable_crud_operation"
                :buttonType="FLOATING_ACTION_BUTTON"
                iconName="mdi-plus"
                size="x-small"
                :buttonText="$vuetify.lang.t('$vuetify.common.create')"
                color="primary"
                @onClick="createNewClick()"
              />
            </v-row>
          </v-col>
        </v-row>
        <div>
          <Loader v-if="isLoading" class="mr-2 mt-6" />
          <v-data-table
            v-else
            :headers="credentialHeaders"
            :items="credentials"
            :sort-by="['created_at']"
            :sort-desc="[true]"
          >
            <template v-slot:item.id="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ item.id.split("-")[0] }}</span>
                </template>
                <span>{{ item.id }}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.name="{ item }">
              {{ item.name ? item.name : "N/A" }}
            </template>
            <template v-slot:item.status="{ item }">
              {{ item.status ? capitalizeFirstLetter(item.status) : "N/A" }}
            </template>
            <template v-slot:item.security="{ item }">
              <ChatHeadTooltip
              :toolTipText = "getSecurityLevelsDescriptions(item.security?.level)"
              :chipText = "item.security?.level"
              :chipColor = "item.security?.color"
              :textColor = "item.security?.textColor"
              />
            </template>
            <template v-slot:item.created_at="{ item }">
              {{ item.created_at ? formatDateTime(item.created_at) : "N/A" }}
            </template>
            <template v-slot:item.actions="{ item }">
              <ButtonGroup
                :groupType="ACTION_BUTTON_GROUP"
                :detailsButton="false"
                :addButton="false"
                :deleteButton="false"
                :startButton="disable_crud_operation || item.status !== 'active'"
                :disabledStartButton="
                  disable_crud_operation || (item.status !== 'active' && selectedProject.status !== 'running')
                "
                :disabledEditButton="disable_crud_operation || selectedProject.status !== 'running'"
                :disabledStopButton="disable_crud_operation || selectedProject.status !== 'running'"
                :stopButton="disable_crud_operation || item.status === 'active'"
                @onEditClick="editItem(item)"
                @onStartClick="startItem(item)"
                @onStopClick="stopItem(item)"
              />
            </template>
          </v-data-table>
        </div>
      </v-card>
      <template v-if="selectedProject.segment.name === PROJECT_SEGMENTS.PERSONALIZER || selectedProject.segment.name === PROJECT_SEGMENTS.Maira">
        <v-card class="elevation-0" v-if="selectedProject.solution_template.sandbox_location">
          <v-card-title class="font-weight-bold">
            {{ $vuetify.lang.t("$vuetify.common.sandbox_btn") }}
          </v-card-title>
          <v-divider></v-divider>
          <v-row>
            <v-col class="mx-2">
              <template>
                <div>
                  <v-btn
                    class="ma-2"
                    outlined
                    color="primary"
                    :href="selectedProject.solution_template.sandbox_location"
                    target="_blank"
                  >
                    {{ $vuetify.lang.t("$vuetify.common.sandbox_btn") }}
                  </v-btn>
                </div>
              </template>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="elevation-0" v-if="selectedProject.solution_template.documentation_location">
          <v-card-title class="font-weight-bold">{{
            $vuetify.lang.t("$vuetify.common.documentation_btn")
          }}</v-card-title>
          <v-divider></v-divider>
          <v-row>
            <v-col class="mx-2">
              <template>
                <div>
                  <v-btn
                    class="ma-2"
                    outlined
                    color="primary"
                    :href="selectedProject.solution_template.documentation_location"
                    target="_blank"
                  >
                    {{ $vuetify.lang.t("$vuetify.common.documentation_btn") }}
                  </v-btn>
                </div>
              </template>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="elevation-0" v-if="selectedProject.solution_template.tutorial_location">
          <v-card-title class="font-weight-bold">{{
            $vuetify.lang.t("$vuetify.common.tutorial_btn")
          }}</v-card-title>
          <v-divider></v-divider>
          <v-row>
            <v-col class="mx-2">
              <template>
                <div>
                  <v-btn
                    class="ma-2"
                    outlined
                    color="primary"
                    :href="selectedProject.solution_template.tutorial_location"
                    target="_blank"
                  >
                    {{ $vuetify.lang.t("$vuetify.common.tutorial_btn") }}
                  </v-btn>
                </div>
              </template>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </div>
    <v-form ref="form" v-model="valid">
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <div class="px-3 py-3 primary--text font-weight-bold">
            {{
              edit
                ? $vuetify.lang.t("$vuetify.project_credentials.edit_credential_label_txt")
                : $vuetify.lang.t("$vuetify.project_credentials.add_credential_label_txt")
            }}
          </div>
          <v-divider></v-divider>
          <v-layout class="px-5 py-5">
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  :inputType="TEXT"
                  v-model="credentialName"
                  :label="$vuetify.lang.t('$vuetify.project_credentials.credentials_name_header_txt')"
                  :rules="[
                    (v) => !!v || $vuetify.lang.t('$vuetify.project_credentials.credentials_error_msg_txt'),
                  ]"
                  outlined
                  dense
                ></v-text-field>

                <template>
                  <div>
                    <v-select
                      :label="$vuetify.lang.t('$vuetify.project_credentials.key_type_selector_txt')"
                      :items="credentialTypeOptions"
                      :disabled="edit"
                      variant="solo-filled"
                      v-model="credentialType"
                      :key="dialog"
                      outlined
                      dense
                    >
                      <template v-slot:append>
                        <v-tooltip top v-if="!edit">
                          <template v-slot:activator="{ on }">
                            <v-avatar v-on="on">
                              <v-icon>mdi-information</v-icon>
                            </v-avatar>
                          </template>
                          <span v-if="credentialType === 'project'">{{ $vuetify.lang.t('$vuetify.project_credentials.project_api_key_tooltip_txt') }}</span>
                          <span v-else-if="credentialType === 'client'">{{ $vuetify.lang.t('$vuetify.project_credentials.client_key_tooltip_txt') }}</span>
                        </v-tooltip>
                        <v-icon>mdi-chevron-down</v-icon> 
                      </template>
                    </v-select>
                  </div>
                </template>

                <template>
                  <div>
                    <v-combobox
                      v-model="whitelistedDomainShow"
                      :label="$vuetify.lang.t('$vuetify.project_credentials.whitelisted_domain_label_txt')"
                      :placeholder="$vuetify.lang.t('$vuetify.project_credentials.whitelisted_domain_placeholder_txt')"
                      multiple
                      small-chips
                      outlined
                      append-icon=""
                      deletable-chips
                      clearable
                      height="90"
                      @keydown.space.prevent
                      :rules="[validateWhitelistedDomain]"
                    >
                  </v-combobox>
                  </div>
                </template>

                <v-alert v-if='whitelistedDomainShow.length === 0'
                  outlined
                  dense
                  prominent
                  text
                  border="left"
                  type="warning"
                >
                <span v-html="$vuetify.lang.t('$vuetify.project_credentials.whitelisted_domain_security_alert_txt')"></span>
                </v-alert>
              </v-col>
            </v-row>
          </v-layout>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <Button
              class="red--text font-weight-bold"
              :buttonType="TEXT_BUTTON"
              :buttonText="$vuetify.lang.t('$vuetify.common.cancel_btn')"
              color="red"
              @onClick="dialog = false"
            />
            <Button
              class="font-weight-bold"
              :buttonType="RAISED_BUTTON"
              :buttonText="$vuetify.lang.t('$vuetify.common.save_btn')"
              :disabled="!valid"
              :color="valid ? 'primary' : '#90A4AE'"
              @onClick="saveCredentialClick()"
              :loading="isLoading"
            />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <v-dialog v-model="successDialog" persistent width="600">
      <v-card>
        <v-card-title class="headline white--text text--primary mb-4">
          {{ $vuetify.lang.t("$vuetify.project_credentials.credentials_label_txt") }}
        </v-card-title>
        <v-card-text>
          <div class="mb-8">
            <v-alert type="info" outlined>
              {{ $vuetify.lang.t("$vuetify.project_credentials.credentials_download_json_msg_txt") }}
            </v-alert>
          </div>
          <!-- key copy box starts here-->
          <v-text-field v-if="credentialType === 'client'" 
            v-model="client_key"
            :label="this.$vuetify.lang.t('$vuetify.project_credentials.client_key_label_txt')"
            outlined
          >
            <template v-slot:append>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" @click="copyClientKey()">mdi-content-copy</v-icon>
                </template>
                {{
                  client_key_copy
                    ? $vuetify.lang.t('$vuetify.common.copied_msg_txt')
                    : $vuetify.lang.t('$vuetify.common.copy_msg_txt')
                }}
              </v-tooltip>
            </template>
          </v-text-field>

          <div v-else>
            <v-text-field v-model="project_key" :label="$vuetify.lang.t('$vuetify.project_credentials.project_key_label_txt')" outlined>
              <template v-slot:append>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="copyProjectKey()"> mdi-content-copy </v-icon>
                  </template>
                  {{
                    project_key_copy
                      ? $vuetify.lang.t("$vuetify.common.copied_msg_txt")
                      : $vuetify.lang.t("$vuetify.common.copy_msg_txt")
                  }}
                </v-tooltip>
              </template>
            </v-text-field>
            <v-text-field v-model="api_key" :label="$vuetify.lang.t('$vuetify.project_credentials.api_key_label_txt')" outlined>
              <template v-slot:append>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="copyApiKey()"> mdi-content-copy </v-icon>
                  </template>
                  {{
                    api_key_copy
                      ? $vuetify.lang.t("$vuetify.common.copied_msg_txt")
                      : $vuetify.lang.t("$vuetify.common.copy_msg_txt")
                  }}
                </v-tooltip>
              </template>
            </v-text-field>
          </div>
          <!-- key copy box ends here -->
          <v-btn color="primary" @click="downloadCredential()">
            <v-icon>mdi-download</v-icon>
            {{ $vuetify.lang.t("$vuetify.project_credentials.download_json_msg_txt") }}
          </v-btn>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <Button
            :buttonType="TEXT_BUTTON"
            :buttonText="$vuetify.lang.t('$vuetify.common.close_btn')"
            color="primary"
            @onClick="successDialog = false"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

import store from "@/store";

import {
  ADD_ALERT,
  LOAD_ALL_CREDENTIAL,
  SAVE_CREDENTIAL,
  UPDATE_CREDENTIAL,
  GET_MAPPER,
  GET_RANK_SETTINGS,
  LOAD_TRAIN_TASKS,
  GET_PREVIOUSLY_SETUP_STATE
} from "@/store/_actiontypes";

import { TEXT } from "@/components/common/input/_inputTypes";

import {
  FLOATING_ACTION_BUTTON,
  TEXT_BUTTON,
  RAISED_BUTTON,
} from "@/components/common/button/_buttontypes";
import ItemsTrain from "@/components/Projects/Train/ItemsTrain";
import ImagesTrain from "@/components/Projects/Train/ImagesTrain";
import RanksTrain from "@/components/Projects/Train/RanksTrain";
import UpdateRankSettings from "@/components/Projects/UpdateSettings/UpdateRankSettings";
import UpdateMapper from "@/components/Projects/UpdateSettings/UpdateMapper";

import { ACTION_BUTTON_GROUP } from "@/components/common/buttongroup/_buttongrouptypes";

import { capitalizeFirstLetter, formatDateTime, authorize } from "@/helpers/helper";
import { Role } from "@/helpers/role";
import {
  SEGMENTS
} from '@/global/_constants';
import Loader from "@/components/common/loader/Loader";
import Button from "@/components/common/button/Button";
import ButtonGroup from "@/components/common/buttongroup/ButtonGroup";
import InputControl from "@/components/common/input/InputControl";

import ChatHeadTooltip from "@/components/common/tooltip/ChatHeadTooltip.vue";

export default {
  props: {},
  components: {
    Loader,
    Button,
    ButtonGroup,
    InputControl,
    ItemsTrain,
    ImagesTrain,
    RanksTrain,
    UpdateRankSettings,
    UpdateMapper,
    ChatHeadTooltip,
  },
  data() {
    return {
      disable_crud_operation: false,
      capitalizeFirstLetter,
      formatDateTime,
      TEXT: TEXT,
      FLOATING_ACTION_BUTTON: FLOATING_ACTION_BUTTON,
      TEXT_BUTTON: TEXT_BUTTON,
      RAISED_BUTTON: RAISED_BUTTON,
      ACTION_BUTTON_GROUP: ACTION_BUTTON_GROUP,
      isLoading: false,
      dialog: false,
      successDialog: false,
      downloadMessage: "",
      edit: false,
      valid: true,
      PROJECT_SEGMENTS: SEGMENTS,
      credentialName: "",
      credentialType: "project",
      whitelistedDomain: {},
      whitelistedDomainShow: [],
      isValidWhitelistedDomain: true,
      credentialResponse: null,
      api_key: "",
      project_key: "",
      client_key: "",
      api_key_copy: false,
      project_key_copy: false,
      client_key_copy: false,
      // TODO: Need to build text handling from a string.json file with different locale support
      alertText: {
        createNotAllow: this.$vuetify.lang.t("$vuetify.project_settings.create_restricted_msg_txt"),
        projectLocked: this.$vuetify.lang.t("$vuetify.project_settings.project_locked_msg_txt"),
      },
      credentialHeaders: [
        {
          text: this.$vuetify.lang.t("$vuetify.table_header.id_header_txt"),
          align: "center",
          sortable: true,
          value: "id",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.table_header.credentials_name_header_txt"),
          align: "center",
          sortable: true,
          value: "name",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.project_credentials.key_type_header_txt"), 
          align: "center",
          sortable: true,
          value: "key_type",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.table_header.status"),
          align: "center",
          sortable: true,
          value: "status",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.project_credentials.label_header_txt"),
          align: "center",
          sortable: true,
          value: "security",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.table_header.created_at"),
          align: "center",
          sortable: true,
          value: "created_at",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.table_header.actions"),
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      mapper: {},
      rankSettings: {},
      trainLoader: false,
      updateLoader: false,
      isTrainHistoryLoading: false,
      trainHistoryHeaders: [
        {
          text: this.$vuetify.lang.t("$vuetify.table_header.name_header_txt"),
          align: "center",
          sortable: false,
          value: "name",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.job.started_at"),
          align: "center",
          sortable: false,
          value: "started_at",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.job.finished_at"),
          align: "center",
          sortable: false,
          value: "last_updated_at",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.table_header.status"),
          align: "center",
          sortable: false,
          value: "status",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.project.message"),
          align: "center",
          sortable: false,
          value: "message",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.project.generated_by"),
          align: "center",
          sortable: false,
          value: "scheduled_by",
        },
      ],
      totalNumberOfTrains: 0,
      defaultPagination: { 
        "page": 1, 
        "itemsPerPage": 5, 
        "sortBy": [ "created_at" ], 
        "sortDesc": [ true ], 
        "groupBy": [], 
        "groupDesc": [], 
        "mustSort": false, 
        "multiSort": false, 
        "descending": true, 
        "totalItems": 0 
      },
      paginateTrainHistoryObj: {
        "descending": true,
        "page": 1,
        "itemsPerPage": 5, // -1 for All",
        "totalItems": 0
      },
      filterTrainHistoryType: "all_tasks",
      filterTrainHistoryOptions: [
        { name: this.$vuetify.lang.t("$vuetify.training.all"), value: "all_tasks" },
        { name: this.$vuetify.lang.t("$vuetify.training.items_train"), value: "item_train" },
        { name: this.$vuetify.lang.t("$vuetify.training.ranks_train"), value: "rank_train" },
        { name: this.$vuetify.lang.t("$vuetify.training.images_train"), value: "image_train" },
        { name: this.$vuetify.lang.t("$vuetify.training.insight_process"), value: "insight_process" },
      ],
      taskHistoryData: [],
      updateFlag: false
    };
  },
  watch: {
    whitelistedDomainShow: {
      handler: function() {
        if (this.whitelistedDomainShow != null) {
          this.validateWhitelistedDomain();
        } else {
          console.warn('whitelistedDomainShow is null or undefined');
        }
      },
      deep: true,
    },
    rankSettings: {
      handler(newRankSettings) {
        // Check to ensure newRankSettings is not null or undefined
        if (newRankSettings != null) {
          // Perform your logic that manipulates newRankSettings
          // console.log('Rank settings updated:', newRankSettings);
        } else {
          console.warn('rankSettings is null or undefined');
        }
      },
      immediate: true,
    }
  },
  computed: {
    ...mapState({
      selectedProject: (state) => state.project.selectedProject,
      trainTasks: (state) => state.project.trainTasks
    }),
    ...mapGetters("loader", ["loading"]),
    ...mapGetters("project", ["credentials"]),
    credentialTypeOptions() {
      return [
        { text: this.$vuetify.lang.t("$vuetify.project_credentials.credential_type.project_api_key_txt"), value: 'project' }, 
        { text: this.$vuetify.lang.t("$vuetify.project_credentials.credential_type.client_key_txt"), value: 'client' }
      ];
    },
    validateWhitelistedDomain() {
      return (v) => {
        let domains = Array.isArray(this.whitelistedDomainShow) ? this.whitelistedDomainShow : [];
        
        if (("project" === this.credentialType || "client" === this.credentialType) && 0 === domains.length){
          return true;
          //return 'Please enter at least one whitelisted domain.'
        }

        // The domain must follow a valid format.
        // For example, "localhost", "example.com", "subdomain.example.com", or "120.0.0.1".
        // Do not include scheme ("https://"), page paths ("example.com/page"), or port numbers ("localhost:8000").
        const domainPattern = /^(?!:\/\/)(?=.{1,255}$)(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,63}|localhost|\b((25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\b)$/;
        const isValid = domains.every((domain) => {
          return domainPattern.test(domain);
        });

        this.isValidWhitelistedDomain = isValid;
        if (!isValid) {
          this.valid = false;
        }

        return isValid || this.$vuetify.lang.t("$vuetify.project_credentials.whitelisted_domain_validation_txt");
      };
    },
    initialSetupDone() {
      return (
        this.selectedProject?.setup_state &&
        this.selectedProject.setup_state.every(entry => entry.status === 'success')
      );
    },
  },
  mounted() {
    this.disableCrudOperation();
  },
  beforeMount() {
    /* TODO: Here if we are calling three(3) API's at a time, 
     the recommender gets WORKER failed to load and get restarted.
     Need to handle this scenerio first. For now we are 
     calling the API with a small delay so that user experiences 
     is not get affected.

      // this.getMapper();
      // this.getRankSettings();
      // this.getTrainTasks(this.defaultPagination);
     */
    if (this.initialSetupDone){
      this.getTrainTasks(this.defaultPagination);
    
      setTimeout(() => {
        this.getMapper();
          // setTimeout(() => {
          //   this.getRankSettings();
          // }, 800);
      }, 1000);
    }
    
  },
  created() {
    this.getAllCredential();
  },
  methods: {
    ...mapActions("project", [
      LOAD_ALL_CREDENTIAL,
      SAVE_CREDENTIAL,
      UPDATE_CREDENTIAL,
      GET_MAPPER,
      GET_RANK_SETTINGS,
      LOAD_TRAIN_TASKS,
      GET_PREVIOUSLY_SETUP_STATE
    ]),
    ...mapActions("alert", [ADD_ALERT]),
    copyApiKey() {
      navigator.clipboard.writeText(this.api_key);
      this.api_key_copy = true;
    },
    copyProjectKey() {
      navigator.clipboard.writeText(this.project_key);
      this.project_key_copy = true;
    },
    copyClientKey() {
      navigator.clipboard.writeText(this.client_key);
      this.client_key_copy = true;
    },
    async getAllCredential() {
      this.isLoading = true;
      try {
        await this.LOAD_ALL_CREDENTIAL({
          project_id: this.selectedProject.id,
        });
      } catch (error) {
        console.error("Error loading credentials:", error);
      } finally {
        this.isLoading = false;
      }
    },
    getSecurityLevelsDescriptions(level){
      switch (level) {
        case "High Risk":
          return this.$vuetify.lang.t("$vuetify.project_credentials.credentials_risk_level_high_risk_description_txt");
        case "Medium":
          return this.$vuetify.lang.t("$vuetify.project_credentials.credentials_risk_level_medium_description_txt");
        default:
          return this.$vuetify.lang.t("$vuetify.project_credentials.credentials_risk_level_no_risk_description_txt");
      }
    },
    getTrainTasks(options) {
      this.defaultPagination = this.deepCopy(options);
      this.isTrainHistoryLoading = true;
      this.LOAD_TRAIN_TASKS({
        project_id: this.selectedProject.id,
        train_type: this.filterTrainHistoryType,
        status: "all_status",
        start: (options.page - 1) * options.itemsPerPage,
        size: options.itemsPerPage,
      }).then(
        (response) => {
          this.totalNumberOfTrains = response.data.detail.total_train;
          this.taskHistoryData = response.data.detail.response;
          this.isTrainHistoryLoading = false;
        },
        (error) => {
          this.isTrainHistoryLoading = false;
        }
      );
    },
    getMapper() {
      this.isLoading = true;
      this.trainLoader = true;
      this.GET_MAPPER({
        project_id: this.selectedProject.id,
      }).then(
        (response) => {
          if (!this.updateFlag){
            this.getRankSettings();
          }
          this.mapper = response.data;
          this.isLoading = false;
          this.trainLoader = false;
        },
        (error) => {
          this.isLoading = false;
          this.trainLoader = false;
        }
      );
    },
    getRankSettings() {
      this.updateLoader = true;
      this.GET_RANK_SETTINGS({
        project_id: this.selectedProject.id,
      }).then(
        (response) => {
          this.rankSettings = response.data;
          this.isLoading = false;
          this.updateLoader = false;
        },
        (error) => {
          this.isLoading = false;
          this.updateLoader = false;
        }
      );
    },
    createNewClick() {
      if (this.selectedProject.status === "running") {
        this.resetForm();
        this.edit = false;
        this.credentialName = "";
        this.credentialType = "project";
        this.whitelistedDomain = {};
        this.whitelistedDomainShow = [];
        this.credentialResponse = null;
        this.dialog = true;
      } else if (this.selectedProject.status === "locked") {
        store.dispatch(
          `alert/${ADD_ALERT}`,
          { message: this.alertText.projectLocked, color: "error" },
          { root: true }
        );
      } else {
        store.dispatch(
          `alert/${ADD_ALERT}`,
          { message: this.alertText.createNotAllow, color: "error" },
          { root: true }
        );
      }
    },
    editItem(item) {
      this.edit = true;
      this.toBeUpdatedItem = item.id;
      this.credentialName = item.name;
      this.credentialType = item.key_type;
      this.dialog = true;
      if (item.key_metadata !== null){
        this.whitelistedDomainShow = item.key_metadata.whitelists;
      }
    },
    startItem(item) {
      this.updateCredential({
        project_id: this.selectedProject.id,
        credential_id: item.id,
        status: "active",
      });
    },
    stopItem(item) {
      this.updateCredential({
        project_id: this.selectedProject.id,
        credential_id: item.id,
        status: "inactive",
      });
    },
    downloadCredential() {
      let json_object = this.credentialResponse;
      let file_name = `${
        this.credentialName.indexOf(" ") >= 0
          ? this.credentialName.trim().replace(/\s/g, "-")
          : this.credentialName
      }-${this.$route.params.project_id}.json`;
      if (!window.navigator.msSaveOrOpenBlob) {
        // BLOB NAVIGATOR
        const url = window.URL.createObjectURL(new Blob([JSON.stringify(json_object)]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file_name);
        document.body.appendChild(link);
        link.click();
      } else {
        // BLOB FOR EXPLORER 11
        const url = window.navigator.msSaveOrOpenBlob(
          new Blob([JSON.stringify(json_object)]),
          file_name
        );
      }
    },
    saveCredentialClick() {
      if (this.validateForm) {
        if (this.isValidWhitelistedDomain) {
          let domains = Array.isArray(this.whitelistedDomainShow) ? this.whitelistedDomainShow : [];
          this.whitelistedDomain = {
            'whitelists': domains
          }
        }
        if (this.edit) {
          if (this.selectedProject.id && this.toBeUpdatedItem) {
            this.updateCredential({
              project_id: this.selectedProject.id,
              credential_id: this.toBeUpdatedItem,
              name: this.credentialName,
              type: this.credentialType,
              whitelisted: this.whitelistedDomain
            });
          }
        } else {
          if (this.selectedProject.id) {
            this.saveCredential({
              project_id: this.selectedProject.id,
              name: this.credentialName,
              type: this.credentialType,
              whitelisted: this.whitelistedDomain
            });
          }
        }
      }
    },
    saveCredential(body_json) {
      this.isLoading = true;
      this.SAVE_CREDENTIAL(body_json).then(
        (response) => {
          this.credentialResponse = response.data.details;
          this.downloadMessage = response.data.message;
          this.api_key = response.data.details.api_key;
          this.project_key = response.data.details.project_key;
          this.client_key = response.data.details.client_key;
          this.dialog = false;
          this.successDialog = true;
          this.getAllCredential();
        },
        (error) => {
          this.isLoading = false;
        }
      );
    },
    updateCredential(body_json) {
      this.isLoading = true;
      this.UPDATE_CREDENTIAL(body_json).then(
        (response) => {
          this.dialog = false;
          this.getAllCredential();
        },
        (error) => {
          this.isLoading = false;
        }
      );
    },
    validateForm() {
      return this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    resetForm() {
      this.$refs.form.reset();
    },
    disableCrudOperation(){
      if(authorize([Role.USER])){
        this.disable_crud_operation = true;
      }
    },
    paginateTrainHistory(options){
      if (!this.deepEqual(options, this.defaultPagination)) {
        this.getTrainTasks(options);
      }
    },
    deepEqual(obj1, obj2) {
      if (obj1 === obj2) return true;

      if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) return false;

      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) return false;

      for (const key of keys1) {
        if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) return false;
      }

      return true;
    },
    onFilterTrainTypeChange() {
      this.paginateTrainHistoryObj.page = 1
      this.getTrainTasks(this.paginateTrainHistoryObj);
    },
    deepCopy(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    handleMapperUpdateComplete() {
      this.updateFlag = true;
      this.getMapper();
    },
    handleTrainHistoryTableComplete() {
      this.getTrainTasks(this.defaultPagination);
    },
    handleSettingsUpdate(){
      this.getPreviouslySetupState();
    },
    getPreviouslySetupState(){
      this.isLoading = true;
      this.isSetupStateLoading = true;
      this.GET_PREVIOUSLY_SETUP_STATE({
        project_id: this.$route.params.project_id,
      }).then(
        (response) => {
          this.isSetupStateLoading = false;
          // Here we are updating global state : previouslySetupState
        },
        (error) => {
          this.isSetupStateLoading = false;
        }
      );
    },
  },
};
</script>

<style scoped>
.banner-style {
  border-color: rgba(241, 101, 8, 0.986);
  border-width: 2px;
  border-radius: 5px !important;
  color: rgba(241, 101, 8, 0.986);
  background-color: #fff !important;
}
</style>