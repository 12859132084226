<template>
  <div>
    <v-container fill-height fluid v-if="status !== 'created'">
      <v-row>
        <v-col>
          <v-skeleton-loader
            type="card-avatar, article, actions"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
    <v-container fill-height fluid v-if="status === 'created'">
      <v-row align="center" justify="start">
        <v-col class="pa-0" cols="12" sm="12" md="10">
          <v-card outlined class="elevation-2 mx-auto">
            <v-toolbar flat>
              <v-toolbar-title class="ma-auto font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.complete_register_header_txt") }}
              </v-toolbar-title>
            </v-toolbar>
            <v-stepper v-model="e1" flat>
              <v-stepper-header>
                <template v-for="item in steps">
                  <v-stepper-step
                    :key="`${item.value}-step`"
                    :complete="
                      e1 >
                      (!showAccountInfo && item.value == 3 ? 2 : item.value)
                    "
                    :step="!showAccountInfo && item.value == 3 ? 2 : item.value"
                  >
                    <div>{{ item.title }}</div>
                  </v-stepper-step>

                  <v-divider
                    v-if="item.value !== 3"
                    :key="item.value"
                  ></v-divider>
                </template>
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content
                  v-for="item in steps"
                  :key="`${item.value}-content`"
                  :step="item.value"
                >
                  <div v-if="item.value === 1">
                    <ValidationObserver ref="firstStep">
                      <v-row>
                        <v-col cols="12" md="6">
                          <span class="font-weight-bold">
                            {{
                              `${$vuetify.lang.t(
                                "$vuetify.account_name_label_txt",
                              )}:`
                            }}
                          </span>
                          <span class="body-1">
                            {{ accountName }}
                          </span>
                        </v-col>

                        <!-- TODO email_label_txt  -->
                        <v-col cols="12" md="6">
                          <span class="font-weight-bold">
                            {{
                              `${$vuetify.lang.t("$vuetify.email_label_txt")}:`
                            }}
                          </span>
                          <span class="body-1">
                            {{ organization.email }}
                          </span>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-form ref="step1">
                        <v-row class="mt-4">
                          <v-col cols="12" md="6">
                            <ValidationProvider
                              name="First Name"
                              rules="required|is_numeric"
                              v-slot="{ errors }"
                            >
                              <v-text-field
                                v-model="user.first_name"
                                :label="
                                  $vuetify.lang.t(
                                    '$vuetify.first_name_label_txt',
                                  )
                                "
                                :error-messages="
                                  localizeErrorMsg(
                                    errors,
                                    'first_name',
                                    user.first_name,
                                  )
                                "
                                outlined
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" md="6" v-if="isVisible">
                            <ValidationProvider
                              name="Middle Name"
                              rules="is_numeric"
                              v-slot="{ errors }"
                            >
                              <v-text-field
                                :label="
                                  $vuetify.lang.t(
                                    '$vuetify.middle_name_label_txt',
                                  )
                                "
                                v-model="user.additional_name"
                                :error-messages="
                                  localizeErrorMsg(
                                    errors,
                                    'middle_name',
                                    user.additional_name,
                                  )
                                "
                                outlined
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" md="6">
                            <ValidationProvider
                              name="Last Name"
                              rules="required|is_numeric"
                              v-slot="{ errors }"
                            >
                              <v-text-field
                                :label="
                                  $vuetify.lang.t(
                                    '$vuetify.last_name_label_txt',
                                  )
                                "
                                v-model="user.last_name"
                                :error-messages="
                                  localizeErrorMsg(
                                    errors,
                                    'last_name',
                                    user.last_name,
                                  )
                                "
                                outlined
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" md="6" v-if="isVisible">
                            <ValidationProvider name="Gender">
                              <v-autocomplete
                                :label="
                                  $vuetify.lang.t('$vuetify.gender_label_txt')
                                "
                                :items="genderParams"
                                v-model="user.gender"
                                item-text="text"
                                item-value="value"
                                outlined
                              ></v-autocomplete>
                            </ValidationProvider>
                          </v-col>

                          <v-col cols="12" md="6" v-if="isVisible">
                            <v-dialog
                              ref="dialog"
                              v-model="modal"
                              :return-value.sync="user.date_of_birth"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <ValidationProvider
                                  name="Date Of Birth"
                                  rules="birth_date"
                                  v-slot="{ errors }"
                                >
                                  <v-text-field
                                    v-model="user.date_of_birth"
                                    :label="
                                      $vuetify.lang.t('$vuetify.dob_label_txt')
                                    "
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined
                                    :error-messages="
                                      localizeErrorMsg(errors, 'dob')
                                    "
                                  ></v-text-field>
                                </ValidationProvider>
                              </template>
                              <v-date-picker
                                v-model="user.date_of_birth"
                                scrollable
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  outlined
                                  color="primary"
                                  @click="modal = false"
                                >
                                  {{
                                    $vuetify.lang.t("$vuetify.cancel_btn_txt")
                                  }}
                                </v-btn>
                                <v-btn
                                  outlined
                                  color="primary"
                                  @click="
                                    $refs[`dialog`][0].save(user.date_of_birth)
                                  "
                                >
                                  {{ $vuetify.lang.t("$vuetify.OK_btn_txt") }}
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-col>

                          <v-col cols="12" md="6" v-if="isVisible">
                            <!-- <ValidationProvider
                              name="Phone"
                              rules="required|phone_num"
                              v-slot="{ errors }"
                            > -->
                            <ValidationProvider
                              name="Phone"
                              rules="phone_num"
                              v-slot="{ errors }"
                            >
                              <vue-tel-input-vuetify
                                v-model="user.phone"
                                :errorMessages="
                                  localizeErrorMsg(
                                    errors,
                                    'phone_no',
                                    user.phone,
                                  )
                                "
                                @input="onInput"
                                mode="international"
                                placeholder=" "
                                :label="
                                  $vuetify.lang.t('$vuetify.phone_no_label_txt')
                                "
                                :disabledFetchingCountry="true"
                                outlined
                              ></vue-tel-input-vuetify>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                        <!-- <v-row>
                          <v-col cols="12" md="6"> -->
                        <!-- <v-row no-gutters justify="center" align="center"> -->
                        <!-- <v-col cols="8"> -->
                        <!-- <v-file-input
                              show-size
                              label="Upload Profile"
                              accept="image/*"
                              prepend-inner-icon="mdi-camera"
                              @change="selectImage"
                              outlined
                            ></v-file-input> -->
                        <!-- </v-col> -->

                        <!-- <v-col cols="4" class="pl-2">
                                    <v-btn color="primary" dark small @click="upload">
                                      Upload
                                      <v-icon right dark>mdi-cloud-upload</v-icon>
                                    </v-btn>
                                  </v-col> -->
                        <!-- </v-row> -->
                        <!-- <div v-if="progress">
                              <div>
                                <v-progress-linear
                                  v-model="progress"
                                  color="light-blue"
                                  height="25"
                                  reactive
                                >
                                  <strong>{{ progress }} %</strong>
                                </v-progress-linear>
                              </div>
                            </div>
                          </v-col>
                          <v-col cols="12" md="6">
                            <div v-if="previewImage && currentImage">
                              <div>
                                <v-img
                                  class=" preview"
                                  width="300"
                                  height="200"
                                  contain
                                  :src="previewImage"
                                  alt=""
                                ></v-img>
                              </div>
                            </div>
                          </v-col>
                        </v-row> -->
                        <!--                          <v-divider></v-divider>-->

                        <div class="primary--text text-h6" v-if="isVisible">
                          {{ $vuetify.lang.t("$vuetify.address_label_txt") }}
                        </div>
                        <v-divider class="mb-3" v-if="isVisible"></v-divider>
                        <v-row v-if="isVisible">
                          <v-col cols="12" md="6">
                            <!-- <ValidationProvider
                              name="Country"
                              rules="required"
                              v-slot="{ errors }"
                            > -->
                            <ValidationProvider
                              name="Country"
                              rules=""
                              v-slot="{ errors }"
                            >
                              <form autocomplete="off" @submit.prevent>
                                <VCountrySelect
                                  v-model="user.country"
                                  itemText="countryName"
                                  :label="
                                    $vuetify.lang.t(
                                      '$vuetify.country_label_txt',
                                    )
                                  "
                                  :errorMessage="
                                    localizeErrorMsg(errors, 'country')
                                  "
                                  outlined
                                  autocomplete="off"
                                />
                              </form>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" md="6">
                            <!-- <ValidationProvider
                              name="State"
                              rules="required"
                              v-slot="{ errors }"
                            > -->
                            <ValidationProvider
                              name="State"
                              rules=""
                              v-slot="{ errors }"
                            >
                              <form autocomplete="off" @submit.prevent>
                                <VRegionSelect
                                  v-model="user.region"
                                  itemText="name"
                                  :country="user.country"
                                  :label="
                                    $vuetify.lang.t('$vuetify.state_label_txt')
                                  "
                                  :errorMessage="
                                    localizeErrorMsg(errors, 'state')
                                  "
                                  outlined
                                  autocomplete="off"
                                />
                              </form>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" md="6">
                            <!-- <ValidationProvider
                              name="City"
                              rules="required|is_numeric"
                              v-slot="{ errors }"
                            > -->
                            <ValidationProvider
                              name="City"
                              rules="is_numeric"
                              v-slot="{ errors }"
                            >
                              <v-text-field
                                v-model="user.city"
                                :error-messages="
                                  localizeErrorMsg(errors, 'city', user.city)
                                "
                                :label="
                                  $vuetify.lang.t('$vuetify.city_label_txt')
                                "
                                outlined
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" md="6">
                            <!-- <ValidationProvider
                              name="Postal Code"
                              mode="eager"
                              rules="required|postal_code:@Country"
                              v-slot="{ errors }"
                            > -->
                            <ValidationProvider
                              name="Postal Code"
                              rules="postal_code:@Country"
                              v-slot="{ errors }"
                            >
                              <v-text-field
                                v-model="user.postal_code"
                                :error-messages="
                                  localizeErrorMsg(
                                    errors,
                                    'post_code',
                                    user.postal_code,
                                  )
                                "
                                :label="
                                  $vuetify.lang.t(
                                    '$vuetify.post_code_label_txt',
                                  )
                                "
                                outlined
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12">
                            <ValidationProvider
                              name="Address"
                              rules="is_numeric"
                              v-slot="{ errors }"
                            >
                              <v-textarea
                                v-model="user.address"
                                :error-messages="
                                  localizeErrorMsg(
                                    errors,
                                    'address',
                                    user.address,
                                  )
                                "
                                :label="
                                  $vuetify.lang.t('$vuetify.address_label_txt')
                                "
                                rows="2"
                                outlined
                              ></v-textarea>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                      </v-form>
                    </ValidationObserver>
                  </div>

                  <!-- second step  -->
                  <div v-if="item.value === 2">
                    <ValidationObserver ref="stepTwo">
                      <v-form ref="step2">
                        <v-row>
                          <v-col cols="12" md="6">
                            <ValidationProvider
                              name="Account Name"
                              rules="required|is_numeric"
                              v-slot="{ errors }"
                            >
                              <v-text-field
                                :label="
                                  $vuetify.lang.t(
                                    '$vuetify.account_name_label_txt',
                                  )
                                "
                                v-model="accountName"
                                :error-messages="
                                  localizeErrorMsg(
                                    errors,
                                    'account_name',
                                    accountName,
                                  )
                                "
                                disabled
                                outlined
                              >
                              </v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" md="6">
                            <ValidationProvider
                              name="Account Type"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <v-autocomplete
                                :label="
                                  $vuetify.lang.t(
                                    '$vuetify.account_type_label_txt',
                                  )
                                "
                                v-model="accountType"
                                :items="accountTypeParams"
                                item-text="text"
                                item-value="value"
                                :error-messages="
                                  localizeErrorMsg(errors, 'account_type')
                                "
                                outlined
                              ></v-autocomplete>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                        <div v-if="accountType === 'organization' && showOrgDetails" >
                          <v-row>
                            <v-col cols="12" md="6">
                              <ValidationProvider
                                name="Organization Name"
                                rules="required|is_numeric"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  :label="
                                    $vuetify.lang.t(
                                      '$vuetify.org_name_label_txt',
                                    )
                                  "
                                  v-model="organization.name"
                                  :error-messages="
                                    localizeErrorMsg(
                                      errors,
                                      'org_name',
                                      organization.name,
                                    )
                                  "
                                  outlined
                                >
                                </v-text-field>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12" md="6" v-if="isVisible">
                              <ValidationProvider
                                name="Organization VAT Number"
                                rules=""
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  :label="
                                    $vuetify.lang.t('$vuetify.vat_no_label_txt')
                                  "
                                  v-model="organization.vat_number"
                                  :error-messages="errors"
                                  outlined
                                >
                                </v-text-field>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12" md="6" v-if="isVisible">
                              <ValidationProvider
                                name="Organization Registration Number"
                                rules=""
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  :label="
                                    $vuetify.lang.t('$vuetify.reg_no_label_txt')
                                  "
                                  :error-messages="errors"
                                  v-model="organization.registration_number"
                                  outlined
                                >
                                </v-text-field>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12" md="6">
                              <ValidationProvider
                                name="Registered Managing Director/CEO"
                                rules="required|is_numeric"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  :label="
                                    $vuetify.lang.t('$vuetify.ceo_label_txt')
                                  "
                                  v-model="organization.director_name"
                                  :error-messages="
                                    localizeErrorMsg(
                                      errors,
                                      'ceo',
                                      organization.director_name,
                                    )
                                  "
                                  outlined
                                >
                                </v-text-field>
                              </ValidationProvider>
                            </v-col>

                            <!-- TODO -->
                            <v-col cols="12" md="6" v-if="isVisible">
                              <ValidationProvider
                                name="Organization Email"
                                rules="email"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  :label="
                                    $vuetify.lang.t(
                                      '$vuetify.org_email_label_txt',
                                    )
                                  "
                                  v-model="organization.email"
                                  :error-messages="
                                    localizeErrorMsg(errors, 'org_email')
                                  "
                                  outlined
                                >
                                </v-text-field>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12" md="6" v-if="isVisible">
                              <ValidationProvider
                                name="Organization Phone"
                                rules="phone_num"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  :label="
                                    $vuetify.lang.t(
                                      '$vuetify.org_phone_label_txt',
                                    )
                                  "
                                  v-model="organization.phone"
                                  :error-messages="
                                    localizeErrorMsg(
                                      errors,
                                      'org_phone',
                                      organization.phone,
                                    )
                                  "
                                  outlined
                                >
                                </v-text-field>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12" md="6">
                              <ValidationProvider
                                name="Organization Website"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  :label="
                                    $vuetify.lang.t(
                                      '$vuetify.org_website_label_txt',
                                    )
                                  "
                                  v-model="organization.website"
                                  :error-messages="
                                    localizeErrorMsg(
                                      errors,
                                      'org_website',
                                      organization.website,
                                    )
                                  "
                                  outlined
                                >
                                </v-text-field>
                              </ValidationProvider>
                            </v-col>

                            <v-col cols="12" md="6">
                              <ValidationProvider
                                name="Organization Size"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <form autocomplete="off" @submit.prevent>
                                  <v-autocomplete
                                    :label="
                                      $vuetify.lang.t(
                                        '$vuetify.org_size_label_txt',
                                      )
                                    "
                                    :items="orgSizeParams"
                                    item-value="value"
                                    item-text="text"
                                    :error-messages="
                                      localizeErrorMsg(errors, 'org_size')
                                    "
                                    v-model="organization.size"
                                    outlined
                                  >
                                  </v-autocomplete>
                                </form>
                              </ValidationProvider>
                            </v-col>

                            <v-col cols="12" md="6" v-if="isVisible">
                              <ValidationProvider
                                name="Industry"
                                v-slot="{ errors }"
                              >
                                <form autocomplete="off" @submit.prevent>
                                  <v-autocomplete
                                    :items="allCategory"
                                    :label="
                                      $vuetify.lang.t(
                                        '$vuetify.org_industry_label_txt',
                                      )
                                    "
                                    v-model="organization.industry"
                                    item-text="name"
                                    :search-input.sync="searchIndustry"
                                    :error-messages="errors"
                                    return-object
                                    outlined
                                  >
                                    <!-- <template v-slot:append-outer>
                                      <v-slide-x-reverse-transition mode="out-in">
                                        <v-icon
                                          color="primary"
                                          @click="createIndustry()"
                                          v-text="'mdi-plus'"
                                        >
                                        </v-icon>
                                      </v-slide-x-reverse-transition>
                                    </template> -->
                                  </v-autocomplete>
                                </form>
                              </ValidationProvider>
                            </v-col>
                          </v-row>
                          <!--                            <v-divider></v-divider>-->
                          <div class="primary--text text-h6">
                            {{
                              $vuetify.lang.t(
                                "$vuetify.registered_address_header_txt",
                              )
                            }}
                          </div>
                          <v-divider class="mb-3"></v-divider>
                          <v-row>
                            <v-col cols="12" md="6">
                              <ValidationProvider
                                name="Country"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <form autocomplete="off" @submit.prevent>
                                  <VCountrySelect
                                    v-model="organization.country"
                                    itemText="countryName"
                                    :label="
                                      $vuetify.lang.t(
                                        '$vuetify.country_label_txt',
                                      )
                                    "
                                    :errorMessage="
                                      localizeErrorMsg(errors, 'country')
                                    "
                                    outlined
                                  />
                                </form>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12" md="6">
                              <ValidationProvider
                                name="State"
                                rules=""
                                v-slot="{ errors }"
                              >
                                <form autocomplete="off" @submit.prevent>
                                  <VRegionSelect
                                    v-model="organization.region"
                                    itemText="name"
                                    :country="organization.country"
                                    :label="
                                      $vuetify.lang.t(
                                        '$vuetify.state_label_txt',
                                      )
                                    "
                                    :errorMessage="errors"
                                    outlined
                                  />
                                </form>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12" md="6">
                              <ValidationProvider
                                name="City"
                                rules="is_numeric"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  v-model="organization.city"
                                  :error-messages="
                                    localizeErrorMsg(
                                      errors,
                                      'city',
                                      organization.city,
                                    )
                                  "
                                  :label="
                                    $vuetify.lang.t('$vuetify.city_label_txt')
                                  "
                                  outlined
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12" md="6">
                              <ValidationProvider
                                name="Postal Code"
                                mode="eager"
                                rules="required|postal_code:@Country"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  v-model="organization.postal_code"
                                  :error-messages="
                                    localizeErrorMsg(
                                      errors,
                                      'post_code',
                                      organization.postal_code,
                                    )
                                  "
                                  :label="
                                    $vuetify.lang.t(
                                      '$vuetify.post_code_label_txt',
                                    )
                                  "
                                  outlined
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12">
                              <ValidationProvider
                                name="Address"
                                rules="required|is_numeric"
                                v-slot="{ errors }"
                              >
                                <v-textarea
                                  v-model="organization.address"
                                  :error-messages="
                                    localizeErrorMsg(
                                      errors,
                                      'address',
                                      organization.address,
                                    )
                                  "
                                  :label="
                                    $vuetify.lang.t(
                                      '$vuetify.address_label_txt',
                                    )
                                  "
                                  rows="2"
                                  outlined
                                ></v-textarea>
                              </ValidationProvider>
                            </v-col>
                          </v-row>
                        </div>
                      </v-form>
                    </ValidationObserver>
                  </div>

                  <div v-if="item.value === 3">
                    <BasicInformation :user="user" :profileImg="previewImage" />

                    <AccountInformation
                      :show-account-info="showAccountInfo"
                      :account-type="accountType"
                      :account-name="accountName"
                      :organization="organization"
                    />
                  </div>

                  <v-row align="center" justify="start">
                    <v-col cols="12">
                      <v-btn
                        v-if="item.value !== 1"
                        color="primary"
                        class="mr-4"
                        large
                        outlined
                        :loading="false"
                        @click="previousStep(item.value)"
                        >{{ $vuetify.lang.t("$vuetify.prev_btn_txt") }}</v-btn
                      >
                      <v-btn
                        v-if="item.value === 3"
                        color="primary"
                        class="mr-4"
                        large
                        :loading="isAccountCreating"
                        @click="saveRegistration()"
                        >{{ $vuetify.lang.t("$vuetify.save_btn_txt") }}</v-btn
                      >
                      <v-btn
                        v-else
                        color="primary"
                        class="mr-4"
                        large
                        :loading="false"
                        @click="handleContinue(item.value)"
                        >{{
                          $vuetify.lang.t("$vuetify.continue_btn_txt")
                        }}</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card>
        </v-col>
      </v-row>
      <!-- industry category dialog  -->
      <v-dialog v-model="createIndustryDialog" max-width="600">
        <v-card>
          <v-card-title class="headline primary white--text" primary-title>
            {{ $vuetify.lang.t("$vuetify.create_industry_header_txt") }}
          </v-card-title>
          <div class="pa-5">
            <v-form ref="categoryForm">
              <v-text-field
                type="text"
                v-model="industryName"
                :label="$vuetify.lang.t('$vuetify.industry_name_label_txt')"
                outlined
                :rules="[
                  required($vuetify.lang.t('$vuetify.industry_name_label_txt')),
                ]"
              ></v-text-field>
              <v-textarea
                :label="$vuetify.lang.t('$vuetify.description_label_txt')"
                v-model="industryDescription"
                outlined
                :rules="[
                  required($vuetify.lang.t('$vuetify.description_label_txt')),
                ]"
              ></v-textarea>
            </v-form>
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-2"
              color="error"
              @click="createIndustryDialog = false"
            >
              {{ $vuetify.lang.t("$vuetify.close_btn_txt") }}
            </v-btn>
            <v-btn
              color="primary"
              :loading="isCreatingInd"
              @click="saveIndustry()"
            >
              {{ $vuetify.lang.t("$vuetify.save_btn_txt") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  REGISTER,
  LOAD_ALL_CATEGORY,
  SAVE_CATEGORY,
  COMPLETE_REGISTRATION,
  MEMBER_SETUP,
  ADD_ALERT,
  LOAD_ORGANIZATION,
  LOAD_ACCOUNT,
} from "@/store/_actiontypes";
import {
  OUTLINED_BUTTON,
  RAISED_BUTTON,
  TEXT_BUTTON,
} from "@/components/common/button/_buttontypes";
import {
  TEXT,
  PASSWORD,
  AUTO_COMPLETE,
  MULT_AUTO_COMPLETE,
} from "@/components/common/input/_inputTypes";
import validations from "@/helpers/validations";
import router from "@/router/index";
import { VCountrySelect, VRegionSelect } from "vuetify-country-search";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import "@/helpers/vee-validate";
import BasicInformation from "@/components/CompleteRegister/BasicInformation";
import AccountInformation from "@/components/CompleteRegister/AccountInformation";
import { Role } from "@/helpers/role";
import { authorize } from "@/helpers/helper";
import jwt_decode from "jwt-decode";

export default {
  components: {
    VCountrySelect,
    VRegionSelect,
    ValidationObserver,
    ValidationProvider,
    BasicInformation,
    AccountInformation,
  },
  props: {
    genderParams: {
      type: Array,
      default() {
        return [
          {
            text: this.$vuetify.lang.t("$vuetify.male_label_txt"),
            value: "male",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.female_label_txt"),
            value: "female",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.other_label_txt"),
            value: "other",
          },
        ];
      },
    },
    orgSizeParams: {
      type: Array,
      default() {
        return [
          {
            text: this.$vuetify.lang.t("$vuetify.org_size_opt_1_txt"),
            value: "0 - 9",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.org_size_opt_2_txt"),
            value: "10 - 49",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.org_size_opt_3_txt"),
            value: "50 - 149",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.org_size_opt_4_txt"),
            value: "150 - 249",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.org_size_opt_5_txt"),
            value: "250 - 999",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.org_size_opt_6_txt"),
            value: "1000 ~ ",
          },
        ];
      },
    },
    accountTypeParams: {
      type: Array,
      default() {
        return [
          {
            text: this.$vuetify.lang.t(
              "$vuetify.account_type_value_individual",
            ),
            value: "individual",
          },
          {
            text: this.$vuetify.lang.t(
              "$vuetify.account_type_value_organization",
            ),
            value: "organization",
          },
        ];
      },
    },
  },
  computed: {
    ...mapState({
      allCategory: state => state.category.allCategory,
      loading: state => state.loader.loading,
      organizationAccount: state => state.account.organization,
      accountInfo: state => state.account.account,
      userToken: state => state.account.user,
    }),
  },
  created() {
    let parsedJWTJson = jwt_decode(this.userToken.access_token);
    if (authorize([Role.ADMIN]) && parsedJWTJson["ast"] === "created") {
      this.showAccountInfo = true;
    } else {
      this.steps.splice(1, 1);
    }

    this.getAll();
  },
  data() {
    return {
      TEXT: TEXT,
      PASSWORD: PASSWORD,
      OUTLINED_BUTTON: OUTLINED_BUTTON,
      AUTO_COMPLETE: AUTO_COMPLETE,
      MULT_AUTO_COMPLETE: MULT_AUTO_COMPLETE,
      RAISED_BUTTON: RAISED_BUTTON,
      TEXT_BUTTON: TEXT_BUTTON,
      showOrgDetails: false,
      organization: {
        name: "",
        size: "",
        phone: "",
        vat_number: "",
        registration_number: "",
        country: "",
        region: "",
        city: "",
        postal_code: "",
        director_name: "",
        website: "",
        industry: "",
        email: "",
        logo: "logo will coming soon",
        address: "",
      },
      ...validations,
      e1: 1,
      steps: [
        {
          value: 1,
          title: this.$vuetify.lang.t("$vuetify.basic_info_header_txt"),
        },
        {
          value: 2,
          title: this.$vuetify.lang.t("$vuetify.acc_info_header_txt"),
        },
        { value: 3, title: this.$vuetify.lang.t("$vuetify.review_step_txt") },
      ],
      accountType: "individual",
      user: {
        first_name: "",
        additional_name: "",
        last_name: "",
        date_of_birth: "",
        gender: "",
        phone: "",
        phoneInt: "",
        ip_address: "",
        country: "",
        region: "",
        city: "",
        postal_code: "",
        address: "",
        logo: "",
      },
      modal: false,
      account: {
        name: "",
        email: "",
      },
      accountName: "",
      email: "",
      // industryName: [],
      //image
      currentImage: undefined,
      previewImage: undefined,
      progress: 0,
      //industry
      industry: "",
      createIndustryDialog: false,
      industryName: "",
      industryDescription: "",
      searchIndustry: "",
      //user status
      status: undefined,
      isCreatingInd: false,
      isAccountCreating: false,
      showAccountInfo: false,
      isVisible: false,
    };
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
    accountInfo: {
      handler: function () {
        if (this.accountInfo) {
          this.accountName = this.accountInfo.account_name;
        }
      },
      immediate: true,
    },
    organizationAccount: {
      handler: function () {
        if (this.organizationAccount) {
          this.status = this.organizationAccount.status;
          this.email = this.organizationAccount.email;
          this.user.first_name = this.organizationAccount.first_name;
          this.user.last_name = this.organizationAccount.last_name;
          this.organization = {
            email: this.organizationAccount.email,
          };
          // this.organizationAccount.status === "active" && router.push("/dashboard");
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("account", [
      REGISTER,
      COMPLETE_REGISTRATION,
      LOAD_ORGANIZATION,
      LOAD_ACCOUNT,
    ]),
    ...mapActions("member", [MEMBER_SETUP]),
    ...mapActions("category", [LOAD_ALL_CATEGORY, SAVE_CATEGORY]),
    ...mapActions("alert", [ADD_ALERT]),
    localizeErrorMsg() {
      if (
        arguments[0] &&
        arguments[0].length &&
        arguments[1] &&
        !arguments[2]
      ) {
        return this.$vuetify.lang.t(`$vuetify.${arguments[1]}_error_msg_txt`);
      }
      if (arguments[0] && arguments[0].length && arguments[1] && arguments[2]) {
        return this.$vuetify.lang.t(`$vuetify.${arguments[1]}_error_msg_txt_2`);
      }
    },
    getAll() {
      this.getAllCategory();
      this.getIp();
    },
    getAllCategory() {
      this.LOAD_ALL_CATEGORY().catch(() => {});
    },
    //load account data
    getAccountData() {
      this.LOAD_ACCOUNT()
        .then(res => {
          let data = res.data;
          this.accountName = data.account_name;
        })
        .catch(() => {});
    },
    saveRegistration() {
      let step_refs = this.showAccountInfo ? "stepTwo" : "firstStep";
      // if(!this.$refs[`step2`][0].validate()) return;
      this.$refs[step_refs][0].validate().then(success => {
        if (!success) {
          return;
        }
        let indData = {
          first_name: this.user.first_name,
          middle_name: this.user.additional_name,
          last_name: this.user.last_name,
          ...(this.user.date_of_birth
            ? { birth_date: this.user.date_of_birth }
            : {}),
          ...(this.user.gender
            ? { gender: this.user.gender.toLowerCase() }
            : {}),
          phone: this.user.phoneInt?.replaceAll(" ", ""),
          ip_address: this.user.ip_address,
          country_code: this.user.country.countryCode,
          state_or_province: this.user.region.name,
          city: this.user.city,
          postal_code: this.user.postal_code,
          address: this.user.address,
          photo: this.user.logo,
        };
        if (this.showAccountInfo) {
          indData["account_name"] = this.accountName;
          indData["type"] = this.accountType;
        }
        let orgData = {
          //individual
          ...indData,
          //organization
          org_name: this.organization.name,
          org_size: this.organization.size,
          org_vat_number: this.organization.vat_number,
          org_registration_number: this.organization.registration_number,
          org_director_name: this.organization.director_name,
          org_email: this.organization.email,
          org_logo: this.organization.logo,
          ...(this.organization.industry
            ? { org_industry_id: this.organization.industry.id }
            : {}),
          org_website_url: this.organization.website,
          org_phone: this.organization.phone
            ? this.organization.phone?.replaceAll(" ", "")
            : this.organization.phone,
          org_address: {
            country_code: this.organization.country
              ? this.organization.country.countryCode
              : "",
            state_or_province: this.organization.region
              ? this.organization.region.name
              : "",
            city: this.organization.city,
            postal_code: this.organization.postal_code,
            address: this.organization.address,
          },
        };
        let userData = this.accountType === "organization" ? orgData : indData;

        this.isAccountCreating = true;
        if (this.user.first_name && this.user.last_name) {
          if (this.showAccountInfo) {
            this.COMPLETE_REGISTRATION({ userData })
              .then(_res => {
                this.$store.dispatch(
                  `alert/${ADD_ALERT}`,
                  {
                    message: this.$vuetify.lang.t(
                      "$vuetify.acc_update_msg_txt",
                    ),
                    color: "success",
                  },
                  { root: true },
                );
                this.isAccountCreating = false;
              })
              .catch(_err => {
                this.isAccountCreating = false;
              });
          } else {
            this.MEMBER_SETUP({ userData })
              .then(_res => {
                this.REFRESHTOKEN({
                  refresh_token: this.userToken.refresh_token,
                }).then(_response => {
                  router.push("/dashboard");
                });
                this.isAccountCreating = false;
              })
              .catch(_err => {
                this.isAccountCreating = false;
              });
          }
        }
      });
    },
    handleLoginClick() {
      router.push("/login");
    },
    completeRegistration() {},
    // redirect if user status is active
    handleCheckStatus() {
      this.LOAD_ORGANIZATION()
        .then(res => {
          this.status = res.data.status;
          this.email = res.data.email;
          this.organization = {
            email: res.data.email,
          };
          this.user = {
            first_name: res.data.first_name,
            last_name: res.data.last_name,
          };
          res.data.status === "active" && router.push("/dashboard");
        })
        .catch(() => {});
    },
    // get user ip address
    getIp() {
      fetch("https://api.ipify.org?format=json")
        .then(res => res.json())
        .then(({ ip }) => {
          this.user.ip_address = ip;
        })
        .catch(_err => {});
    },
    // handle industry
    handleIndustry() {
      this.allCategory.map(data => {
        this.industryName.push(data.display_name);
      });
    },
    createIndustry() {
      this.createIndustryDialog = !this.createIndustryDialog;
      this.industryName = this.searchIndustry;
    },
    saveIndustry() {
      if (!this.$refs.categoryForm.validate()) return;
      this.isCreatingInd = true;
      if (this.industryName && this.industryDescription) {
        this.SAVE_CATEGORY({
          name: this.industryName,
          description: this.industryDescription,
        }).then(
          response => {
            this.getAllCategory();
            this.organization.industry = response.data;
            this.createIndustryDialog = false;
            this.isCreatingInd = false;
          },
          error => {
            this.alertMessage = error.response.data.message;
            this.alertDialog = true;
            this.isCreatingInd = false;
          },
        );
      }
    },
    // image upload
    selectImage(image) {
      this.currentImage = image;
      let binaryData = [];
      binaryData.push(this.currentImage);
      this.previewImage = binaryData
        ? URL.createObjectURL(new Blob(binaryData))
        : undefined;
      this.progress = 0;
      // this.message = "";
    },
    // upload
    upload() {
      if (!this.currentImage) {
        this.message = this.$vuetify.lang.t("$vuetify.select_img_msg_txt");
        return;
      }
      // @TODO Image upload api and function will be integrate soon
    },
    //stepper
    previousStep(n) {
      let step = this.showAccountInfo ? 1 : 2;
      this.e1 = n - step;
    },
    handleContinue(n) {
      if (n === 1) {
        this.$refs[`firstStep`][0].validate().then(success => {
          if (!success) {
            return;
          }
          this.e1 = this.showAccountInfo ? n + 1 : n + 2;
        });
      } else if (n === 2) {
        this.$refs[`stepTwo`][0].validate().then(success => {
          if (!success) {
            return;
          }
          this.e1 = n + 1;
        });
      }
    },
    onInput(formattedNumber, { number, _valid, _country }) {
      this.user.phoneInt = number.international;
      // this.phone.valid = valid;
      // this.phone.country = country && country.name;
    },
  },
};
</script>

<style></style>
