<template>
  <div>
    <template>
      <div>
        <v-divider></v-divider>
        <v-row>
          <v-col xs="6" md="6" sm="6">
            <div class="px-2 primary--text font-weight-bold">
              {{ $vuetify.lang.t("$vuetify.catalog.catalog_items") }}
            </div>
          </v-col>
          <!-- delete all items -->
          <v-col md="6">
            <v-btn
              class="text-capitalize float-right"
              color="error"
              outlined
              @click="handleDeleteClick"
            >
              {{ $vuetify.lang.t("$vuetify.tooltip.delete") }} ({{
                selectedDocuments.length || "All"
              }})
              <v-icon right class="ml-2">mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <!-- search and price range field start -->
        <v-row>
          <v-col cols="12" md="3">
            <v-menu
              ref="menuDates"
              v-model="menuDates"
              :close-on-content-click="false"
              :return-value.sync="dates"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  :placeholder="
                    $vuetify.lang.t('$vuetify.common.daterange_placeholder_txt')
                  "
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  hide-details="auto"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dates"
                color="primary"
                no-title
                scrollable
                range
                :max="dateOfToday()"
              >
                <v-spacer></v-spacer>
                <v-btn text color="error" @click="menuDates = false">
                  {{ $vuetify.lang.t("$vuetify.common.cancel_btn") }}
                </v-btn>

                <v-btn text color="primary" @click="setDates">
                  {{ $vuetify.lang.t("$vuetify.common.ok_btn") }}
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="searchText"
              :label="$vuetify.lang.t('$vuetify.catalog.search')"
              outlined
              dense
              single-line
              hide-details
              append-icon="mdi-magnify"
              @input="debouncedFetchCatalogItems"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-range-slider
              v-model="priceRange"
              :max="maxPrice"
              :min="minPrice"
              :step="step"
              hide-details
              class="align-center"
              @change="debouncedPriceSort"
            >
              <template v-slot:prepend>
                <v-text-field
                  v-model.number="priceRange[0]"
                  class="mt-0 pt-0"
                  dense
                  outlined
                  hide-details
                  single-line
                  type="number"
                  readonly
                ></v-text-field>
              </template>
              <template v-slot:append>
                <v-text-field
                  v-model.number="priceRange[1]"
                  class="mt-0 pt-0"
                  dense
                  outlined
                  hide-details
                  single-line
                  type="number"
                  readonly
                ></v-text-field>
              </template>
            </v-range-slider>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <!-- data tables for catalog items -->
        <Loader v-if="isLoading" loader-type="table-row-divider@10" />
        <v-data-table
          v-else
          :headers="catalogItemHeaders"
          :loading="loading"
          :items="catalogTableItems"
          :items-per-page="itemsPerPage"
          :page.sync="page"
          :search="search"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :must-sort="true"
          show-select
          hide-default-footer
          @update:sort-desc="updateSort"
          v-model="selectedDocuments"
        >
          <template v-slot:[`item.item_id`]="{ item }">
            <span>{{ item.item[mapper?.item_id] }}</span>
          </template>
          <template v-slot:[`item.title`]="{ item }">
            <span>{{ item.item[mapper?.title] }}</span>
          </template>
          <template v-slot:[`item.availability`]="{ item }">
            <span>{{ item.item[mapper?.availability] }}</span>
          </template>
          <template v-slot:[`item.price`]="{ item }">
            <span>{{
              new Intl.NumberFormat().format(item.item[mapper?.price])
            }}</span>
          </template>
          <template v-slot:[`item.date`]="{ item }">
            <span>{{ formatDateTime(item.date) }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <ButtonGroup
              :groupType="ACTION_BUTTON_GROUP"
              :addButton="false"
              @onDetailsClick="detail_modal(item)"
              @onEditClick="updateItem(item)"
              :stopButton="false"
              @onDeleteClick="deleteItem(item)"
              :startButton="false"
            />
          </template>
        </v-data-table>
        <v-row>
          <v-col class="py-0" cols="12" md="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" xs="12" sm="6" md="4" class="d-flex">
            <span v-if="!isLoading" class="ma-2 font-weight-bold">
              {{ $vuetify.lang.t("$vuetify.common.page_no") }} :
              <span class="primary--text">
                {{ catalogScrollIdStack.length }} / {{ pageCount }}
              </span>
            </span>
          </v-col>
          <v-col cols="12" xs="12" sm="6" md="6" class="d-flex">
            <v-btn
              class="ma-2"
              color="primary"
              :disabled="
                boundary === 'left' || boundary === 'both' || isLoading
              "
              @click="handlePreviousPage"
            >
              <v-icon left>mdi-chevron-left</v-icon>
              Previous
            </v-btn>

            <v-btn
              class="ma-2"
              color="primary"
              :disabled="
                boundary === 'right' || boundary === 'both' || isLoading
              "
              @click="handleNextPage"
            >
              Next
              <v-icon right>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" xs="6" sm="3" md="2" class="d-flex justify-end">
            <v-select
              v-model="itemsPerPage"
              :items="rows"
              hide-details="auto"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
        <!-- edit Dialog -->
        <v-dialog v-model="openEditModal" max-width="800">
          <v-card class="d-flex flex-column">
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <h1
                              class="text-h6 text-sm-h5 text-md-h4 text-lg-h5"
                            >
                              {{
                                $vuetify.lang.t(
                                  "$vuetify.catalog.edit_catalog_items",
                                )
                              }}
                            </h1>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item
                        v-for="(value, key) in selectedItem.item"
                        :key="key"
                      >
                        <v-list-item-content>
                          <v-list-item-title class="d-flex flex-wrap">
                            <v-textarea
                              v-model="selectedItem.item[key]"
                              :label="formatKey(key)"
                              outlined
                              dense
                              hide-details
                              max-rows="8"
                              auto-grow
                              :rows="dynamicRowCount"
                              clearable
                              clear-icon="mdi-close-circle"
                              color="primary"
                              class="mt-2"
                            ></v-textarea>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="justify-end">
              <v-btn color="error" text @click="close">{{
                $vuetify.lang.t("$vuetify.common.cancel_btn")
              }}</v-btn>
              <v-btn color="primary" text @click="saveUpdatedItem">{{
                $vuetify.lang.t("$vuetify.common.save_btn")
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Detail Dialog -->
        <v-dialog v-model="openDetailModal" max-width="800">
          <v-card
            class="d-flex flex-column"
            style="overflow-y: scroll; overflow-x: hidden"
          >
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-list>
                      <v-list-item
                        v-for="(value, key) in modalContent.item"
                        :key="key"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            class="d-flex flex-wrap align-center"
                          >
                            <strong
                              class="mr-2 text-subtitle-1 text-sm-body-1 text-md-body-2 text-lg-subtitle-1 font-weight-bold"
                            >
                              {{ formatKey(key) }}:
                            </strong>
                            <div class="d-flex flex-column flex-grow-1">
                              <template v-if="imageKeys.includes(key)">
                                <v-img
                                  :src="value"
                                  class="mt-2"
                                  max-width="300"
                                  max-height="200"
                                  contain
                                ></v-img>
                              </template>
                              <template v-else-if="textKeys.includes(key)">
                                <span
                                  v-html="value"
                                  class="response-details-text text-wrap text-subtitle-1 word-break mt-1"
                                ></span>
                              </template>
                              <template v-else>
                                <span
                                  class="response-details-text text-wrap text-subtitle-1 word-break"
                                >
                                  {{
                                    Array.isArray(value)
                                      ? value.join(", ")
                                      : typeof value === "object" &&
                                          value !== null
                                        ? Object.values(value).join(", ")
                                        : value
                                  }}</span
                                >
                              </template>
                            </div>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="justify-end">
              <v-btn color="primary" text @click="close">{{
                $vuetify.lang.t("$vuetify.common.close_btn")
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
    <DialogModal
      v-model="deleteDialog"
      :title="$vuetify.lang.t('$vuetify.common.confirm_alert_title_txt')"
      :message="delete_alert_message"
      :confirmButtonText="$vuetify.lang.t('$vuetify.common.confirm_btn')"
      :cancelButtonText="$vuetify.lang.t('$vuetify.common.cancel_btn')"
      @onConfirmation="removeItems()"
      @onCancel="deleteDialog = false"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import _ from "lodash";

import {
  GET_TASK_STATUS,
  GET_CATALOG_ITEMS,
  REMOVE_CATALOG_ITEMS,
  UPDATE_CATALOG_ITEM,
  LOAD_ALL_PROJECT,
  ADD_ALERT,
} from "@/store/_actiontypes";
import { ACTION_BUTTON_GROUP } from "@/components/common/buttongroup/_buttongrouptypes";
import ButtonGroup from "@/components/common/buttongroup/ButtonGroup";
import DialogModal from "@/components/common/DialogModal";
import Loader from "@/components/common/loader/Loader";
import { IMAGE_KEYS, TEXT_KEYS } from "@/global/_constants";
import { formatDateTime, dateOfToday } from "@/helpers/helper";
export default {
  props: {
    props: {
      selectedDocuments: {
        type: Array,
        default: () => [],
      },
    },
  },
  components: {
    ButtonGroup,
    DialogModal,
    Loader,
  },

  data() {
    return {
      formatDateTime,
      ACTION_BUTTON_GROUP: ACTION_BUTTON_GROUP,
      catalogItemHeaders: [
        {
          text: this.$vuetify.lang.t("$vuetify.catalog.item_id"),
          align: "start",
          sortable: false,
          value: "item_id",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.catalog.title"),
          align: "start",
          sortable: false,
          value: "title",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.catalog.availability"),
          align: "start",
          sortable: false,
          value: "availability",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.catalog.price"),
          align: "start",
          sortable: true,
          value: "price",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.catalog.date"),
          align: "start",
          sortable: false,
          value: "date",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.table_header.actions"),
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
      intervalId: null,
      itemsPerPage: 10,
      currentPage: 1,
      openEditModal: false,
      openDetailModal: false,
      toBeDeletedProjectId: null,
      deleteDialog: false,
      catalogTableItems: [],
      selectedDocuments: [],
      menuDates: false,
      dateOfToday,
      selectedItem: {},
      minPrice: 0,
      maxPrice: 100,
      priceRange: [0, 0],
      step: 50,
      sortBy: "",
      sortDesc: true,
      dates: [],
      rows: [5, 10, 20],
      returnedHits: 0,
      dynamicRowCount: 1,
      page: 1,
      search: "",
      isLoading: false,
      searchText: null,
      start_date: null,
      end_date: null,
      imageKeys: IMAGE_KEYS,
      textKeys: TEXT_KEYS,
      modalContent: {
        item: {},
      },
      delete_alert_message: this.$vuetify.lang.t(
        "$vuetify.catalog.confirm_alert_msg_txt",
      ),
      editedItem: {
        item: {},
      },
      itemId: null,
      updateDialog: false,
      polling: null,
      inProgress: false,
      updateResult: "",
      updateStatus: "",
      updateStartedAt: "",
      boundary: "",
    };
  },

  computed: {
    ...mapState({
      selectedProject: state => state.project.selectedProject,
      dateRangeText: function () {
        if (this.dates.length === 2) {
          return this.dates.join(" ~ ");
        } else {
          return "Select Date Range";
        }
      },
      catalogItems: state => {
        return state.project.catalogItems;
      },
      pageCount(state) {
        if (state.project?.catalogItems?.detail?.response?.total_hits) {
          const count = Math.ceil(
            state.project?.catalogItems?.detail?.response?.total_hits /
              this.itemsPerPage,
          );
          return count;
        } else return 1;
      },
      catalogScrollIdStack: state => state.project.catalogScrollIdStack,
    }),
    ...mapGetters("loader", ["loading"]),
    ...mapGetters("project", ["mapper"]),
  },
  watch: {
    itemsPerPage: {
      handler(value) {
        this.$store.commit("project/CATALOG_SCROLL_ID_STACK", {
          action: "clear",
        });
        this.getCatalogItems();
      },
      immediate: false,
    },
    dates() {
      this.start_date = this.dates[0];
      this.end_date = this.dates[1];
    },
  },

  mounted() {
    this.fetchCatalogItems();
  },
  beforeCreate() {},
  created() {
    this.debouncedFetchCatalogItems = _.debounce(
      () => this.fetchCatalogItems("searchField"),
      500,
    );
    this.debouncedPriceSort = _.debounce(
      () => this.fetchCatalogItems("priceRange"),
      500,
    );
  },
  beforeDestroy() {
    this.$store.commit("project/CATALOG_SCROLL_ID_STACK", { action: "clear" });
  },

  methods: {
    ...mapActions("alert", [ADD_ALERT]),
    ...mapActions("project", [
      GET_CATALOG_ITEMS,
      REMOVE_CATALOG_ITEMS,
      UPDATE_CATALOG_ITEM,
      LOAD_ALL_PROJECT,
    ]),
    ...mapActions("task", [GET_TASK_STATUS]),
    detail_modal(data) {
      this.modalContent = data;
      this.openDetailModal = true;
    },

    close() {
      this.openEditModal = false;
      this.openDetailModal = false;
    },
    startInterval(task_id) {
      this.intervalId = setInterval(() => {
        this.GET_TASK_STATUS({
          project_id: this.$route.params.project_id,
          task_id: task_id,
        }).then(
          response => {
            if (response.data.detail.response.status === "success") {
              this.stopInterval();
              clearInterval(this.intervalId);
              this.resetTable();
              this.getCatalogItems();
            }
          },
          error => {
            this.stopInterval();
            clearInterval(this.intervalId);
            this.resetTable();
            this.alertMessage = error.message;
            this.alertDialog = true;
          },
        );
      }, 1000);
    },
    stopInterval() {
      this.is_task_running = false;
      this.selectedDocuments = [];
      clearInterval(this.intervalId);
    },
    removeItems() {
      const itemIds = this.selectedDocuments.map(document => document.id);
      const isDeleteAll = this.selectedDocuments.length === 0;

      this.REMOVE_CATALOG_ITEMS({
        project_id: this.selectedProject.id,
        item_ids: isDeleteAll ? [] : itemIds,
        is_delete_all: isDeleteAll,
      }).then(
        response => {
          if (response.status === 202) {
            this.startInterval(response.data?.detail.response);
            this.deleteDialog = false;
          }
        },
        error => {
          this.alertMessage = error.message;
          this.deleteDialog = false;
          this.alertDialog = true;
        },
      );
    },
    deleteItem(item) {
      this.selectedDocuments = [item];
      this.delete_alert_message = this.$vuetify.lang.t(
        "$vuetify.common.confirm_alert_msg_txt_7",
      );
      this.deleteDialog = true;
    },
    handleDeleteClick() {
      this.delete_alert_message = this.selectedDocuments.length
        ? this.$vuetify.lang.t("$vuetify.common.confirm_alert_msg_txt_9")
        : this.$vuetify.lang.t("$vuetify.common.confirm_alert_msg_txt_8");
      this.deleteDialog = true;
    },
    updateItem(item) {
      this.selectedItem = JSON.parse(JSON.stringify(item));
      this.openEditModal = true;
    },

    saveUpdatedItem() {
      this.isLoading = true;
      this.updateItemFunction();
    },

    updateItemFunction() {
      if (this.selectedItem && this.selectedItem.item) {
        for (let key in this.selectedItem.item) {
          if (Array.isArray(this.selectedItem.item[key])) {
            this.selectedItem.item[key] =
              this.selectedItem.item[key].join(", ");
          }
        }
      }
      this.UPDATE_CATALOG_ITEM({
        project_id: this.selectedProject.id,
        item_id: this.selectedItem.id,
        payload: this.selectedItem,
      })
        .then(() => {
          this.openEditModal = false;
          this.isLoading = false;
          this.$store.dispatch(
            `alert/${ADD_ALERT}`,
            {
              message: this.$vuetify.lang.t(
                "$vuetify.catalog.catalog_item_success_msg_txt",
              ),
              color: "success",
            },
            {
              root: true,
            },
          );
          setTimeout(() => {
            this.$store.commit("project/CATALOG_SCROLL_ID_STACK", {
              action: "pop",
            });
            this.priceRange = [0, this.max_price];
            this.fetchCatalogItems("updateItem");
          }, 1000);
        })
        .catch(error => {
          this.isLoading = false;
        });
    },
    updateSort() {
      this.fetchCatalogItems("sort");
    },
    setDates() {
      this.$refs.menuDates.save(this.dates);
      if (this.dates.length === 1) {
        this.dates[1] = dateOfToday();
        this.end_date = dateOfToday();
      }
      const start_date = this.dates[0];
      const end_date = this.dates[1];
      this.resetTable();

      this.fetchCatalogItems();
    },
    resetTable() {
      this.page = 1;
      this.searchText = "";
      this.$store.commit("project/CATALOG_SCROLL_ID_STACK", {
        action: "clear",
      });
    },
    fetchCatalogItems(source = null) {
      this.isLoading = true;
      this.page = 1;
      if (
        source === "searchField" ||
        source === "priceRange" ||
        source === "sort"
      ) {
        this.$store.commit("project/CATALOG_SCROLL_ID_STACK", {
          action: "clear",
        });
      }
      this.getCatalogItems(source);
    },
    getCatalogItems(source = null) {
      let project_id = this.selectedProject.id;
      const size = this.itemsPerPage;
      if (this.dates.length === 0) {
        let today = new Date();
        const formattedDate = today
          .toISOString()
          .split("T")[0]
          .replace(/-/g, "-");
        const sevenDaysBack = new Date(
          today.getTime() - 7 * 24 * 60 * 60 * 1000,
        )
          .toISOString()
          .split("T")[0]
          .replace(/-/g, "-");

        // Setting initial date range here
        this.dates[0] = this.selectedProject.created_at
          ? new Date(this.selectedProject.created_at)
              .toISOString()
              .split("T")[0]
              .replace(/-/g, "-")
          : sevenDaysBack;
        this.dates[1] = formattedDate;

        this.end_date = formattedDate;
      } else if (this.dates.length === 1) {
        let today = new Date();
        const formattedDate = today
          .toISOString()
          .split("T")[0]
          .replace(/-/g, "-");
        this.dates[1] = formattedDate;
        this.end_date = formattedDate;
      }
      let start_date = this.dates[0] || null;
      let end_date = this.dates[1] || null;
      const priceKey = this.mapper.price;
      const payload = {
        start_date: start_date,
        end_date: end_date,
        text: this.searchText || null,
        sort: {
          [priceKey]: this.sortDesc ? "desc" : "asc",
        },
        ranges: {
          [priceKey]: {
            gte: this.priceRange[0] || undefined,
            lte: this.priceRange[1] || undefined,
          },
        },
        size: size,
      };

      if (this.catalogScrollIdStack.length !== 0) {
        payload["scroll_id"] =
          this.catalogScrollIdStack[this.catalogScrollIdStack.length - 1];
      }

      this.GET_CATALOG_ITEMS({
        project_id: project_id,
        payload: payload,
      }).then(
        response => {
          this.catalogTableItems = response.data?.detail?.response?.items;
          const minPrice = response.data?.detail?.response?.min_price;
          const maxPrice =
            source !== "updateItem"
              ? Math.max(
                  response.data?.detail?.response?.max_price,
                  this.maxPrice,
                )
              : response.data?.detail?.response?.max_price;
          this.maxPrice = maxPrice;

          if (maxPrice > 200000) {
            this.maxPrice = this.computedMaxPrice(maxPrice);
          }

          if (source !== "priceRange") {
            this.priceRange = [0, this.maxPrice];
          }

          if (response?.data?.detail?.response?.next_scroll_id) {
            this.$store.commit("project/CATALOG_SCROLL_ID_STACK", {
              action: "push",
              value: response?.data?.detail?.response?.next_scroll_id,
            });
          }
          this.boundary =
            this.pageCount === 1
              ? "both"
              : this.catalogScrollIdStack.length === 1
                ? "left"
                : this.pageCount === this.catalogScrollIdStack.length
                  ? "right"
                  : "";

          this.isLoading = false;
        },
        error => {
          this.isLoading = false;
        },
      );
    },
    computedMaxPrice(maxPrice) {
      return Math.ceil((maxPrice + 0.0001) / this.step) * this.step;
    },
    formatKey(key) {
      return key.replace(/_/g, " ").replace(/([a-z0-9])([A-Z])/g, "$1 $2");
    },
    handleNextPage() {
      this.fetchCatalogItems();
    },
    handlePreviousPage() {
      this.$store.commit("project/CATALOG_SCROLL_ID_STACK", { action: "pop" });
      this.$store.commit("project/CATALOG_SCROLL_ID_STACK", { action: "pop" });
      this.fetchCatalogItems();
    },
  },
};
</script>

<style>
.v-data-table-header th {
  white-space: nowrap;
}

/* Following css class are vuetify class and override here for design adjustment */
.v-input__append-outer,
.v-input__prepend-outer {
  display: inline-flex;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  line-height: 1;
}
</style>
