<template>
  <div class="mt-4">
    <template v-if="isCancelBtn">
      <v-stepper v-model="e1">
        <v-stepper-header>
          <template v-for="n in steps">
            <v-stepper-step :key="`${n}-step`" :complete="e1 > n" :step="n">
              <!-- Step {{ n }} -->
              <div v-if="n === 1">
                {{$vuetify.lang.t("$vuetify.common.add_btn")}}
              </div>
              <div v-if="n === 2">
                {{$vuetify.lang.t("$vuetify.common.review")}}
              </div>
            </v-stepper-step>

            <v-divider v-if="n !== steps" :key="n"></v-divider>
          </template>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content v-for="n in steps" :key="`${n}-content`" :step="n">
            <div v-if="n === 1">
              <ValidationObserver ref="firstStep">
                <v-form ref="form1" @submit.prevent>
                  <v-row>
                    <v-col cols="12" xs="12" md="12">
                      <v-row>
                        <v-col cols="12">
                          <v-alert type="info" outlined>
                            {{ $vuetify.lang.t("$vuetify.wallet.add_account_message") }}
                          </v-alert>
                        </v-col>
                        <v-col cols="3">
                          <Tooltip
                            :label="$vuetify.lang.t('$vuetify.wallet.action_type')"
                            :message="$vuetify.lang.t('$vuetify.wallet.action_type_tooltip')" />
                        </v-col>
                        <v-col cols="9">
                          <v-btn-toggle
                            v-model="actionType"
                            mandatory
                            color="white"
                            active-class="primary"
                          >
                            <v-btn :value="'import'" text>
                              {{ $vuetify.lang.t("$vuetify.wallet.import_account") }}
                            </v-btn>
                            <v-btn :value="'create'" text>
                              {{ $vuetify.lang.t("$vuetify.wallet.create_account") }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>
                      </v-row>
                    </v-col>
                    <template v-if="actionType == 'create'">
                      <v-col cols="12" xs="12" md="12">
                        <v-row>
                          <v-col cols="3">
                            <Tooltip
                              :label="$vuetify.lang.t('$vuetify.wallet.create_account')"
                              :message="$vuetify.lang.t('$vuetify.wallet.create_account_tooltip')"
                            />
                          </v-col>
                          <v-col cols="9">
                            <v-btn-toggle
                              v-model="toggle_account_create"
                              mandatory
                              color="white"
                              active-class="primary"
                            >
                              <v-btn :value="1" text>
                                {{ $vuetify.lang.t("$vuetify.wallet.create_account_source") }}
                              </v-btn>
                              <v-btn :value="2" text>
                                {{ $vuetify.lang.t("$vuetify.wallet.create_account_without_source") }}
                              </v-btn>
                            </v-btn-toggle>
                          </v-col>
                        </v-row>
                      </v-col>
                      <template v-if="toggle_account_create === 1">
                        <v-col cols="12" xs="12" md="12">
                          <v-row>
                            <v-col cols="3">
                              <Tooltip
                                :label="$vuetify.lang.t('$vuetify.wallet.account_name')"
                                :message="$vuetify.lang.t('$vuetify.wallet.account_name_tooltip')"
                              />
                            </v-col>
                            <v-col cols="9">
                              <ValidationProvider
                                :name="$vuetify.lang.t('$vuetify.wallet.account_name')"
                                rules="required|is_numeric"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  v-model="accName"
                                  placeholder="gigalogy"
                                  outlined
                                  :error-messages="errors"
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" xs="12" md="12">
                          <v-row>
                            <v-col cols="3">
                              <Tooltip
                                :label="$vuetify.lang.t('$vuetify.wallet.source_account_secret')"
                                :message="$vuetify.lang.t('$vuetify.wallet.source_account_secret_tooltip')"
                              />
                            </v-col>
                            <v-col cols="9">
                              <ValidationProvider
                                :name="$vuetify.lang.t('$vuetify.wallet.secret_key')"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  class="mask-password"
                                  v-model="createFromSource.secretKey"
                                  placeholder="SDIYMRSWN*****************************CVFZIA"
                                  outlined
                                  :error-messages="errors"
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" xs="12" md="12">
                          <v-row>
                            <v-col cols="3">
                              <Tooltip
                                :label="$vuetify.lang.t('$vuetify.wallet.starting_balance')"
                                :message="$vuetify.lang.t('$vuetify.wallet.starting_balance_tooltip')"
                              />
                            </v-col>
                            <v-col cols="9">
                              <ValidationProvider
                                :name="$vuetify.lang.t('$vuetify.wallet.starting_balance')"
                                rules="required|min_value:1|is_decimal_allowed"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  v-model="createFromSource.startingBalance"
                                  type="number"
                                  min="0"
                                  placeholder="10,000"
                                  outlined
                                  :error-messages="errors"
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" xs="12" md="12">
                          <v-row>
                            <v-col cols="3">
                              <Tooltip
                                :label="$vuetify.lang.t('$vuetify.wallet.memo')"
                                :message="$vuetify.lang.t('$vuetify.wallet.memo_tooltip')"
                              />
                            </v-col>
                            <v-col cols="9">
                              <ValidationProvider
                                :name="$vuetify.lang.t('$vuetify.wallet.memo')"
                                rules=""
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  v-model="createFromSource.memo"
                                  :placeholder="$vuetify.lang.t('$vuetify.wallet.memo')"
                                  outlined
                                  :error-messages="errors"
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                          </v-row>
                        </v-col>
                      </template>
                      <template v-if="toggle_account_create === 2">
                        <v-col cols="12" xs="12" md="12">
                          <v-row>
                            <v-col cols="3">
                              <Tooltip
                                :label="$vuetify.lang.t('$vuetify.wallet.account_name')"
                                :message="$vuetify.lang.t('$vuetify.wallet.account_name_tooltip')"
                              />
                            </v-col>
                            <v-col cols="9">
                              <ValidationProvider
                                :name="$vuetify.lang.t('$vuetify.wallet.account_name')"
                                rules="required|is_numeric"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  v-model="accName"
                                  placeholder="gigalogy"
                                  outlined
                                  :error-messages="errors"
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                          </v-row>
                        </v-col>
                      </template>
                    </template>

                    <template v-if="actionType == 'import'">
                      <v-col cols="12" xs="12" md="12">
                        <v-row>
                          <v-col cols="3">
                            <Tooltip
                              :label="$vuetify.lang.t('$vuetify.wallet.account_name')"
                              :message="$vuetify.lang.t('$vuetify.wallet.account_name_tooltip')"
                            />
                          </v-col>
                          <v-col cols="9">
                            <ValidationProvider
                              :name="$vuetify.lang.t('$vuetify.wallet.account_name')"
                              rules="required|is_numeric"
                              v-slot="{ errors }"
                            >
                              <v-text-field
                                v-model="accName"
                                placeholder="gigalogy"
                                outlined
                                :error-messages="errors"
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" xs="12" md="12">
                        <v-row>
                          <v-col cols="3">
                            <Tooltip
                              :label="$vuetify.lang.t('$vuetify.wallet.secret_key')"
                              :message="$vuetify.lang.t('$vuetify.wallet.secret_key_tooltip')"
                            />
                          </v-col>
                          <v-col cols="9">
                            <ValidationProvider
                              :name="$vuetify.lang.t('$vuetify.wallet.secret_key')"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <v-text-field
                                class="mask-password"
                                v-model="importExistingAccount.secretKey"
                                placeholder="SDIYMRSWN*****************************CVFZIA"
                                outlined
                                :error-messages="errors"
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                      </v-col>
                    </template>
                  </v-row>
                </v-form>
              </ValidationObserver>
            </div>
            <div v-if="n === 2">
              <v-card class="elevation-0">
                <v-card-title class="font-weight-bold">
                  {{ $vuetify.lang.t("$vuetify.common.review") }}
                </v-card-title>
                <v-divider></v-divider>
                <v-list>
                  <v-list-item>
                    <v-list-item-content class="font-weight-bold">
                      {{ $vuetify.lang.t("$vuetify.wallet.action_type") }}:
                    </v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ actionType }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="font-weight-bold">
                      {{ $vuetify.lang.t("$vuetify.wallet.account_name") }}:
                    </v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ accName ? accName : "N/A" }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="toggle_account_create !== 2">
                    <v-list-item-content class="font-weight-bold">
                      {{ $vuetify.lang.t("$vuetify.wallet.secret_key") }}:
                    </v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{
                        actionType === "import"
                          ? importExistingAccount.secretKey &&
                            maskSecret(importExistingAccount.secretKey)
                          : createFromSource.secretKey &&
                            maskSecret(createFromSource.secretKey)
                      }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </div>
            <br />
            <v-row align="center" justify="start">
              <v-col cols="12">
                <v-btn
                  v-if="n !== 1"
                  color="primary"
                  class="mr-4"
                  large
                  outlined
                  :loading="false"
                  @click="previousStep(n)"
                  >
                  {{ $vuetify.lang.t("$vuetify.common.prev_btn") }}
                </v-btn
                >
                <v-btn
                  v-if="n === 1"
                  color="error"
                  class="mr-2"
                  large
                  outlined
                  :loading="false"
                  @click="goBack()">
                  {{ $vuetify.lang.t("$vuetify.common.cancel_btn") }}
                </v-btn
                >
                <v-btn
                  v-if="n === 2"
                  color="primary"
                  class="mr-2"
                  large
                  :loading="false"
                  @click="proceedAction()"
                  >
                  {{ $vuetify.lang.t("$vuetify.common.proceed") }}
                </v-btn
                >
                <v-btn
                  v-else
                  color="primary"
                  class="mr-2"
                  large
                  :loading="false"
                  @click="nextStep(n)"
                  >
                  {{ $vuetify.lang.t("$vuetify.common.continue_btn") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <!-- go to account dialog  -->
      <v-dialog v-model="accCreateDialog" persistent width="500">
        <v-card class="mx-auto pa-4">
          <v-card-text class="text-center">
            <v-icon color="success" size="80">task_alt</v-icon>
          </v-card-text>
          <v-card-text class="font-weight-bold success--text headline text-center">
            {{message}}
          </v-card-text>
          <v-card-text class="text-center">
            <v-btn color="primary" @click="gotoMyAccount">Go to Account</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- account crate fail dialog  -->
      <v-dialog v-model="accCreateFailDialog" persistent width="500">
        <v-card class="mx-auto pa-4">
          <v-card-text class="text-center">
            <v-icon color="error" size="80">mdi-close-circle-outline</v-icon>
          </v-card-text>
          <v-card-text class="font-weight-bold error--text headline text-center">
            {{errMessage}}
          </v-card-text>
          <v-card-text class="text-center">
            <v-btn color="error" @click="accCreateFailDialog = false">Close</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
    <!-- passcode dialog  -->
    <PasscodeCheck
      v-model="passcode"
      @confirm="proceedClick"
      @cancel="cancel"
      :isCancelBtn="true"
      :isLoading="isLoading"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";

import {
  ADD_WALLET_ACCOUNT,
  WALLET_CREATE_ACCOUNT_FROM_SOURCE,
  WALLET_CREATE_ACCOUNT_WITHOUT_SOURCE,
  ACCESS_WALLET,
  LOAD_ALL_WALLET_ACCOUNT,
} from "@/store/_actiontypes";
import { SET_WALLET_SELECTED_ACC } from "@/store/_mutationtypes";
import Tooltip from "@/components/common/Tooltip";

import validations from "@/helpers/validations";
import router from "@/router/index";

import { ValidationObserver, ValidationProvider } from "vee-validate";

import {
  formatNum,
  timeToNextWalletToken,
  capitalizeFirstLetter,
} from "@/helpers/helper";

import { CURRENCY } from "@/global/_constants";
import PasscodeCheck from "@/components/wallet/PasscodeCheck";

export default {
  props: {},
  components: {
    ValidationObserver,
    ValidationProvider,
    Tooltip,
    PasscodeCheck,
  },
  computed: {
    ...mapState({
      total_amount: (state) => state.payment.total_amount,
      allAccount: (state) => state.wallet.allAccount,
    }),
    ...mapGetters("loader", ["loading"]),
  },
  mounted() {},
  data() {
    return {
      toggle_account_create: 1,
      formatNum,
      timeToNextWalletToken,
      capitalizeFirstLetter,
      createCategoryDialog: false,
      e1: 1,
      steps: 2,
      CURRENCY: CURRENCY,
      ...validations,
      search: "",
      invoiceIDList: [],
      token: null,
      confirmDialog: false,
      actionType: "import",
      importExistingAccount: {
        secretKey: null,
      },
      passcode: null,
      allAction: [
        {
          id: "import",
          name: "Import",
        },
        {
          id: "create",
          name: "Create",
        },
      ],
      createItems: [
        {
          name: "Using Source Account",
          value: 1,
        },
        {
          name: "Without Source Account",
          value: 2,
        },
      ],
      createFromSource: {
        secretKey: "",
        startingBalance: "",
        memo: "",
      },
      accName: "",
      showPasscode: false,
      passType: true,
      isLoading: false,
      accCreateDialog: false,
      accCreateFailDialog: false,
      public_key: "",
      secret_key: "",
      secret_key_mod: "",
      public_key_copy: false,
      isCancelBtn: true,
      message: null,
      errMessage: null,
    };
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
  },
  methods: {
    ...mapActions("wallet", [
      ADD_WALLET_ACCOUNT,
      WALLET_CREATE_ACCOUNT_FROM_SOURCE,
      WALLET_CREATE_ACCOUNT_WITHOUT_SOURCE,
      ACCESS_WALLET,
      LOAD_ALL_WALLET_ACCOUNT,
    ]),
     ...mapMutations("wallet", [SET_WALLET_SELECTED_ACC]),
    proceedAction() {
      this.$store.commit("wallet/toggleModal", true);
    },
    proceedClick() {
      if (this.actionType === "import") {
        this.importAccount();
      } else if (
        this.actionType === "create" &&
        this.toggle_account_create === 1
      ) {
        this.createAccount();
      } else {
        this.createAccWithoutActivation();
      }
    },
    getAccess() {
      this.$refs.passcodeConfirm.validate().then((success) => {
        if (!success) {
          return;
        }
        this.ACCESS_WALLET({
          passcode: this.passcode,
        })
          .then((res) => {
            this.passcodeDialog = false;
            this.isCancelBtn = true;
            this.passcode = null;
          })
          .catch((err) => {});
      });
    },
    cancelClick() {
      this.passcodeDialog = false;
    },
    importAccount() {
      this.isLoading = true;
      let body_json = {
        account_name: this.accName,
        secret_key: this.importExistingAccount.secretKey,
        passcode: this.passcode,
      };
      this.ADD_WALLET_ACCOUNT({
        body_json: body_json,
      }).then(
        (res) => {
          this.message = res.data.message;
          let publicKey = res.data.public_key;
          this.public_key = publicKey;
          this.LOAD_ALL_WALLET_ACCOUNT({}).then((res) => {
            this.$store.commit("wallet/toggleModal", false);
            this.passcode = null;
            this.isLoading = false;
            this.accCreateDialog = true;
          }).catch((err) => {
            this.$store.commit("wallet/toggleModal", false);
            this.passcode = null;
            this.isLoading = false;
            })
        },
        (err) => {
          this.errMessage = err.response.data?.message;
          this.isLoading = false;
          this.accCreateFailDialog = true;
          this.$store.commit("wallet/toggleModal", false);
          this.passcode = null;
        }
      );
    },
    createAccount() {
      this.isLoading = true;
      let body_json = {
        account_name: this.accName,
        source_secret: this.createFromSource.secretKey,
        starting_balance: this.createFromSource.startingBalance,
        ...(this.createFromSource.memo
          ? { memo: this.createFromSource.memo }
          : {}),
        passcode: this.passcode,
      };
      this.WALLET_CREATE_ACCOUNT_FROM_SOURCE({
        body_json: body_json,
      }).then(
        (res) => {
          this.message = res.data.message;
          let publicKey = res.data.public_key;
          this.public_key = publicKey;
          this.LOAD_ALL_WALLET_ACCOUNT({}).then((res) => {
            this.$store.commit("wallet/toggleModal", false);
            this.passcode = null;
            this.isLoading = false;
            this.accCreateDialog = true;
          }).catch((err) => {
            this.$store.commit("wallet/toggleModal", false);
            this.passcode = null;
            this.isLoading = false;
          })
        },
        (err) => {
          this.errMessage = err.response.data?.message;
          this.isLoading = false;
          this.accCreateFailDialog = true;
          this.$store.commit("wallet/toggleModal", false);
          this.passcode = null;
        }
      );
    },
    createAccWithoutActivation() {
      this.isLoading = true;
      let body_json = {
        account_name: this.accName,
        passcode: this.passcode,
      };
      this.WALLET_CREATE_ACCOUNT_WITHOUT_SOURCE({
        body_json: body_json,
      }).then(
        (res) => {
          this.message = res.data.message;
          let publicKey = res.data.public_key;
          this.public_key = publicKey;
          this.LOAD_ALL_WALLET_ACCOUNT({}).then((res) => {
            this.$store.commit("wallet/toggleModal", false);
            this.passcode = null;
            this.isLoading = false;
            this.accCreateDialog = true;
          }).catch((err) => {
            this.$store.commit("wallet/toggleModal", false);
            this.passcode = null;
            this.isLoading = false;
          })
        },
        (err) => {
          this.errMessage = err.response.data?.message;
          this.isLoading = false;
          this.$store.commit("wallet/toggleModal", false);
          this.passcode = null;
          this.accCreateFailDialog = true;
        }
      );
    },
    gotoMyAccount() {
      let newAcc = this.allAccount.filter((item) => item.public_key === this.public_key)[0]
      this.SET_WALLET_SELECTED_ACC(newAcc);
      this.accCreateDialog = false;
      router.push('/wallet')
    },
    goBack() {
      history.back();
    },
    nextStep(n) {
      if (n === 1) {
        this.$refs[`firstStep`][0].validate().then((success) => {
          if (!success) {
            return;
          }
          this.e1 = n + 1;
        });
      }
    },
    previousStep(n) {
      this.e1 = n - 1;
    },
    maskSecret(str) {
      return `${str.substring(0, 1)}${"*".repeat(str.length - 1)}`;
    },
    cancel() {
      this.$store.commit("wallet/toggleModal", false);
      this.passcode = null;
    },
  },
};
</script>
