<template>
  <v-main>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col align-self="center" sm="8" md="6" lg="4" xl="4">
          <v-card outlined class="elevation-2 mx-auto pa-4">
            <Logo />
            <v-card-title class="font-weight-bold">
              {{ $vuetify.lang.t("$vuetify.registration.forgot_password_msg_txt_1") }}
            </v-card-title>
            <v-card-subtitle>
              {{ $vuetify.lang.t("$vuetify.registration.forgot_password_msg_txt_2") }}
            </v-card-subtitle>
            <v-card-text>
              <v-form ref="emailForm">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.registration.email_label_txt')"
                  type="email"
                  v-model="emailForm.email"
                  placeholder="adam@gigalogy.com"
                  prepend-inner-icon="email"
                  outlined
                  :rules="[
                    required($vuetify.lang.t('$vuetify.registration.email_label_txt')),
                    email($vuetify.lang.t('$vuetify.registration.email_label_txt')),
                  ]"
                ></v-text-field>
                <v-btn
                  color="primary"
                  large
                  block
                  @click="handleSubmitClick()"
                  :loading="loading"
                  >{{ $vuetify.lang.t("$vuetify.common.reset_btn") }}</v-btn
                >
              </v-form>
              <v-divider class="my-5"></v-divider>
              <router-link to="/login" class="text-decoration-none">
                {{ $vuetify.lang.t("$vuetify.registration.login_link_txt") }}
              </router-link>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import getEnv from "@/config/env";
import { SEND_PASSWORD_RESET_LINK } from "@/store/_actiontypes";
import validations from "@/helpers/validations";
import Logo from "@/components/common/logo";

export default {
  components: {
    Logo,
  },
  data() {
    return {
      emailForm: {
        email: "",
      },
      ...validations,
    };
  },
  computed: {
    ...mapState({
      loading: state => state.loader.loading,
    }),
  },
  methods: {
    ...mapActions("account", [SEND_PASSWORD_RESET_LINK]),
    async handleSubmitClick() {
      if (!this.$refs.emailForm.validate()) return;

      const clientIP = await this.$ipCheck.getClientIP();
      const isAllowed = await this.$ipCheck.ipRangeCheck(clientIP);

      if (getEnv("VUE_APP_CAPTCHA_ENABLED") && !isAllowed) {
        this.$recaptcha
          .execute("reset_password")
          .then(recaptcha_token => {
            this.handleSubmit(recaptcha_token, clientIP);
          })
          .catch(error => {
            // handle error
            console.error("reCaptcha error:", error);
          });
      } else {
        this.handleSubmit(null, clientIP);
      }
    },
    handleSubmit(recaptcha_token, clientIP) {
      const { email } = this.emailForm;
      let reset_page_route = "reset-password/";
      // FIXME this api call doesn't looks perfect as on successful response
      //  we should reset the form field but this doesn't happen actually.
      this.SEND_PASSWORD_RESET_LINK({
        email,
        reset_page_route,
        recaptcha_token,
        clientIP,
      })
        .then(res => {})
        .catch(err => {});
    },
  },
};
</script>

<style></style>
