<template>
  <div>
    <v-container fill-height fluid>
      <v-row>
        <v-col class="pa-0" xs="12" md="12">
          <v-card class="mx-auto px-2" outlined>
            <v-row>
              <v-col md="6">
                <div class="px-2 primary--text font-weight-bold">
                  {{ $vuetify.lang.t("$vuetify.gpt_dataset.dataset_header_txt") }}
                </div>
              </v-col>
              <v-col md="6">
                <v-btn
                    class="float-right text-capitalize"
                    @click="createNewDatasetClick()"
                    color="primary"
                    dark
                >
                  <span class="hidden-sm-and-down">
                    <v-icon left>mdi-plus-circle-outline</v-icon>
                    {{ $vuetify.lang.t("$vuetify.gpt_dataset.create_dataset") }}
                  </span>
                  <span class="hidden-md-and-up">
                    <v-icon>mdi-plus-circle-outline</v-icon>
                  </span>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" xs="12" md="4">
                <v-text-field v-model="search" append-icon="mdi-magnify"
                  :label="$vuetify.lang.t('$vuetify.gpt_dataset.search_btn_txt')" outlined dense single-line
                  hide-details @change="handleQueryOnDatasets" ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" md="3">
                <v-select multiple chips v-model="selected_dataset_tags" :items="datasetTags" item-text="state" item-value="value" persistent-hint :placeholder="$vuetify.lang.t('$vuetify.gpt_profile.dataset_tags')"
                  hide-details="auto" outlined dense @change="handleQueryOnDatasets"></v-select>
              </v-col>
              <v-col cols="12" xs="12" md="3">
                <v-select v-model="selected_dataset_status" :items="states" item-text="state" item-value="value" persistent-hint
                  hide-details="auto" outlined dense @change="handleQueryOnDatasets"></v-select>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-data-table
              :headers="headers"
              :items="gptDatasets"
              :options.sync="options"
              :server-items-length="pageCount" 
              hide-default-footer
              :loading="isLoading"
              loading-text=""
            >
              <template v-slot:progress>
                <Loader class="mr-2 mt-6"  />
              </template>
              <template v-slot:[`item.dataset_id`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ item.dataset_id.split("-")[0] }}</span>
                  </template>
                  <span>{{ item.dataset_id }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <div>
                  <v-btn text class="primary--text text--darken-3" @click="detailsModal(item)">{{ item.name }}</v-btn>
                </div>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                {{ formatDateTime(item.created_at) }}
              </template>
              <template v-slot:[`item.updated_at`]="{ item }">
                {{ formatDateTime(item.updated_at) }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <!-- <v-icon>mdi-information</v-icon> -->

                <ButtonGroup class="d-flex justify-center" :groupType="ACTION_BUTTON_GROUP" :addButton="false"
                   :stopButton="false" :documentButton="true" :trainButton="true" :startButton="false"

                  :disabledDeleteButton="disable_crud_operation" :disabledEditButton="disable_crud_operation"
                  @onDetailsClick="detailsModal(item)" @onEditClick="updateItem(item)"
                  @onDocumentClick="redirectDocument(item)" @onDeleteClick="deleteItem(item)" 
                  @onTrainClick="skipForm(item)"
                />
              </template>
            </v-data-table>
            <v-row>
              <v-col class="py-0" cols="12" md="12">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" xs="12" sm="3" md="3"></v-col>
              <v-col cols="12" xs="12" sm="6" md="6">
                <v-pagination class="text-center" v-model="page" :length="pageCount"></v-pagination>
              </v-col>
              <v-col cols="12" offset-sm="1" offset-md="1" xs="3" sm="2" md="2">
                <v-select v-model="itemsPerPage" :items="rows" hide-details="auto" outlined dense></v-select>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <template v-if="detailsDialog">
      <v-dialog v-model="detailsDialog" max-width="800px" persistent scrollable hide-overlay
        transition="dialog-bottom-transition" content-class="rounded-lg" elevation="24" rounded>
        <v-card class="rounded-lg" elevation="24" rounded v-if="!detailsLoader">
          <v-card-title>
            <div>
              <span class="headline text-capitalize">{{ dataset_summary.name }}</span>
              <span class="caption d-block">{{ dataset_summary.dataset_id }}
                <v-icon color="primary" @click="redirectDocument({
                  dataset_id: dataset_summary.dataset_id
                })">mdi-file-document</v-icon>
              </span>
            </div>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <div>
                  <strong>{{ $vuetify.lang.t("$vuetify.gpt_dataset.description_label_txt") }}:</strong> {{ dataset_summary.description || 'No description available' }}
                </div>
                <div>
                  <strong>{{ $vuetify.lang.t("$vuetify.table_header.created_at") }}:</strong> {{ new Date(dataset_summary.created_at).toLocaleString() }}
                </div>
                <div>
                  <strong>{{ $vuetify.lang.t("$vuetify.gpt_dataset.document_count") }}:</strong>
                  <ul>
                    <li>{{ $vuetify.lang.t("$vuetify.gpt_dataset.total_label_txt") }}: {{ dataset_summary.documents_count.total }}</li>
                    <li>{{ $vuetify.lang.t("$vuetify.dashboard.active") }}: {{ dataset_summary.documents_count.active }}</li>
                    <li>{{ $vuetify.lang.t("$vuetify.gpt_dataset.text_trained") }}: {{ dataset_summary.documents_count.text_trained }}</li>
                    <li>{{ $vuetify.lang.t("$vuetify.gpt_dataset.image_trained") }}: {{ dataset_summary.documents_count.image_trained }}</li>
                  </ul>
                </div>
                <div>
                  <strong>{{ $vuetify.lang.t("$vuetify.gpt_dataset.index_column_name") }}:</strong> {{ dataset_summary.idx_column_name }}
                </div>
                <div>
                  <strong>{{ $vuetify.lang.t("$vuetify.gpt_dataset.image_url_column") }}:</strong> {{ dataset_summary.image_url_column || 'Not available' }}
                </div>
                <div>
                  <strong>{{ $vuetify.lang.t("$vuetify.gpt_dataset.secondary_index_column") }}:</strong> {{ dataset_summary.secondary_idx_column || 'Not available' }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn text color="error" @click="detailsDialog = false">
              {{ $vuetify.lang.t("$vuetify.common.close_btn") }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <Loader v-else />
      </v-dialog>
    </template>

    <template v-if="is_task_running">
      <v-dialog v-model="is_task_running" max-width="400px" persistent
        transition="dialog-bottom-transition" content-class="rounded-lg" elevation="24" rounded>
        <v-card class="rounded-lg" elevation="24" rounded>
          <v-card-title>
            <div>
              <span class="headline text-capitalize">
                Deleting Dataset
              </span>
            </div>
          </v-card-title>
          <v-card-text>
            <v-list-item>

              <v-list-item-icon>
                <v-progress-circular indeterminate color="primary">
                </v-progress-circular>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $vuetify.lang.t("$vuetify.gpt_dataset.delete_dataset_progress_txt") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn text color="error" @click="is_task_running = false">
              {{ $vuetify.lang.t("$vuetify.common.close_btn") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>


    <DialogModal v-model="deleteDialog" :title="$vuetify.lang.t('$vuetify.common.confirm_alert_title_txt')"
      :message="$vuetify.lang.t('$vuetify.gpt_dataset.confirm_alert_msg_txt_1')"
      :confirmButtonText="$vuetify.lang.t('$vuetify.common.confirm_btn')"
      :cancelButtonText="$vuetify.lang.t('$vuetify.common.cancel_btn')" @onConfirmation="deleteDataset()"
      @onCancel="deleteDialog = false" />

    <DialogModal v-model="alertDialog" :title="$vuetify.lang.t('$vuetify.common.error_title_txt')" :message="alertMessage"
      :confirmButtonText="$vuetify.lang.t('$vuetify.common.ok_btn')" :cancelButton="false"
      @onConfirmation="alertDialog = false" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import {
  FLOATING_ACTION_BUTTON,
  RAISED_BUTTON,
  TEXT_BUTTON,
} from "@/components/common/button/_buttontypes";

import { ACTION_BUTTON_GROUP } from "@/components/common/buttongroup/_buttongrouptypes";

import {
  GET_GPT_DATASETS,
  TERMINATE_GPT_DATASET,
  UPDATE_GPT_DATASET_STATE,
  GET_GPT_DATASETS_SUMMARY,
  GET_TASK_STATUS,
} from "@/store/_actiontypes";

import { AUTO_COMPLETE, TEXT } from "@/components/common/input/_inputTypes";

import validations from "@/helpers/validations";
import { authorize } from "@/helpers/helper";
import {formatDateTime} from "@/helpers/formatter";
import { Role } from "@/helpers/role";

import ButtonGroup from "@/components/common/buttongroup/ButtonGroup";
import DialogModal from "@/components/common/DialogModal";
import Loader from "@/components/common/loader/Loader";
import getEnv from "@/config/env";


export default {
  props: {
    solution_type: {
      type: String
    },
  },
  components: {
    ButtonGroup,
    DialogModal,
    Loader,
  },
  computed: {
    ...mapState({
      resourceMetadataByType: (state) => state.resource_metadata.resourceMetadataByType,
    }),
    ...mapGetters("loader", ["loading"]),
  },
  watch: {
    page() {
      this.getAllDataset();
    },
    itemsPerPage() {
      if (this.page === 1) {
        this.getAllDataset();
      } else {
        this.page = 1;
      }
    },
    options: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal.sortBy.length !== 0) {
          this.sortDesc = {
            [newVal.sortBy[0]]: newVal.sortDesc[0] ? "desc" : "asc",
          };
        }
        else {
          this.sortDesc = null;
        }
        this.getAllDataset();
      },
      deep: true,
    },
  },
  mounted() {
    this.disableCrudOperation();
  },
  beforeDestroy() {
    this.stopInterval();
  },
  data() {
    return {
      gptDatasets: [],
      options:{},
      datasetTags: [],
      formatDateTime,
      disable_crud_operation: false,
      isLoading: false,
      showPassword: false,
      TEXT: TEXT,
      AUTO_COMPLETE: AUTO_COMPLETE,
      FLOATING_ACTION_BUTTON: FLOATING_ACTION_BUTTON,
      TEXT_BUTTON: TEXT_BUTTON,
      RAISED_BUTTON: RAISED_BUTTON,
      ACTION_BUTTON_GROUP: ACTION_BUTTON_GROUP,
      dialog: false,
      detailsDialog: false,
      detailsLoader: false,
      dataset_summary: {},
      valid: true,
      projectName: "",
      type: "",
      language: "",
      deleteDialog: false,
      stopDialog: false,
      startDialog: false,
      alertDialog: false,
      alertMessage: "Unknown error occurs",
      edit: false,
      ...validations,
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.table_header.id_header_txt"),
          align: "center",
          sortable: false,
          value: "dataset_id",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.table_header.name_header_txt"),
          align: "center",
          sortable: false,
          value: "name",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.table_header.created_at"),
          align: "center",
          sortable: true,
          value: "created_at",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.table_header.updated_at"),
          align: "center",
          sortable: true,
          value: "updated_at",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.table_header.actions"),
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      sortDesc: null,
      toBeDeletedDatasetId: null,
      toBeUpdatedDatasetId: null,
      toShowDatasetId: null,
      projectDetails: {},
      selected_dataset_status: "",
      selected_dataset_tags: [],
      states: [
        { state: this.$vuetify.lang.t("$vuetify.gpt_dataset.dataset_filter_menu.all"), value: "" },
        { state: this.$vuetify.lang.t("$vuetify.gpt_dataset.dataset_filter_menu.idx_column_auto_filled"), value: "true" },
        { state: this.$vuetify.lang.t("$vuetify.gpt_dataset.dataset_filter_menu.idx_column_manually_filled"), value: "false" }
      ],
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      rows: [5, 10, 20, 30, 40, 50],
      intervalId: null,
      is_task_running: false,
    };
  },

  methods: {
    ...mapActions("project", [
      GET_GPT_DATASETS,
      TERMINATE_GPT_DATASET,
      UPDATE_GPT_DATASET_STATE,
      GET_GPT_DATASETS_SUMMARY,
    ]),
    ...mapActions("task", [GET_TASK_STATUS]),

    updateItem(item) {
      this.$router.push({
        path: this.$route.params.project_id + "/update-dataset/" + item.dataset_id,
        query: {
          skipFirstStep: false, // Pass the flag as a query parameter(optional for false)
        },
      });
    },
    skipForm(item){
      this.$router.push({
        path: this.$route.params.project_id + "/update-dataset/" + item.dataset_id,
        query: {
          skipFirstStep: true, // Pass the flag as a query parameter to skip stepper form 1
        },
      });
    },
    
    handleSort(items, index, isDescending) {
      if(index[0]===this.sortBy && isDescending[0] !== this.sortDesc){
        this.sortDesc = isDescending[0];
        this.getAllDataset();
      }
      return this.gptDatasets;
    },
    handleQueryOnDatasets(){
      if(this.page === 1) {
        this.getAllDataset();
      }else{
        this.page = 1;
      }
    },
    getAllDataset() {
      this.isLoading = true;
      this.gptDatasets = [];
      this.GET_GPT_DATASETS({
        project_id: this.$route.params.project_id,
        keyword: this.search,
        sort: this.sortDesc,
        is_idx_fillup_if_empty: this.selected_dataset_status,
        tags: this.selected_dataset_tags,
        start: (this.page - 1) * this.itemsPerPage,
        size: this.itemsPerPage,
      }).then(
        (response) => {
          this.gptDatasets = response.data?.datasets || [];
          this.datasetTags = response.data?.dataset_tags || [];
          let total_hits = response.data?.total_hits || 0;
          let returned_hits = response.data?.returned_hits || 0;
          this.pageCount = Math.ceil(total_hits / this.itemsPerPage) || 1;
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    },
    startInterval(task_id) {
      this.intervalId = setInterval(() => {
        this.GET_TASK_STATUS({
          project_id: this.$route.params.project_id,
          task_id: task_id,
        }).then(
          (response) => {
            if (response.data.detail.response.status === "success") {
              this.stopInterval();
              clearInterval(this.intervalId);
              this.getAllDataset();
            }
          },
          (error) => {
            this.stopInterval();
            clearInterval(this.intervalId);
            this.alertMessage = error.message;
            this.alertDialog = true;
          }
        );
      }, getEnv("VUE_APP_POLLING_INTERVAL"));
    },
    stopInterval() {
      this.is_task_running = false;
      clearInterval(this.intervalId);
    },
    createNewDatasetClick() {
      this.$router.push(this.$route.params.project_id + "/create-dataset");
    },
    deleteDataset() {
      this.TERMINATE_GPT_DATASET({
        project_id: this.$route.params.project_id,
        dataset_id: this.toBeDeletedDatasetId
      }).then(
        (response) => {
          if (response.status === 202) {
            this.is_task_running = true;
            this.startInterval(response.data?.detail.task_id);
            this.deleteDialog = false;
          }
        },
        (error) => {
          this.alertMessage = error.message;
          this.deleteDialog = false;
          this.alertDialog = true;
        }
      );
    },
    detailsModal(item) {
      this.toshowDatasetId = item.dataset_id;
      this.detailsDialog = true;
      this.detailsLoader = true;
      this.GET_GPT_DATASETS_SUMMARY({
        project_id: this.$route.params.project_id,
        dataset_id: item.dataset_id
      }).then(
        (response) => {
          this.dataset_summary = response.data?.response.dataset;
          this.detailsLoader = false;
        },
        (error) => {
          this.alertMessage = error.message;
          this.alertDialog = true;
          this.detailsDialog = false;
          this.detailsLoader = false;
        }
      );
    },
    redirectDocument(item) {
      this.$router.push(this.$route.params.project_id + "/datasets/" + item.dataset_id + "/documents");
    },
    deleteItem(item) {
      this.deleteDialog = true;
      this.toBeDeletedDatasetId = item.dataset_id;
    },
    disableCrudOperation() {
      if (authorize([Role.USER])) {
        this.disable_crud_operation = true;
      }
    }
  },
};
</script>

<style></style>
