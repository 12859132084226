<template>
  <div>
    <v-container fill-height fluid>
      <v-row>
        <v-col class="pa-0" xs="12" md="12">
          <v-card outlined class="mx-auto px-2">
            <v-row>
              <v-col cols="6" md="6">
                <div class="px-2 primary--text font-weight-bold">
                  {{ $vuetify.lang.t("$vuetify.wallet.buy_history") }}
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="6" md="3">
                <div class="d-block float-right">
                  <v-btn color="primary" @click="buyClick()"
                    >{{ $vuetify.lang.t("$vuetify.wallet.buy_xlm_btn") }}</v-btn
                  >
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <!-- integrate after get invoice data  -->
            <Loader v-if="isLoading" class="mr-2 mt-6" />
            <!-- invoice here  -->
            <v-data-table
              v-else
              :headers="headers"
              :items="allBuyHistoryByAccount.items"
              :search="search"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              hide-default-footer
              @page-count="pageCount = $event"
            >
              <template v-slot:[`item.wyre_created_at`]="{ item }">
                {{
                  new Date(item.wyre_created_at).toLocaleDateString("en-us", {
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })
                }}
              </template>
              <template v-slot:[`item.purchase_amount`]="{ item }">
                <v-chip
                    class="text-capitalize"
                    outlined
                    small
                    color="primary"
                  >
                    {{ item.purchase_amount + " " + item.source_currency }}
                </v-chip>
              </template>
              <template v-slot:[`item.source_amount`]="{ item }">
                <v-chip
                    class="text-capitalize"
                    outlined
                    small
                    color="primary"
                  >
                    {{ item.source_amount + " " + item.source_currency }}
                </v-chip>
              </template>
              <template v-slot:[`item.dest_amount`]="{ item }">
                <v-chip
                    class="text-capitalize"
                    outlined
                    small
                    color="primary"
                  >
                    {{ item.dest_amount + " " + item.dest_currency }}
                </v-chip>
              </template>
              <template v-slot:[`item.transaction_fee`]="{ item }">
                <v-chip
                    class="text-capitalize"
                    outlined
                    small
                    color="primary"
                  >
                    {{ item.transaction_fee + " " + item.source_currency }}
                </v-chip>
              </template>
              <template v-slot:[`item.rate`]="{ item }">
                <v-chip
                    class="text-capitalize"
                    outlined
                    small
                    color="primary"
                  >
                    {{ item.rate }}
                </v-chip>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  class="text-capitalize"
                  outlined
                  small
                  :color="`${
                    item.status === 'COMPLETE' ? 'primary' : 'orange'
                  }`"
                >
                  {{ item.status }}
                </v-chip>
              </template>
              <template v-slot:[`item.transfer_id`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="handleTrackClick(item)"
                      :href="`${wyreTrackUrl}track/${item.transfer_id}`" 
                      target="_blank"
                    > 
                      {{ $vuetify.lang.t("$vuetify.wallet.track") }}
                    </v-btn>
                  </template>
                  <span>{{ $vuetify.lang.t("$vuetify.wallet.track_buy") }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
            <div v-if="!allBuyHistoryByAccount.total"></div>
            <v-row v-else>
              <v-col class="py-0" cols="12" md="12">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" xs="12" sm="3" md="3"></v-col>
              <v-col cols="12" xs="12" sm="6" md="6">
                <v-pagination
                  class="text-center"
                  v-model="page"
                  :length="Math.ceil(allBuyHistoryByAccount.total / itemsPerPage)"
                  @input="handlePageChange"
                ></v-pagination>
              </v-col>
              <v-col cols="12" offset-sm="1" offset-md="1" xs="3" sm="2" md="2">
                <v-select
                  v-model="itemsPerPage"
                  :items="rows"
                  hide-details="auto"
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="minBuyAmountModal" max-width="500" persistent>
      <v-card>
        <v-card-text>
          <p class="pt-10 font-weight-bold red--text text-center subtitle-1">
            <v-icon color="error">mdi-alert</v-icon>
            {{ this.$vuetify.lang.t('$vuetify.wallet.minimum_buy_amount_text').replace('{_source_currency_}', this.source_currency) }} {{minimum_buy_amount}}.
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="minBuyAmountModal=false">
            {{ $vuetify.lang.t("$vuetify.common.ok_btn") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="buyModal" max-width="900" persistent>
      <v-card>
        <v-card-title class="font-weight-bold"> {{ $vuetify.lang.t("$vuetify.wallet.buy_lumens_txt") }} </v-card-title>
        <v-card-subtitle class="font-weight-bold text-subtitle-1">
          {{ $vuetify.lang.t("$vuetify.wallet.buy_lumens_by_credit_card") }}
        </v-card-subtitle>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="3" md="3">
              <ValidationProvider
                :name="$vuetify.lang.t('$vuetify.wallet.asset')"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  class="mr-2 mr-md-3"
                  v-model="dest_curr"
                  :items="allAsset"
                  :label="$vuetify.lang.t('$vuetify.wallet.asset')"
                  item-text="name"
                  item-value="name"
                  outlined
                  :error-messages="errors"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      color="white"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      @click="data.select"
                    >
                      <v-avatar left>
                        <v-img :src="data.item.img"></v-img>
                      </v-avatar>
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-avatar>
                        <img :src="data.item.img" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.name"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-select>
              </ValidationProvider>
            </v-col>
            <v-col cols="3" md="3">
              <ValidationProvider
                :name="$vuetify.lang.t('$vuetify.wallet.currency')"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  class="mr-3"
                  v-model="source_currency"
                  :items="allFiatCurrencies"
                  :label="$vuetify.lang.t('$vuetify.wallet.currency')"
                  item-text="name"
                  item-value="value"
                  outlined
                  :error-messages="errors"
                >
                </v-select>
              </ValidationProvider>
            </v-col>
            <v-col cols="3" md="3">
              <ValidationProvider
                :name="$vuetify.lang.t('$vuetify.wallet.amount')"
                :rules="{
                  required: true,
                }"
                v-slot="{ errors }"
              >
                <vuetify-money
                  class="mr-3"
                  v-model="buy_amount"
                  :label="$vuetify.lang.t('$vuetify.wallet.amount')"
                  :placeholder="`${$vuetify.lang.t('$vuetify.wallet.minimum_buy')} ${source_currency} ${minimum_buy_amount}`"
                  outlined
                  clearable
                  value-when-is-empty=""
                  :error-messages="errors"
                  :options="numberOptions"
                  v-bind:properties="{hint:`${$vuetify.lang.t('$vuetify.wallet.minimum_buy')} ${source_currency} ${minimum_buy_amount}`}"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="3" md="3">
              <ValidationProvider
                :name="$vuetify.lang.t('$vuetify.wallet.gateway')"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  class="md-3"
                  v-model="dest_gateway"
                  :items="allGateway"
                  :label="$vuetify.lang.t('$vuetify.wallet.gateway')"
                  item-text="name"
                  item-value="value"
                  outlined
                  :error-messages="errors"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      color="white"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      @click="data.select"
                    >
                      <v-avatar left>
                        <v-img :src="data.item.img"></v-img>
                      </v-avatar>
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-avatar>
                        <img :src="data.item.img" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.name"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-select>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <v-progress-linear
                v-if="applicableRewardInfoLoading"
                color="primary lighten-2"
                indeterminate
                rounded
                height="5"
                class="mt-0 mb-2"
              ></v-progress-linear>
              <template>
                <div>
                  <v-alert
                    v-show="applicable_reward_percentage > 0"
                    dense
                    text
                    type="success"
                    icon="mdi-gift"
                    class="pl-6"
                  >
                    {{ this.$vuetify.lang.t('$vuetify.wallet.dzt_holding_balance_text') }} <strong>{{ holding_balance }}</strong>.
                    <br>
                    {{ this.$vuetify.lang.t('$vuetify.wallet.dzt_reward_percentage_text_1') }}
                    <strong>{{ applicable_reward_percentage }}%</strong>
                    {{ this.$vuetify.lang.t('$vuetify.wallet.dzt_reward_percentage_text_2') }}
                  </v-alert>
                  <v-alert
                    v-show="applicable_reward_percentage <= 0"
                    dense
                    text
                    type="warning"
                    icon="mdi-gift-off"
                    class="pl-6"
                  >
                    {{ this.$vuetify.lang.t('$vuetify.wallet.dzt_holding_balance_text') }} <strong>{{ holding_balance }}</strong>.
                    <br>{{ this.$vuetify.lang.t('$vuetify.wallet.dzt_increase_text') }}
                  </v-alert>
                </div>
              </template>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <template>
                <div>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <p class="ma-0 font-weight-bold text--secondary subtitle-2">
                          <v-icon color="info">
                            mdi-alert-circle
                          </v-icon>
                          {{ this.$vuetify.lang.t('$vuetify.wallet.reward_percentage_details') }}
                        </p>
                        <template v-slot:actions>
                          <v-icon color="primary">
                            $expand
                          </v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-data-table
                          :headers="reward_percentage_headers"
                          :items="reward_percentages"
                          dense
                          sortBy="percentage"
                          fixed-header
                          height="150px"
                          hide-default-footer
                          disable-pagination
                        ></v-data-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn color="error" :disabled="buyConfirmLoading" @click="cancelBuy()">
            {{ $vuetify.lang.t("$vuetify.common.cancel_btn") }}
          </v-btn>
          <v-btn
            class="mr-2"
            color="primary"
            :disabled="applicableRewardInfoLoading"
            @click="continueBuy()"
            :loading="buyConfirmLoading"
            >{{ $vuetify.lang.t("$vuetify.common.continue_btn") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import {
  LOAD_WALLET_BUY_HISTORY_BY_ACCOUNT,
  LOAD_WALLET_REWARD_PERCENTAGES,
  LOAD_WALLET_APPLICABLE_REWARD_INFO,
  WALLET_BUY_ASSET,
  UPDATE_WALLET_WYRE_ORDER,
} from "@/store/_actiontypes";
import validations from "@/helpers/validations";
import Loader from "@/components/common/loader/Loader";
import {
  formatNum,
  timeToNextWalletToken,
  capitalizeFirstLetter,
} from "@/helpers/helper";
import { CURRENCY } from "@/global/_constants";
import wyre from "@/assets/images/wyre.svg";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { NUMBER_FORMAT_LOCALE } from "@/global/_constants";
import getEnv from "@/config/env";

export default {
  props: {
    item: {
      type: Object,
    },
  },
  components: {
    Loader,
    ValidationProvider,
  },
  computed: {
    ...mapState({
      allBuyHistoryByAccount: (state) => state.wallet.allBuyHistoryByAccount,
      dateRangeText: function () {
        return this.dates.join(" ~ ");
      },
    }),
    ...mapGetters("loader", ["loading"]),
  },
  destroyed() {
    if(Object.keys(this.interval).length > 0){
      for (let i in this.interval) {
        clearInterval(this.interval[i])
      }
    }
  },
  data() {
    return {
      isLoading: false,
      timeToNextWalletToken,
      page: 1,
      search: "",
      pageCount: 0,
      itemsPerPage: 10,
      rows: [5, 10, 20],
      ...validations,
      headers: [
        {
          text: this.$vuetify.lang.t('$vuetify.common.date'),
          align: "start",
          sortable: true,
          value: "wyre_created_at",
        },
        {
          text: this.$vuetify.lang.t('$vuetify.wallet.source_amount'),
          align: "start",
          sortable: true,
          value: "purchase_amount",
        },
        {
          text: this.$vuetify.lang.t('$vuetify.wallet.received_amount'),
          align: "start",
          sortable: true,
          value: "dest_amount",
        },
        {
          text: this.$vuetify.lang.t('$vuetify.wallet.transaction_fee'),
          align: "start",
          sortable: true,
          value: "transaction_fee",
        },
        {
          text: this.$vuetify.lang.t('$vuetify.wallet.total_paid'),
          align: "start",
          sortable: true,
          value: "source_amount",
        },
        {
          text: this.$vuetify.lang.t('$vuetify.wallet.exchange_rate'),
          align: "start",
          sortable: true,
          value: "rate",
        },
        {
          text: this.$vuetify.lang.t('$vuetify.common.status'),
          align: "start",
          sortable: false,
          value: "status",
        },
        {
          text: this.$vuetify.lang.t('$vuetify.common.actions'),
          align: "start",
          sortable: false,
          value: "transfer_id",
        }
      ],
      reward_percentage_headers: [
        {
          text: this.$vuetify.lang.t('$vuetify.wallet.holding_balance'),
          align: "start",
          sortable: true,
          value: "holding_balance",
        },
        {
          text: this.$vuetify.lang.t('$vuetify.wallet.reward_percentage'),
          align: "start",
          sortable: true,
          value: "percentage",
        }
      ],
      items: [],
      reward_percentages: null,
      holding_balance: null,
      applicable_reward_percentage: null,
      source_currency: "USD",
      buy_amount: "20",
      minimum_buy_amount: "20",
      account_select: null,
      buyModal: false,
      minBuyAmountModal: false,
      numberOptions: {
        locale: NUMBER_FORMAT_LOCALE,
        prefix: "",
        suffix: "",
        length: 15,
        precision: 0,
      },
      allAsset: [
        {
          name: "XLM",
          img: "https://img.icons8.com/external-black-fill-lafs/144/external-Stellar-cryptocurrency-black-fill-lafs.png",
        },
        // {
        //   name: 'DZT',
        //   img: 'https://doozie.io/img/favicon.png'
        // }
      ],
      dest_curr: "XLM",
      dest_gateway: "wyre",
      allGateway: [
        {
          name: this.$vuetify.lang.t('$vuetify.wallet.buy_with_wyre'),
          value: "wyre",
          img: wyre,
        },
      ],
      allFiatCurrencies: [
        {
          name: "EUR",
          value: "EUR"
        },
        {
          name: "GBP",
          value: "GBP"
        },
        {
          name: "JPY",
          value: "JPY"
        },
        {
          name: "USD",
          value: "USD"
        },
      ],
      minimumBuyAmounts: {
        "EUR": "20",
        "GBP": "17",
        "USD": "20",
        "JPY": "2800",
      },
      buyConfirmLoading: false,
      applicableRewardInfoLoading: true,
      interval: {},
      wyreTrackUrl: getEnv("VUE_APP_WYRE_TRACK_PAGE_URL"),
    };
  },
  watch: {
    item: {
      immediate: true,
      handler(val, oldVal) {
        this.account_select = val.public_key;
        this.getAllBuyHistory();
        this.reward_percentages = null;
        this.holding_balance = null;
        this.applicable_reward_percentage = null;
        this.applicableRewardInfoLoading = true;
        this.buyModal = false;
      },
    },
    itemsPerPage() {
      this.page = 1;
      this.getAllBuyHistory();
    },
    source_currency(newVal, oldVal) {
      this.changeMinimumBuyAmount(newVal);
    }
  },
  methods: {
    ...mapActions("wallet", [
      LOAD_WALLET_BUY_HISTORY_BY_ACCOUNT,
      LOAD_WALLET_REWARD_PERCENTAGES,
      LOAD_WALLET_APPLICABLE_REWARD_INFO,
      WALLET_BUY_ASSET,
      UPDATE_WALLET_WYRE_ORDER
    ]),
    getAllBuyHistory() {
      this.isLoading = true;
      this.LOAD_WALLET_BUY_HISTORY_BY_ACCOUNT({
        public_key: this.item?.public_key,
        per_page: this.itemsPerPage,
        page: this.page,
      }).then(
        (response) => {
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    },
    handlePageChange(value) {
      this.page = value;
      this.getAllBuyHistory();
    },
    continueBuy() {
      let wallet = JSON.parse(localStorage.getItem("wallet_user"));
      if (!wallet || this.timeToNextWalletToken() < 1) {
        this.$emit('openPasscode')
      }else {
        if(this.checkMinimumBuyAmount()) {
          this.buyConfirmLoading = true;
          this.WALLET_BUY_ASSET({
            public_key: this.item?.public_key,
            source_currency: this.source_currency,
            buy_amount: this.buy_amount
          }).then((res) => {
            this.buyConfirmLoading = false;
            res.data && window.open(res.data.url, "_blank");
          })
          .catch((err) => {
            this.buyConfirmLoading = false;
          });
        } 
      }
    },
    checkMinimumBuyAmount() {
      let minimum_buy_amount = this.minimumBuyAmounts[this.source_currency]
      if(this.buy_amount.length == 0 || parseInt(this.buy_amount) < parseInt(minimum_buy_amount)) {
        this.minBuyAmountModal = true;
        return false;
      }
      return true;
    },
    getRewardPercentageDetails() {
      this.LOAD_WALLET_REWARD_PERCENTAGES({
      }).then(
        (response) => {
          this.reward_percentages = response.data.reward_percentages
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    },
    getApplicableRewardDetails() {
      this.LOAD_WALLET_APPLICABLE_REWARD_INFO({
        public_key: this.item?.public_key,
      }).then(
        (response) => {
          this.holding_balance = response.data.holding_balance
          this.applicable_reward_percentage = response.data.percentage
          this.isLoading = false;
          this.applicableRewardInfoLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.applicableRewardInfoLoading = false;
        }
      );
    },
    changeMinimumBuyAmount(source_currency) {
      let minimum_buy_amount = this.minimumBuyAmounts[source_currency]
      this.buy_amount = minimum_buy_amount
      this.minimum_buy_amount = minimum_buy_amount
    },
    buyClick() {
      this.getRewardPercentageDetails();
      this.getApplicableRewardDetails();

      let wallet = JSON.parse(localStorage.getItem("wallet_user"));
      if (!wallet || this.timeToNextWalletToken() < 1) {
        this.$emit('openPasscode')
      }else {
        this.buyModal = true;
      }
    },
    cancelBuy() {
      this.buyModal = false;
      this.buyConfirmLoading = false;
    },
    loadBuyHistory() {
      this.LOAD_WALLET_BUY_HISTORY_BY_ACCOUNT({
        public_key: this.item?.public_key,
        per_page: this.itemsPerPage,
        page: this.page,
      }).then(
        (response) => {},
        (error) => {}
      );
    },
    updateWyreOrder(order_id, isRefresh=true) {
      let wallet = JSON.parse(localStorage.getItem("wallet_user"));
      if (!wallet || this.timeToNextWalletToken() < 1) {
        this.$emit('openPasscode')
      }else {
        this.UPDATE_WALLET_WYRE_ORDER({
          public_key: this.item?.public_key,
          order_id: order_id,
          }).then((res) => {
          if(res.data.response.status !== "COMPLETE") {
            this.loadBuyHistory()
            !this.interval[order_id] && this.updateStatusByInterval(order_id, isRefresh)
          }else{
            this.loadBuyHistory()
          }
        }).catch((err) => {})
      }
    },
    updateStatusByInterval(order_id, isRefresh=true) {
      this.interval[order_id] = setInterval(() => {
        let wallet = JSON.parse(localStorage.getItem("wallet_user"));
        if (!wallet || this.timeToNextWalletToken() < 1) {
          this.$emit('openPasscode');
          clearInterval(this.interval[order_id]);
        }else {
          this.UPDATE_WALLET_WYRE_ORDER({
            public_key: this.item?.public_key,
            order_id: order_id,
            }).then((res) => {
              if(res.data.response.status === "COMPLETE"){
                this.loadBuyHistory()
                clearInterval(this.interval[order_id]);
                isRefresh && this.$emit("refreshAfterBuy");
              }
            }).catch((err) => {
              clearInterval(this.interval[order_id]);
            })
        }
      }, getEnv("VUE_APP_POLLING_INTERVAL"))
    },
    handleTrackClick(item) {
      let wallet = JSON.parse(localStorage.getItem("wallet_user"));
      if (!wallet || this.timeToNextWalletToken() < 1) {
        this.$emit('openPasscode')
      }else {
        if(item.status === "PROCESSING"){
          this.updateWyreOrder(item.wyre_order_id, false)
        }
      }
    }
  },
};
</script>

<style>
.table-bar {
  margin: 5px;
}
.total-cost {
  margin-right: 100px !important;
}
</style>