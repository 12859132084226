<template>
  <div>
    <v-row>
      <v-col cols="12" xs="12" md="3">
        <v-select
          v-model="asset_code_id"
          :items="allWalletAssets"
          item-text="asset_code"
          item-value="id"
          placeholder="select asset"
          persistent-hint
          hide-details="auto"
          outlined
          dense
        ></v-select>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <Loader v-if="isLoading" class="mr-2 mt-6" />
    <v-data-table
      v-else
      :headers="headers"
      :items="allClaimableBalance"
      :search="search"
      :items-per-page="-1"
      hide-default-footer
    >
      <template v-slot:[`item.last_modified_time`]="{ item }">
        {{
          new Date(item.last_modified_time).toLocaleDateString("en-us", {
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            year: "numeric",
            month: "short",
            day: "numeric",
          })
        }}
      </template>
      <template v-slot:[`item.id`]="{ item }">
        <v-chip color="error" outlined small>{{ item && "pending" }}</v-chip>
      </template>
      <template v-slot:[`item.sponsor`]="{ item }">
        {{ passChar(item.sponsor) }}
      </template>
      <template v-slot:[`item.asset`]="{ item }">
        <v-chip
          :pill="true"
          color="white"
        >
          <v-avatar
            left
            size="30"
            :color="
              item.asset === 'native' || allAssets.find((a) => a.asset_code === item.asset.split(':')[0])
                ? 'lighten-2'
                : 'primary lighten-2'
            "
          >
            <v-img
              v-if="
                item.asset === 'native' || allAssets.find((a) => a.asset_code === item.asset.split(':')[0])
              "
              :src="item.asset === 'native' 
              ? allAssets.filter(a => a.asset_code === 'XLM')[0].asset_icon
              : allAssets.filter(a => a.asset_code === item.asset.split(':')[0])[0].asset_icon"
            ></v-img>
            <span v-else class="fix-asset-font">
              {{ item.asset.split(":")[0] }}
            </span>
          </v-avatar>
          {{ item && item.asset === "native" ? "XLM" : item.asset.split(":")[0] }}
        </v-chip>
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        <Price 
          color="primary"
          prefix="+"
          :amount="item && item.amount"
        />
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-row>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mt-1 mx-2"
                medium
                icon
                v-bind="attrs"
                v-on="on"
                @click="claimClick(item)"
              >
                <v-icon medium> mdi-cash-lock-open </v-icon>
              </v-btn>
            </template>
            <span>{{ $vuetify.lang.t("$vuetify.wallet.claim") }}</span>
          </v-tooltip>
        </v-row>
      </template>
    </v-data-table>
    <v-row>
      <v-col class="py-0" cols="12" md="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" xs="12" sm="3" md="3"></v-col>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="6"
        class="d-flex justify-center"
      >
        <v-btn
          @click="loadmoreClick()"
          color="primary"
          :loading="loadmore"
          :disabled="disableLoadmore"
        >
          {{ $vuetify.lang.t("$vuetify.common.load_more") }}</v-btn
        >
      </v-col>
      <v-col cols="12" offset-sm="1" offset-md="1" xs="3" sm="2" md="2">
        <v-select
          v-model="itemsPerPage"
          :items="rows"
          hide-details="auto"
          outlined
          dense
        ></v-select>
      </v-col>
    </v-row>
    <!-- claim success  -->
    <v-dialog v-model="claimSuccessDialog" max-width="600" persistent>
      <v-card>
        <v-card-title class="font-weight-bold success--text">
          {{ $vuetify.lang.t("$vuetify.wallet.reward_claimed") }} <v-spacer></v-spacer>
          <v-icon color="success">mdi-check-circle-outline</v-icon>
        </v-card-title>
        <v-row justify="center" v-if="!successData">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
        <v-card-text v-else>
          <v-row no-gutters class="my-2">
            <v-col cols="3">
              <div class="font-weight-bold">{{ $vuetify.lang.t("$vuetify.wallet.created_at") }}:</div>
            </v-col>
            <v-col cols="9">
              {{
                successData
                  ? new Date(successData.created_at).toLocaleDateString(
                      "en-us",
                      {
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      }
                    )
                  : "N/A"
              }}
            </v-col>
          </v-row>
          <v-row no-gutters class="my-2">
            <v-col cols="3">
              <div class="font-weight-bold">{{ $vuetify.lang.t("$vuetify.wallet.transaction_id") }}:</div>
            </v-col>
            <v-col cols="9">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <a
                    target="_blank"
                    :href="successData && successData._links.transaction.href"
                    @click.stop
                    v-on="on"
                    class="text-decoration-none"
                  >
                    {{ successData ? successData.id : "N/A" }}
                  </a>
                </template>
                {{ $vuetify.lang.t("$vuetify.wallet.open_stellar_network_txt") }}
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mr-2" color="error" @click="closeSuccessDialog()"
            >{{ $vuetify.lang.t("$vuetify.common.close_btn") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- passcode  -->
    <PasscodeCheck
      v-model="passcode"
      @confirm="claimClaimableBalance"
      @cancel="cancel"
      :isCancelBtn="true"
      :isLoading="claimLoading"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import Loader from "@/components/common/loader/Loader";
import {
  ADD_ALERT,
  GET_WALLET_CLAIMABLE_BALANCE,
  WALLET_CLAIM_CLAIMABLE_BALANCE,
  ACCESS_WALLET,
} from "@/store/_actiontypes";
import { SET_ALL_WALLET_CLAIMABLE_BALANCE } from "@/store/_mutationtypes";
import PasscodeCheck from "@/components/wallet/PasscodeCheck";
import { timeToNextWalletToken } from "@/helpers/helper";
import Price from "@/components/wallet/Price";

export default {
  props: {
    item: {
      type: Object,
    },
    allAssets: {
      type: Array,
    },
  },
  components: {
    Loader,
    PasscodeCheck,
    Price,
  },
  computed: {
    ...mapState({
      allClaimableBalance: (state) => state.wallet.allClaimableBalance,
    }),
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: this.$vuetify.lang.t('$vuetify.common.date'),
          align: "start",
          sortable: true,
          value: "last_modified_time",
        },
        {
          text: this.$vuetify.lang.t('$vuetify.common.status'),
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: this.$vuetify.lang.t('$vuetify.wallet.address'),
          align: "start",
          sortable: true,
          value: "sponsor",
        },
        {
          text: this.$vuetify.lang.t('$vuetify.wallet.asset'),
          align: "start",
          sortable: true,
          value: "asset",
        },
        {
          text: this.$vuetify.lang.t('$vuetify.wallet.amount'),
          align: "start",
          sortable: true,
          value: "amount",
        },
        {
          text: this.$vuetify.lang.t('$vuetify.common.actions'),
          align: "start",
          value: "action",
          sortable: false,
        },
      ],
      items: [],
      search: "",
      itemsPerPage: 10,
      rows: [5, 10, 20],
      asset_code_id: "",
      xlmIcon:
        "https://img.icons8.com/external-black-fill-lafs/144/external-Stellar-cryptocurrency-black-fill-lafs.png",
      dztIcon: "https://doozie.io/img/favicon.png",
      selectedClaim: null,
      showPasscode: false,
      passcode: null,
      claimLoading: false,
      claimSuccessDialog: false,
      successData: null,
      loadmore: false,
      disableLoadmore: false,
      cursor: null,
      timeToNextWalletToken,
      allWalletAssets: [
        {
          "asset_code": "All",
          "id": "",
        },
        ...this.allAssets
      ]
    };
  },
  watch: {
    item: {
      immediate: true,
      handler(val, oldVal) {
        val.status === "created"
          ? this.SET_ALL_WALLET_CLAIMABLE_BALANCE([])
          : this.getAllClaimableBalance()
      },
    },
    itemsPerPage() {
      this.getAllClaimableBalance();
    },
    asset_code_id() {
      this.getAllClaimableBalance();
    },
  },
  methods: {
    ...mapActions("wallet", [
      GET_WALLET_CLAIMABLE_BALANCE,
      WALLET_CLAIM_CLAIMABLE_BALANCE,
      ACCESS_WALLET,
    ]),
    ...mapActions("alert", [ADD_ALERT]),
    ...mapMutations("wallet", [SET_ALL_WALLET_CLAIMABLE_BALANCE]),
    getAllClaimableBalance() {
      this.isLoading = true;
      this.GET_WALLET_CLAIMABLE_BALANCE({
        asset_code_id: this.asset_code_id ? this.asset_code_id : "",
        public_key: this.item?.public_key,
        limit: this.itemsPerPage,
        cursor: "",
      })
        .then((res) => {
          this.isLoading = false;
          let data = res?.data?.claimable_balances;
          this.cursor = res.data?.next_cursor;
          this.disableLoadmore = data.length < this.itemsPerPage ? true : false;
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    loadmoreClick() {
      if (this.timeToNextWalletToken() < 1) {
        this.$emit("openPasscode");
      } else {
        this.loadmore = true;
        this.GET_WALLET_CLAIMABLE_BALANCE({
          asset_code_id: this.asset_code_id ? this.asset_code_id : "",
          public_key: this.item?.public_key,
          limit: this.itemsPerPage,
          cursor: this.cursor ? this.cursor : "",
          commit_data: false,
        })
          .then((res) => {
            let prevData = this.allClaimableBalance;
            let data = res?.data?.claimable_balances;
            let combineData = [...prevData, ...data];
            this.SET_ALL_WALLET_CLAIMABLE_BALANCE(combineData);
            this.cursor = res?.data?.next_cursor;
            this.loadmore = false;
            this.disableLoadmore = data.length < this.itemsPerPage ? true : false;
            if(data.length < 1) {
              this.$store.dispatch(
                `alert/${ADD_ALERT}`,
                {
                  message: this.$vuetify.lang.t("$vuetify.wallet.no_more_pending_reward"),
                  color: "info",
                },
                { root: true }
              );
            }
          })
          .catch((err) => {
            this.loadmore = false;
          });
      }
    },
    claimClick(item) {
      this.selectedClaim = item;
      this.$store.commit("wallet/toggleModal", true);
    },
    claimClaimableBalance() {
      this.claimLoading = true;
      let body_json = {
        passcode: this.passcode,
        claimable_balance_id: this.selectedClaim.id,
      };
      this.WALLET_CLAIM_CLAIMABLE_BALANCE({
        body_json: body_json,
        public_key: this.item?.public_key,
      })
        .then((res) => {
          this.claimLoading = false;
          this.$store.commit("wallet/toggleModal", false);
          this.passcode = null;
          this.successData = res.data.claim_claimable_balance;
          this.claimSuccessDialog = true;
        })
        .catch((err) => {
          this.$store.commit("wallet/toggleModal", false);
          this.passcode = null;
          this.claimLoading = false;
        });
    },
    closeSuccessDialog() {
      this.claimSuccessDialog = false;
      this.getAllClaimableBalance()
    },
    cancel() {
      this.$store.commit("wallet/toggleModal", false);
      this.passcode = null;
    },
    passChar(str) {
      return `${str.substring(0, 5)}**********${str.substring(
        str.length - 6,
        str.length
      )}`;
    },
  },
};
</script>
<style scoped>
.fix-asset-font {
  font-size: 10px; 
  color:white;
}
</style>