import {
  NUMBER_FORMAT_LOCALE
} from '@/global/_constants';
import jwt_decode from 'jwt-decode';
import CryptoJS from 'crypto-js';

export function authorize (auth = []) {
  const currentUser = getCurrentUser();
  if (!currentUser) return false;  // Handle the null case gracefully
  let parsedJWTJson = jwt_decode(currentUser.access_token);
  const scope = parsedJWTJson["scope"];
  const intersection = scope.filter(value => auth.includes(value));
  return !(auth.length && intersection.length === 0);
}

export function timeToNextToken() {
  let user = getCurrentUser();
  let now = new Date();
  let expiresAt = new Date(user.expires_at);
  return (expiresAt - now) / 1000;
}

export function timeToNextWalletToken() {
  let wallet = JSON.parse(localStorage.getItem("wallet_user"));
  let now = new Date();
  let diff = 0;
  let wallet_expire = wallet.expires_at;
  if(wallet && wallet_expire){
    let expiresAt = new Date(wallet_expire);
    diff = (expiresAt - now) / 1000;
  }
  return diff;
}

export function getCurrentUser() {
  const user = localStorage.getItem("doozie_user");
  return user ? JSON.parse(user) : null;
}

export function setCurrentUser(user) {
  localStorage.setItem("doozie_user", JSON.stringify(user));
}

export function deleteCurrentUser() {
  localStorage.removeItem("doozie_user");
}

export function deleteCurrentWalletUser() {
  localStorage.removeItem("wallet_account");
  localStorage.removeItem("wallet_user");
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

export function capitalizeWords(string) {
  return string.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
}

export function numberToPercentage(num) {
  return (num).toFixed(2) + '%';
}

export function humanReadableKey(key) {
  let value = key.replace(/_/g, " ");
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function removeUnderscoreFromString(string) {
  return string.replace(/_/g, " ");
}

export function formatNum(value) {
   return new Intl.NumberFormat(NUMBER_FORMAT_LOCALE, undefined).format(value)
}

export function getUniqueArrayOfObjects (data = [], property) {
  const uniqueMap = {}
  const uniqueData =  data.reduce((acc, current) => {
      if (!uniqueMap[current[property]]) {
        uniqueMap[current[property]] = true;
        acc.push(current);
      }
      return acc;
    }, []);

    return uniqueData
}
export function formatDateTime(datetime, format, date_only){
  var date = new Date(datetime.toLocaleString())
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();
  var months = date.getMonth()+1;
  months = months < 10 ? '0'+months : months;
  var dates = date.getDate();
  dates = dates < 10 ? '0'+dates : dates;

  if(date_only){
    return date.getFullYear() + "-" + months + "-" + dates;
  }

  var strTime;
  if(format==="24"){
    hours = hours % 24;
    hours = hours ? hours : 24; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    seconds = seconds <10 ? '0'+seconds : seconds;
    strTime = hours + ':' + minutes + ':' + seconds;
  }
  else{
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    seconds = seconds <10 ? '0'+seconds : seconds;
    strTime = hours + ':' + minutes + ':' + seconds + ' ' + ampm;
  }
  return date.getFullYear() + "-" + months + "-" + dates + " at " + strTime;
}

export function dateTimeToStr(date, format){
  var z = {
    M: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    m: date.getMinutes(),
    s: date.getSeconds()
  };
  format = format.replace(/(M+|d+|h+|m+|s+)/g, function(v){
    return ((v.length > 1 ? "0" : "") + z[v.slice(-1)]).slice(-2)
  });

  return format.replace(/(y+)/g, function(v){
    return date.getFullYear().toString().slice(-v.length)
  });
}

export function dateOfToday(){
  return dateTimeToStr(new Date(), "yyyy-MM-dd");
}

export function getLocale() {
  return localStorage.getItem('language')?.substring(0, localStorage.getItem('language')?.indexOf("-")) || "en";
}
export function getSubscriptionTypeColor(subscription_type){
   return subscription_type === 'paid' ? 'success' : 'warning';
}

function toUrlSafeBase64(base64String) {
  return base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

function fromUrlSafeBase64(urlSafeString) {
  let base64String = urlSafeString.replace(/-/g, '+').replace(/_/g, '/');
  while (base64String.length % 4) {
    base64String += '=';
  }
  return base64String;
}

export function encryptData(data, secretKey) {
  const jsonData = JSON.stringify(data);
  const encryptedData = CryptoJS.AES.encrypt(jsonData, secretKey).toString();
  return toUrlSafeBase64(encryptedData);
}

export function decryptData(encryptedData, secretKey) {
  const base64EncryptedData = fromUrlSafeBase64(encryptedData);
  const bytes = CryptoJS.AES.decrypt(base64EncryptedData, secretKey);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
}
