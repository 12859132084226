<template>
  <div>
    <v-app-bar app clipped-left dark color="primary">
      <v-toolbar-title>
        <router-link to="/" tag="span" style="cursor: pointer" class="headline">
          <!-- app bar icon for small device  -->
          <v-app-bar-nav-icon
            v-if="
              $vuetify.breakpoint.name === 'xs' ||
              $vuetify.breakpoint.name === 'sm'
            "
            @click.stop="sidebar = !sidebar"
            :class="{ 'd-none': this.$route.path === '/setup-accounts' }"
          ></v-app-bar-nav-icon>
          <!-- app bar icon for large device  -->
          <v-app-bar-nav-icon
            v-else
            @click.stop="mini = !mini"
            :class="{ 'd-none': this.$route.path === '/setup-accounts' }"
          ></v-app-bar-nav-icon>
          <img
            src="@/assets/images/logo_white_horizontal.png"
            class="logo"
            alt="Gigalogy"
          />
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <switch-language />
      <!-- menu -->
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <div v-on="on" class="d-flex align-center">
            <v-avatar
              size="35px"
              color="primary lighten-2"
              class="ml-2"
              style="cursor: pointer"
            >
              <span class="font-weight-bold logo-text">{{
                nameFirstCharacter
              }}</span>
            </v-avatar>
            <div class="hidden-sm-and-down">
              <v-btn text class="pa-1">
                <span class="text-capitalize"
                  >{{
                    organization.status === "active"
                      ? organization.first_name
                      : organization.status === "created"
                        ? account.account_name
                        : "Hello"
                  }}
                </span>
                <v-icon class="white--text">expand_more</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <v-list>
          <v-list-item
            v-for="item in menuItems"
            :key="item.text"
            router
            dense
            @click="menuActionClick(item.action)"
            :to="item.route"
          >
            <v-list-item-icon class="mr-2">
              <v-icon small>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <!-- <v-banner
      v-if="Object.keys(organization).length && isVisible()"
      class="mx-0 banner-style"
      style="z-index: 5"
      app
      outlined
      single-line
    >
      <v-row align="center" justify="center">
        <v-icon class="mx-2" slot="icon" color="rgba(241, 101, 8, 0.986)" size="30">
          warning_amber
        </v-icon>
        <h4>{{ $vuetify.lang.t("$vuetify.profile.id_verify_msg_txt_1") }}</h4>
        <v-btn class="px-1" color="primary" text @click.stop="redirectToVerify()">
          <h3>{{ $vuetify.lang.t("$vuetify.profile.id_verify_msg_txt_2") }}</h3>
        </v-btn>
        <h4>{{ `${$vuetify.lang.t("$vuetify.profile.id_verify_msg_txt_3")}!` }}</h4>
      </v-row>
    </v-banner> -->
    <v-banner
      v-if="Object.keys(organization).length && !organization.email_verified"
      class="mx-0 banner-style"
      style="z-index: 5"
      app
      outlined
      single-line
    >
      <v-row align="center" justify="center">
        <v-icon
          class="mx-2"
          slot="icon"
          color="rgba(241, 101, 8, 0.986)"
          size="30"
        >
          warning_amber
        </v-icon>
        <h4 class="mr-2">
          {{ $vuetify.lang.t("$vuetify.registration.resend_verification_email_msg_txt_2") }}
        </h4>
        <v-btn
          color="primary"
          small
          outlined
          @click="handleSubmitClick()"
          :loading="emailSendLoading"
          >{{
            $vuetify.lang.t("$vuetify.registration.resend_verification_email_btn_txt")
          }}</v-btn
        >
      </v-row>
    </v-banner>
    <!-- side navigation bar -->
    <div class="side-navbar" :class="{ 'navbar-toggle': mini }">
      <div>
        <v-card :class="{ 'd-none': this.$route.path === '/setup-accounts' }">
          <v-navigation-drawer
            v-model="drawer"
            :mini-variant="mini"
            permanent
            class="fixed hidden-sm-and-down"
            :class="{ 'mt-13': isVisible() || !organization.email_verified }"
          >
            <!-- <v-list>
              <div v-for="item in navItems" :key="item.text">
                <v-list-item v-if="authorize(item.auth)" :to="item.route" link>
                  <v-list-item-icon>
                    <v-icon medium>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list> -->

            <v-list>
              <div v-for="item in navItems" :key="item.text">
                <v-list-item
                  v-if="
                    authorize(item.auth) &&
                    (!item.children || item.children.length === 0)
                  "
                  :to="item.route"
                  link
                  :exact="true"
                >
                  <v-list-item-icon>
                    <v-icon medium>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-group
                  v-else-if="
                    authorize(item.auth) &&
                    item.children &&
                    item.children.length > 0
                  "
                  color="secondary"
                  no-action
                  v-model="navSelectedChild"
                >
                  <template v-slot:activator>
                    <v-list-item-icon>
                      <v-icon medium>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item
                    v-for="(child, i) in item.children"
                    :key="i"
                    link
                    :to="child.route"
                    :class="{ 'navbar-active': child.active }"
                  >
                    <v-list-item-icon>
                      <v-icon medium>{{ child.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ child.text }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </div>
            </v-list>

            <v-divider></v-divider>
            <v-list>
              <div v-for="(item, index) in actionItems" :key="item.text">
                <v-list-item
                  v-if="authorize(item.auth)"
                  :to="item.route ? item.route : null"
                  :href="item.href ? item.href : null"
                  :target="item.href ? '_blank' : undefined"
                  link
                  :exact="true"
                >
                  <v-list-item-icon>
                    <v-icon medium>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon
                    v-if="item.launch && index === actionItems.length - 1"
                  >
                    <v-icon medium :color="launch_icon_color">{{
                      item.launch
                    }}</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </div>
            </v-list>
          </v-navigation-drawer>
          <!-- navigation drawer for mobile  -->
          <v-navigation-drawer
            v-model="sidebar"
            app
            left
            offset-y
            class="hidden-md-and-up mt-12"
          >
            <v-list>
              <div v-for="item in navItems" :key="item.text">
                <v-list-item
                  v-if="
                    authorize(item.auth) &&
                    (!item.children || item.children.length === 0)
                  "
                  :to="item.route"
                  link
                  :exact="true"
                >
                  <v-list-item-icon>
                    <v-icon medium>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-group
                  v-else-if="
                    authorize(item.auth) &&
                    item.children &&
                    item.children.length > 0
                  "
                  color="secondary"
                  no-action
                  v-model="navSelectedChild"
                >
                  <template v-slot:activator>
                    <v-list-item-icon>
                      <v-icon medium>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item
                    v-for="(child, i) in item.children"
                    :key="i"
                    link
                    :to="child.route"
                    :class="{ 'navbar-active': child.active }"
                  >
                    <v-list-item-icon>
                      <v-icon medium>{{ child.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ child.text }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </div>
            </v-list>

            <!-- <v-list>
              <div v-for="item in navItems" :key="item.text">
                <v-list-item v-if="authorize(item.auth)" :to="item.route" link>
                  <v-list-item-icon>
                    <v-icon medium>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list> -->
            <v-divider></v-divider>
            <v-list>
              <div v-for="(item, index) in actionItems" :key="item.text">
                <v-list-item
                  v-if="authorize(item.auth)"
                  :to="item.route ? item.route : null"
                  :href="item.href ? item.href : null"
                  :target="item.href ? '_blank' : undefined"
                  link
                  :exact="true"
                >
                  <v-list-item-icon>
                    <v-icon medium>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon
                    v-if="item.launch && index === actionItems.length - 1"
                  >
                    <v-icon medium :color="launch_icon_color">{{
                      item.launch
                    }}</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </div>
            </v-list>
          </v-navigation-drawer>
        </v-card>
      </div>
      <slot></slot>
    </div>
    <ProgressBar />
  </div>
</template>

<script>
import ProgressBar from "@/components/TheProgressBar";
import { mapState, mapGetters, mapActions } from "vuex";
import {
  LOAD_ORGANIZATION,
  LOAD_ACCOUNT,
  LOGOUT,
  RESEND_VERIFICATION_EMAIL,
  ADD_ALERT,
  WALLET_REVOKE,
} from "@/store/_actiontypes";
import { Role } from "@/helpers/role";
import { authorize } from "@/helpers/helper";
import SwitchLanguage from "./common/SwitchLanguage";
import getEnv from "@/config/env";

export default {
  components: {
    ProgressBar,
    SwitchLanguage,
  },
  props: {
    isProfile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("account", ["nameFirstCharacter"]),
    ...mapState({
      organization: state => state.account.organization,
      user: state => state.account.user,
      account: state => state.account.account,
    }),
  },
  created() {
    this.navSelectedChild = this.$route.query.type;
    this.setActiveChildren();
  },
  mounted() {
    if (getEnv("VUE_APP_WALLET_ENABLED") === "false") {
      this.disableWallet();
    }
  },
  data() {
    return {
      loading: true,
      authorize,
      sidebar: false,
      drawer: true,
      mini: false,
      navSelectedChild: "",
      launch_icon_color: "#004AAD",
      navItems: [
        {
          icon: "dashboard",
          text: this.$vuetify.lang.t("$vuetify.side_nav.dashboard"),
          route: "/dashboard",
          auth: [Role.ADMIN, Role.USER],
        },
        // {
        //   icon: "view_list",
        //   text: this.$vuetify.lang.t("$vuetify.side_nav.solutions"),
        //   route: "/solutions",
        //   auth: [],
        //   children: [
        //     { icon: "thumb_up_alt", name: "Recommender", text: "Recommender", route: "/solutions?type=Recommender", active: false },
        //     { icon: "remove_red_eye", name: "CV", text: "CV", route: "/solutions?type=CV", active: false },
        //     { icon: "question_answer", name: "MyGPT", text: "MyGPT", route: "/solutions?type=MyGPT", active: false }
        //   ],
        // },

        {
          icon: "thumb_up_alt",
          name: "Personalizer",
          text: this.$vuetify.lang.t("$vuetify.side_nav.personalizer"),
          route: "/solutions?type=Personalizer",
          auth: [],
          active: false,
        },

        {
          icon: "remove_red_eye",
          name: "CV",
          text: this.$vuetify.lang.t("$vuetify.side_nav.cv"),
          route: "/solutions?type=CV",
          active: false,
        },

        {
          icon: "question_answer",
          name: "Maira",
          text: this.$vuetify.lang.t("$vuetify.side_nav.maira"),
          route: "/solutions?type=Maira",
          active: false,
        },

        // {
        //   icon: "view_list",
        //   text: this.$vuetify.lang.t("$vuetify.side_nav.projects"),
        //   route: "/projects",
        //   auth: [Role.ADMIN, Role.USER],
        // },
        // {
        //   icon: "mdi-note-edit",
        //   text: this.$vuetify.lang.t("$vuetify.side_nav.invoices"),
        //   route: "/invoices",
        //   auth: [Role.ADMIN, Role.USER],
        // },
        {
          icon: "mdi-account-multiple",
          text: this.$vuetify.lang.t("$vuetify.side_nav.members"),
          route: "/members",
          auth: [Role.ADMIN, Role.USER],
        },
        {
          icon: "account_balance_wallet",
          text: this.$vuetify.lang.t("$vuetify.side_nav.wallet"),
          route: "/wallet",
          auth: [Role.ADMIN, Role.USER],
        },
        {
          icon: "history",
          text: this.$vuetify.lang.t("$vuetify.side_nav.jobs"),
          route: "/jobs",
          auth: [Role.SUPPORT, Role.SUPER],
        },
      ],
      profileNavItems: [
        {
          icon: "mdi-cog",
          text: "Account Settings",
          route: "/account-settings",
          auth: [],
        },
        {
          icon: "perm_identity",
          text: "Profile Settings",
          route: "/profile-settings",
          auth: [],
        },
      ],
      menuItems: [
        // { icon: "mdi-cog", text: "Settings", route: "/account-settings" },
        {
          icon: "perm_identity",
          text: this.$vuetify.lang.t("$vuetify.top_nav.profile_menu_txt"),
          route: "/profile-settings",
          auth: [],
        },
        {
          icon: "exit_to_app",
          text: this.$vuetify.lang.t("$vuetify.top_nav.signout_menu_txt"),
          action: () => {
            this.handleLogOut();
          },
          auth: [],
        },
      ],
      actionItems: [
        {
          icon: "add_circle_outline",
          text: this.$vuetify.lang.t("$vuetify.common.create"),
          route: "/register-solutions",
          auth: [Role.DEVELOPER],
        },
        {
          icon: "new_releases",
          text: this.$vuetify.lang.t("$vuetify.side_nav.release_note"),
          href: "https://tutorial.gigalogy.com/release_notes",
          launch: "launch",
        },
      ],
      emailSendLoading: false,
      model: 1,
    };
  },
  watch: {
    account: {
      handler: function () {
        !Object.keys(this.account).length && this.getAccount();
      },
      immediate: true,
    },
    organization: {
      handler: function () {
        !Object.keys(this.organization).length && this.getOrganization();
      },
      immediate: true,
    },
    "$route.query.type": {
      handler: function (newType) {
        this.navSelectedChild = newType;
        this.setActiveChildren();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("account", [
      LOAD_ORGANIZATION,
      LOAD_ACCOUNT,
      LOGOUT,
      RESEND_VERIFICATION_EMAIL,
    ]),
    ...mapActions("alert", [ADD_ALERT]),
    ...mapActions("wallet", [WALLET_REVOKE]),
    getOrganization() {
      this.LOAD_ORGANIZATION().then(
        response => {
          this.loading = false;
        },
        error => {},
      );
    },
    getAccount() {
      this.LOAD_ACCOUNT().then(
        response => {
          this.loading = false;
        },
        error => {},
      );
    },
    handleHomeRoute() {
      if (this.$route.path !== "/dashboard") {
        this.$router.push("/dashboard");
      }
    },

    redirectToVerify() {
      let tab_id = this.authorize([Role.ADMIN]) ? "2" : "1";
      if (this.$route.path === "/profile-settings") {
        if (this.$route.query.tab !== "2") {
          this.$router.push("/profile-settings?tab=2");
          this.$store.reset();
        }
      } else {
        this.$router.push(`/profile-settings?tab=${tab_id}`);
      }
    },
    isVisible() {
      if (this.organization.id_status === "pending") {
        return true;
      } else if (this.organization.id_status === "verified") {
        return false;
      } else if (this.organization.status === "created") {
        return true;
      } else return !this.organization.email_verified;
    },
    async handleSubmitClick() {
      const clientIP = await this.$ipCheck.getClientIP();
      const isAllowed = await this.$ipCheck.ipRangeCheck(clientIP);

      if (getEnv("VUE_APP_CAPTCHA_ENABLED") && !isAllowed) {
        this.$recaptcha
          .execute("resend_verify_email")
          .then(recaptcha_token => {
            this.handleSubmit(recaptcha_token, clientIP);
          })
          .catch(error => {
            console.error("reCaptcha error:", error);
          });
      } else {
        this.handleSubmit(null, clientIP);
      }
    },
    handleSubmit(recaptcha_token, clientIP) {
      this.emailSendLoading = true;
      this.LOAD_ORGANIZATION().then(
        response => {
          if (response) {
            if (this.organization.email_verified) {
              this.$store.dispatch(
                `alert/${ADD_ALERT}`,
                {
                  message: this.$vuetify.lang.t(
                    "$vuetify.registration.account_already_verified",
                  ),
                  color: "error",
                },
                { root: true },
              );
            } else {
              this.RESEND_VERIFICATION_EMAIL({
                email: this.organization.email,
                recaptcha_token: recaptcha_token,
                clientIP: clientIP,
              })
                .then(res => {
                  this.emailSendLoading = false;
                })
                .catch(err => {
                  this.emailSendLoading = false;
                });
            }
          }
        },
        error => {},
      );
    },
    menuActionClick(action) {
      if (action === undefined) return;
      action();
    },
    async handleLogOut() {
      try {
        if (this.user) {
          if (
            getEnv("VUE_APP_WALLET_ENABLED") === "true" &&
            localStorage.getItem("wallet_user") !== null
          ) {
            try {
              const walletResponse = await this.WALLET_REVOKE({});
              console.log(walletResponse);
            } catch (walletError) {
              console.error("Wallet revocation error:", walletError);
            }
          }
          let refresh_token = this.user.refresh_token;
          try {
            const logoutResponse = await this.LOGOUT({ refresh_token });
            // console.log(logoutResponse);
          } catch (logoutError) {
            console.error("Logout error:", logoutError);
          }
        }
      } catch (generalError) {
        console.error("General error during logout:", generalError);
      }
    },
    disableWallet() {
      this.navItems = this.navItems.filter(item => item.route !== "/wallet");
    },
    setActiveChildren() {
      this.navItems.forEach(navItem => {
        // Check if navItem has children and if one of them matches navSelectedChild
        if (
          navItem.children &&
          navItem.children.some(child => child.name === this.navSelectedChild)
        ) {
          // Set active to true for the matching child
          navItem.children.forEach(child => {
            child.active = child.name === this.navSelectedChild;
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-toolbar__content {
  margin: auto;
}

::v-deep .v-toolbar__items > a.v-btn--active {
  .v-btn__content span {
    font-weight: bold;
  }
}

::v-deep .v-toolbar__items > a.v-btn--active:after {
  content: "";
  position: absolute;
  left: 18px;
  bottom: 0;
  height: 2px;
  width: 75%;
  border-bottom: 2px solid #26a69a;
}

::v-deep .secondary-toolbar .v-toolbar__content {
  padding: 0;
}

::v-deep .v-btn:before {
  background-color: transparent !important;
}

.side-navbar {
  overflow: hidden;
  padding: 5px 0 100px;
  display: grid;
  min-height: 100vh;
  column-gap: 0;
  row-gap: 15px;
  grid-template-columns: 260px minmax(0, 1fr);
}

.navbar-active {
  background-color: rgb(228, 228, 228);
}

.navbar-toggle {
  grid-template-columns: 60px minmax(0, 1fr);
}

.fixed {
  position: fixed;
  top: 45px !important;
}

.banner-style {
  border-color: rgba(241, 101, 8, 0.986);
  border-width: 2px;
  border-radius: 5px !important;
  color: rgba(241, 101, 8, 0.986);
  background-color: #fff !important;
}

.v-app-bar {
  height: 64px !important;
}

.logo {
  max-height: 48px;
  vertical-align: middle;
}

.v-navigation-drawer {
  top: 58px !important;
}

@media screen and (max-width: 950px) {
  .side-navbar {
    grid-template-columns: minmax(0, 1fr);
  }
  .navbar-toggle {
    grid-template-columns: minmax(0, 1fr);
  }
}
</style>
