<template>
  <v-skeleton-loader
    v-if="!dataset"
    class="mr-2 mt-6"
    type="list-item-three-line"
  ></v-skeleton-loader>
  <v-form v-else ref="dataset_form">
    <v-row>
      <v-col cols="12" md="12">
        <v-text-field
          type="text"
          v-model="dataset.datasetName"
          :label="$vuetify.lang.t('$vuetify.gpt_dataset.dataset_name')"
          :rules="[
            required($vuetify.lang.t('$vuetify.gpt_dataset.dataset_name')),
          ]"
          outlined
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          type="text"
          v-model="dataset.indexColumnName"
          :label="$vuetify.lang.t('$vuetify.gpt_dataset.index_column_name')"
          :rules="[
            required($vuetify.lang.t('$vuetify.gpt_dataset.index_column_name')),
          ]"
          :readonly="isUpdateMode"
          outlined
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          type="text"
          v-model="dataset.secondaryIndex"
          :label="
            $vuetify.lang.t('$vuetify.gpt_dataset.secondary_index_column')
          "
          outlined
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          type="text"
          v-model="dataset.imageColumn"
          :label="$vuetify.lang.t('$vuetify.gpt_dataset.image_url_column')"
          outlined
        />
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          type="text"
          v-model="dataset.description"
          :label="$vuetify.lang.t('$vuetify.gpt_dataset.description_label_txt')"
          outlined
        />
      </v-col>
      <v-col cols="12" md="12">
        <v-combobox
          v-model="dataset.tags"
          :items="tagChips"
          :label="$vuetify.lang.t('$vuetify.gpt_profile.dataset_tags')"
          variant="solo"
          chips
          clearable
          outlined
          :hint="$vuetify.lang.t('$vuetify.gpt_profile.chip_hint')"
          multiple
          class="no-dropdown-icon"
        >
          <template v-slot:selection="{ attrs, item, selected }">
            <v-chip v-bind="attrs" :model-value="selected" closable>
              <span>{{ item }}</span
              >&nbsp;
            </v-chip>
          </template>
        </v-combobox>
      </v-col>

      <v-col cols="12" md="12">
        <v-combobox
          v-model="dataset.filterableFields"
          :items="filterableFieldsChips"
          :label="$vuetify.lang.t('$vuetify.gpt_profile.filterable_fields')"
          variant="solo"
          chips
          clearable
          outlined
          :hint="$vuetify.lang.t('$vuetify.gpt_profile.filterable_chip_hint')"
          multiple
          class="no-dropdown-icon"
        >
          <template v-slot:selection="{ attrs, item, selected }">
            <v-chip v-bind="attrs" :model-value="selected" closable>
              <span>{{ item }}</span
              >&nbsp;
            </v-chip>
          </template>
        </v-combobox>
      </v-col>

      <v-col cols="12" md="12">
        <v-file-input
          outlined
          accept=".json, .csv, application/json, text/csv, application/pdf, .pdf"
          @change="handleFileChange"
          :label="$vuetify.lang.t('$vuetify.gpt_dataset.dataset_file')"
          :rules="fileInputRules"
        >
        </v-file-input>
      </v-col>
      <v-col>
        <v-checkbox
          v-model="dataset.fillUpEmptyIndex"
          :label="
            $vuetify.lang.t('$vuetify.gpt_dataset.fill_up_index_if_empty')
          "
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-row> </v-row>
  </v-form>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { UPDATE_GPT_DATASET_STATE } from "@/store/_actiontypes";

export default {
  props: ["propName"],
  computed: {
    ...mapState({
      dataset: state => state.project.selectedGptDataset,
      gptDatasets: state => state.project.gptDatasets.datasets,
      gptSetupState: state => state.project.gptSetupState,
      isUpdateMode() {
        return !!this.$route.params.dataset_id;
      },
      fileInputRules() {
        let rules = [];

        if (!this.gptSetupState?.dataset?.completed) {
          rules.push(
            value =>
              !!value ||
              this.$vuetify.lang.t("$vuetify.gpt_dataset.dataset_file") +
                this.$vuetify.lang.t("$vuetify.validation.required_error_msg_txt"),
          );
        }

        return rules;
      },
    }),
  },
  data() {
    return {
      datasetLoader: true,
      datasetFile: null,
      tagChips: [],
      filterableFieldsChips: [],
    };
  },
  watch: {
    dataset: {
      deep: true,
      handler(newVal, oldVal) {
        const localDataset = this.dataset;
        this.UPDATE_GPT_DATASET_STATE({ dataset: localDataset });
      },
    },
  },
  methods: {
    ...mapActions("project", [UPDATE_GPT_DATASET_STATE]),

    required(fieldName) {
      return (value) => {
        return !!value || `${fieldName}${this.$vuetify.lang.t('$vuetify.validation.required_error_msg_txt')}`;
      };
    },
    handleFileChange(file) {
      this.datasetFile = file;
      this.UPDATE_GPT_DATASET_STATE({
        dataset: { ...this.dataset, datasetFile: file },
      });
    },
    validateForm() {
      return this.$refs.dataset_form.validate();
    },
  },
};
</script>

<style scoped>
.no-dropdown-icon >>> .v-input__append-inner .v-icon {
  display: none !important;
}
</style>
